import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Hammer from "hammerjs";
import { motion } from "framer-motion";

export let initcards = [
  {
    id: "1",
    blogno: "Beyond-Spreadsheets",

    title: "Beyond Spreadsheets", // Anitha
    subTitle:
      "Pioneering Efficient Communication and Team Synergy for Tomorrow", //Anitha
    img: "Rectangle White.png",
    color: "#191825",
    tilt: "-7",
    section: {
      titles: ["About the company", "Timeline", "Benefits"],
      content: {
        about: [
          "Demo-1 sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
        ],
      },
    },
  },
  {
    id: "",
    blogno: "Profit-with-Purpose",

    title: "Profit with Purpose", // Anitha
    subTitle: "Pioneering Specialized Startup Support Systems", //Anitha
    img: "Rectangle Black.png",
    color: "#FFFFFF",
    tilt: "-3",
    section: {
      titles: ["About the company", "Timeline", "Benefits"],
      content: {
        about: [
          "Demo-2 sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
          "demo-2 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
        ],
        about1: [
          " Sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
          "demo-2 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
        ],
      },
    },
  },
  {
    id: "",
    blogno: "resources",

    title: "Resources", // Anitha
    subTitle: "Explore more stories about sustainability on our LinkedIn page", //Anitha
    img: "Rectangle White.png",
    color: "#FFFFFF",
    tilt: "-3",
    section: {
      titles: ["About the company", "Timeline", "Benefits"],
      content: {
        about: [
          "Demo-2 sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
          "demo-2 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
        ],
        about1: [
          " Sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
          "demo-2 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol",
        ],
      },
    },
  },
  // {
  //   id: '',
  //   blogno: '3',

  //   title: 'The Rise of Niche Incubators',
  //   subTitle: 'How Ethical Initiatives Boost Business Success',
  //   img: 'Rectangle White.png',
  //   color: '#191825',
  //   tilt: '4',
  //   section: {
  //     titles: ['About the company', "Timeline", 'Benefits'],
  //     content: {
  //       about: ["Demo-3 sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol", " demo demo 3 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol"]
  //     }
  //   }
  // },
  /*
  {
    id: '4',
    title: 'Demo-4',
    subTitle: '',
    img: 'Rectangle 22.png',
    color: '#191825',
    tilt: '0',
  },/*
  {
    id: '5',
    title: 'Demo-5',
    subTitle: 'Sub Title 5',
    img: 'Rectangle 25.png',
    color: '#191825',
    tilt: '1',
    section: {
      titles: ['About the company', "Timeline", 'Benefits'],
      content: {
        about: ["Demo-5 sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol", "5 demo 5 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol"]
      }
    }
  },
  {
    id: '6',
    title: 'Demo-6',
    subTitle: 'Sub Title 5',
    img: 'Rectangle 24.png',
    color: '#191825',
    tilt: '1',
    section: {
      titles: ['About the company', "Timeline", 'Benefits'],
      content: {
        about: ["Demo-6 sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol", "6  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur molestiae ex harum. Dolores ducimus vitae quidem incidunt pariatur, quaerat quod sit perspiciatis, nam saepe suscipit minus! it, amet consectetur adipisicing elit. Nesciunt blanditiis, quis voluptate consequatur mol"]
      }
    }
  }*/
];

export default function SpotifyCards({ isDarkMode }) {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  window.addEventListener("resize", () => {
    const width = window.innerWidth;
    if (width < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  });
  let initreverseCards = [
    {
      id: "4",
      title: "Demo 4",
      subTitle: "Sub Title 4",
      img: "Square_-_Generalist_2x.png",
      color: "#191825",
      tilt: "-3",
    },
    {
      id: "5",
      title: "Demo 5",
      subTitle: "Sub Title 5",
      img: "Zoned_44_by_Faith_McAllister_2x.png",
      color: "#191825",
      tilt: "1",
    },
  ];

  let allCards = document.querySelectorAll(".tinder--card");
  let totalcards = allCards.length;
  const [cards, setCards] = useState(initcards);
  const [reverseCards, setReverseCards] = useState(initreverseCards);
  const [removed, setRemoved] = useState(false);

  function initCards() {
    var cardsNotRemoved = document.querySelectorAll(
      ".tinder--card:not(.removed)"
    );

    cardsNotRemoved.forEach(function (card, index) {
      card.style.zIndex = totalcards - index;
      //   card.style.transform = `rotate(${Math.floor(
      //     Math.random() * (8 - -8 + 1) + -8
      //   )}deg)`;
    });

    if (cardsNotRemoved.length > 0) {
      // cardsNotRemoved[0].style.transform = 'rotate(0deg)';
    }
  }

  function toggleClass(e) {
    if (e.target.classList.contains("tinder--card")) {
      if (e.target.classList.contains("removed")) {
        e.target.classList.remove("removed");
        e.target.style.zIndex = -e.target.style.zIndex;
        // e.target.style.transform = 'translate(775px, 10px) rotate(2deg) rotateY(180deg) !important';
      } else {
        e.target.classList.add("removed");
        e.target.style.zIndex = e.target.style.zIndex.substring(1);
      }
    }
  }

  function toggleClassBack(e) {}

  function initHammer() {
    allCards.forEach(function (el) {
      let hammerElement = new Hammer(el);

      hammerElement.on("pan", function (e) {
        el.classList.add("moving");
      });

      hammerElement.on("pan", function (event) {
        if (event.deltaX === 0) return;
        if (event.center.x === 0 && event.center.y === 0) return;

        var xMulti = event.deltaX * 0.03;
        var yMulti = event.deltaY / 80;
        var rotate = xMulti * yMulti;

        event.target.style.transform =
          "translate(" +
          event.deltaX +
          "px, " +
          event.deltaY +
          "px) rotate(" +
          rotate +
          "deg)";

        var keep =
          Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

        event.target.classList.toggle("removed", !keep);
      });
    });
  }

  useEffect(() => {
    initCards();
    initHammer();
  }, []);

  const lineHeight = {
    hidden: { height: 0 },
    show: { height: "85%" },
  };

  const horizontalLine = {
    hidden: { x: "100%", opacity: 0 }, // Start from the right and hidden
    show: { x: "0%", opacity: 1 },
  };

  return (
    <div>
      <div className="relative overflow-hidden ">
        <div
          style={{
            // backgroundColor: isDarkMode === "dark" ? 'black' : 'white',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // backgroundImage: `url(${backgroundImageNew1})`,
          }}
          className="tinder pl-8 md:pl-0"
        >
          {/* <h2
              className='font-semibold	font-Poppins	text-center  text-3xl md:text-5xl mt-14	'>
              Why Impact Matters?
            </h2> */}
          <motion.div
            variants={lineHeight}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false }}
            transition={{ duration: 0.9, delay: 0.5 }}
            style={{ transformOrigin: "top" }}
            className="snakeline absolute w-[10px] h-full increase1 right-0 rounded-full "
          ></motion.div>
          <motion.div
            variants={horizontalLine}
            initial="hidden"
            whileInView="show"
            exit="hidden"
            viewport={{ once: false }}
            transition={{ duration: 0.9, delay: 1 }}
            className="snakeline absolute bottom-10 md:bottom-24 h-[10px] w-full increase1 origin-bottom-right"
          ></motion.div>

          <div
            className="tinder--cards h-[50%] pb-20 md:pb-0 w-2/3"
            id="tinder--cards"
          >
            {cards.map((card, index) => (
              <div
                // onMouseDownCapture={(e) => toggleClass(e)}
                draggable={true}
                onDragStart={(e) => {
                  e.preventDefault();
                  e.dataTransfer.setData("text/plain", ""); // Set an empty string as the data
                  // Optionally, you can also set a custom drag image
                  e.dataTransfer.setDragImage(new Image(), 0, 0);
                  toggleClass(e);
                }}
                className={`tinder--card ${
                  card.img === "Rectangle Black.png"
                    ? "text-white"
                    : "text-black"
                }`}
                style={{
                  // zIndex:index,
                  //transform: 'rotate(' + -10 + 'deg)',
                  borderRadius: "10px",
                  left: cards.length - index * 2 + 2 + "%",
                  // bottom: cards.length - index * 7 + 7 + "%",
                  boxShadow: "rgb(52 46 47) 9px -5px -122px",
                }}
                key={card?.id}
                onClick={(e) => {
                  navigate(`/blog?${card.blogno}`);
                }}
              >
                <span
                  className="text-xl p-3 md:text-3xl"
                  style={{
                    position: "absolute",
                    right: "0%",
                    top: "15%",
                    fontWeight: "700",
                    fontFamily: "Inter",
                  }}
                >
                  {card.title}
                </span>
                <span
                  className="text-sm p-1 md:text-xl"
                  style={{
                    position: "absolute",
                    right: "0%",
                    top: "40%",
                    fontWeight: "400",
                    fontFamily: "Inter",
                  }}
                >
                  {card.subTitle}
                </span>
                <img src={require(`../assets/${card?.img}`)} alt="blog cards" />

                <h2>{card?.title}</h2>
              </div>
            ))}
            <div className="card-last -z-20">
              <img
                className="-z-10"
                src={require(`../assets/Rectangle 25.png`)}
                alt="blog card holder"
              />
              <h2 className="md:text-2xl font-Inter font-semibold pt-6 px-4 text-center md:leading-9 ">
                Every action shapes the future. Make it sustainable, Make it
                count!
              </h2>
              <h2 className="md:pt-12 pt-2 font-Inter text-md px-2">
                Why sustainability matters to the business, drag or swipe the
                cards to know more!
              </h2>
            </div>
          </div>
          <div></div>
          <div className="tinder--buttons mt-8 mb-10 pr-10">
            <button
              aria-label="Move to previous card"
              name="go back"
              style={{
                backgroundColor: !isDarkMode === "dark" ? "#232323" : "white",
                color: "white",
                fontWeight: "bolder",
              }}
              id="back"
              onClick={() => {
                // console.log(document.getElementById('tinder--cards'))
                let activeArray = document.querySelectorAll(
                  ".tinder--card.removed"
                );
                // console.log(activeArray)
                activeArray[activeArray.length - 1]?.classList.remove(
                  "removed"
                );
                let element = activeArray[activeArray.length - 1];
                if (element === undefined) return;
                element.style.zIndex = -element.style.zIndex;
              }}
            >
              <img
                src={require(
                  isDarkMode
                    ? "../assets/back_arrow.png"
                    : "../assets/back_arrow_white.png"
                )}
                alt="back arrow white"
              />
            </button>
            <button
              aria-label="Move to next card"
              name="go next"
              style={{
                backgroundColor: !isDarkMode === "dark" ? "#232323" : "white",
                color: "white",
                fontWeight: "bolder",
              }}
              id="next"
              onClick={() => {
                // console.log(document.getElementById('tinder--cards'))
                let activeArray = document.querySelectorAll(
                  ".tinder--card:not(.removed)"
                );
                activeArray[0]?.classList.add("removed");
                let element = activeArray[0];
                if (element === undefined) return;
                element.style.zIndex = element.style.zIndex.substring(1);
              }}
            >
              <img
                src={require(
                  isDarkMode
                    ? "../assets/next_arrow.png"
                    : "../assets/next_arrow_white.png"
                )}
                alt="next arrow white"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
