import React from 'react'
import '../common/styles/styles.css'
import { useState, useEffect } from 'react'
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';

function Contact() {
  // useEffect(()=>{
  //   window.addEventListener("load", function() {
  //     const form = document.getElementById('my-form');
  //     form.addEventListener("submit", function(e) {
  //       e.preventDefault();
  //       const data = new FormData(form);
  //       const action = e.target.action;
  //       fetch(action, {
  //         method: 'POST',
  //         body: data,
  //       })
  //       .then(() => {
  //         alert("Success!");
  //       })
  //     });
  //   });
  // })

  useEffect(() => {

    const scriptURL = 'https://script.google.com/macros/s/AKfycbxczOnNOfsDea4DBme1GX11x7rTkE5tJl7-nKe8y40Y-GhD99aoKhu3KNung-kl4SMF/exec'
    const form = document.forms['submit-to-google-sheet']

    form.addEventListener('submit', e => {
      e.preventDefault()
      fetch(scriptURL, { method: 'POST', body: new FormData(form) })
        .then(response => {
          form.reset();
        })
        .catch(error => console.error('Error!', error.message))
    })

  }, [])

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    openModal();
    // Perform your form submission logic here
  };
  const lineHeight = {
    hidden: { height: 0 },
    show: { height: '95%' },
  }

  const horizontalLine = {
    hidden: { x: '100%', opacity: 0 }, // Start from the right and hidden
    show: { x: '0%', opacity: 1 },
  }
  return (
    <>
      <div className='relative '>

        <motion.div variants={lineHeight} initial="hidden" whileInView='show' viewport={{ once: false }} transition={{ duration: 0.9, delay: 0.5 }}
          style={{ transformOrigin: 'top-right' }}
          className='snakeline absolute w-[10px] h-full increase1 mt-10 right-0 rounded-full'></motion.div>
        <motion.div variants={horizontalLine} initial='hidden' whileInView='show' exit='hidden' viewport={{ once: false }} transition={{ duration: 0.9, delay: 1 }}
          className='snakeline absolute -bottom-12 h-[10px] w-full increase1 origin-bottom-right'></motion.div>
        {/* <motion.div variants={horizontalLine} initial="hidden" whileInView='show' viewport={{once:false}} transition={{duration:0.9, delay:1.3}}
    style={{transformOrigin:'bottom'}}
    className='absolute w-full h-[10px] increase1 -bottom-12 origin-right  rounded-full '></motion.div> */}

        <div className='contact '>
          <div className='flex flex-col md:flex-row mx-auto xl:justify-center md:mt-20 flex-wrap xl:flex-nowrap'>
            <h2 className='text-3xl md:text-5xl font-semibold font-Poppins px-8 max-w-md pt-10 '>Ready to take your impact creation to the next level? </h2>

            <div className='m-8 sm:m-8 relative font-Inter'>
              <form className='' name="submit-to-google-sheet" id="my-form" onSubmit={handleSubmit}>

                <Modal
                  shouldCloseOnOverlayClick={false}
                  isOpen={isOpen}
                  onRequestClose={closeModal}
                  contentLabel="Modal"
                  overlayClassName="fixed inset-0 bg-black bg-opacity-70"
                  className="overflow-hidden z-50 w-[85%] h-[25%] top-[30%] left-[8%] md:w-[45%] md:top-[35%] md:left-[28%] absolute  p-10 text-custom-dark bg-custom-grey font-Roboto rounded-lg"
                >
                  <div className='flex justify-between'>
                    <h2 className='font-semibold pb-4 text-3xl'>Form Submitted</h2>
                    <CloseIcon onClick={closeModal} className='absolute right-3 top-6 text-sustally-violet z-100 cursor-pointer' style={{ fontSize: '30px' }} />
                  </div>
                  <p className='text-xl'>Thanks for reaching out to us. We will get in touch with you shortly.</p>

                </Modal>

                <p className=' font-semibold py-2 text-xl md:text-3xl'>My name is  <span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Name" placeholder="Tony Stark" aria-label="Full name"></input></span>.</p>

                {/* <div className='contact-footer font-semibold py-1  text-xl md:text-3xl max-w-7xl'> */}
                {/* <h2 className='text-lg font-semibold py-1 sm:text-2xl'>I am <span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Position" placeholder="CEO" aria-label="Full name"></input></span>, p</h2> */}
                <label htmlFor="position" className="block font-semibold text-xl md:text-3xl">
                  I am{' '}
                  <span className="border-b border-custom-purple py-1">
                    <select
                      id="position"
                      name="position"
                      className="text-md font-semibold text-white opacity-75 border appearance-none bg-transparent border-none py-1 px-2 leading-tight focus:outline-none"
                    >
                      <option className="bg-gray-800 text-white">CXO</option>
                      <option className="bg-gray-800 text-white">Founder</option>
                      <option className="bg-gray-800 text-white">Functional Lead</option>
                      <option className="bg-gray-800 text-white">Sustainability Team</option>
                      <option className="bg-gray-800 text-white">HR Team</option>
                      <option className="bg-gray-800 text-white">Operations Lead</option>
                      <option className="bg-gray-800 text-white">General Admin Team</option>
                      <option className="bg-gray-800 text-white">Team Member</option>
                    </select>
                  </span>
                </label>

                <p className=' font-semibold py-2 text-xl md:text-3xl'>part of<span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Company" placeholder="Stark Industries" aria-label="Full name"></input></span>team.</p>
                {/* </div> */}
                <p className=' font-semibold py-2 text-xl md:text-3xl'>I am interested in  <span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" name="Purpose" type="text" placeholder="interacting with your team" aria-label="Full name"></input></span>.</p>
                <p className='font-semibold py-2 text-xl md:text-3xl'>I'd like to meet with your team, you can contact me at </p>
                <div className='contact-footer font-semibold py-2 text-xl md:text-3xl'><span className='border-b border-custom-purple font-semibold py-1 text-xl md:text-3xl'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Email" placeholder="tony@stark.com" aria-label="Full name"></input></span><p className=' font-semibold  text-xl md:text-3xl ml-1 mr-1 py-1'>Or</p><span className='font-semibold text-xl md:text-3xl border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Phone" placeholder="+91 12345 67890" aria-label="Full name"></input></span></div>
                {/* <Button/> */}
                <button aria-label="Submit Form" name="submit" type="submit" className="btn rounded font-Roboto md:absolute md:left-[40%] "><span class="text-green">Submit</span></button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact