import React from 'react'
import notFound from '../assets/not Found.png'

function BlogNotFound() {
  return (
    <div className='min-h-screen flex flex-col justify-center items-center'>
    <img className='w-[40%]' src={notFound} alt='blog not found image' width={""} height={""}/>
        <h1 className='text-3xl text-sustally-violet font-Poppins '>Blog Not Found</h1>
    </div>
  )
}

export default BlogNotFound