import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import '../common/styles/styles.css'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Input from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';
import phoneNumberCodes from '../common/countrycodelist';

const ariaLabel = { 'aria-label': 'description' };




const style = {
    position: 'fixed',
    top: '55%',
    left: '75%',
    transform: 'translate(-50%, -50%)',
    border: '2px inset rgba(7, 6, 8, 0.2)',
    background: 'radial-gradient(circle at 10% 20%, rgba(90, 92, 106,0.4) 0%, rgba(32, 45, 58,0.5) 81.3%)',
    backdropFilter: 'blur(10px)',

    maxWidth: '40%',

    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '20px',
    width: '37%',
    height: '57%',
    boxShadow: '0px 4px 18px 0px #000'
};

export default function ContactPopup({ isFormSubmitted, sendEmailPopup, popupformRef, open, setOpen, setPressed, handleOpen, handleClose }) {


    const [isopen, setisopen] = useState(false)
    const [isPhoneopen, setisPhoneopen] = useState(false)

    const toggleopen = () => {
        setisopen(!isopen)
    }
    const togglephoneopen = () => {
        setisPhoneopen(!isPhoneopen)
    }
    const [selectedText, setselectedText] = useState('Select')
    const [selectedphone, setselectedphone] = useState('+91')

    const [name, setname] = useState('')

    const [interested, setinterested] = useState('')
    const [email, setemail] = useState('')
    const [phoneNUmber, setphoneNUmber] = useState('')
    const clearvalues = () => {

        setname("")
        setinterested('')
        setemail("")
        setphoneNUmber("")
        setselectedText('Select')
        setselectedphone('+91')
    }

    useEffect(() => {
        if (isFormSubmitted) {
            clearvalues()
        }
    }, [isFormSubmitted])

    return (
        open &&
        <div className='absolute' style={{ zIndex: 330, ...style }}>








            <div style={{ zIndex: -11, borderRadius: '20px', }} className='absolute top-[-20%] right-[-30%]'>
 { /*              <svg width="583" height="446" viewBox="0 0 583 446" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_146_966)">
                        <path d="M419 411.5L548.2 34.1656L520.49 34.4438L50.4999 67L34.5004 67L419 411.5Z" fill="url(#paint0_linear_146_966)" />
                    </g>
                    <defs>
                        <filter id="filter0_f_146_966" x="0.5" y="0.165527" width="581.699" height="445.334" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="17" result="effect1_foregroundBlur_146_966" />
                        </filter>
                        <linearGradient id="paint0_linear_146_966" x1="494.5" y1="64" x2="228.11" y2="239.357" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#8E4DFF" stop-opacity="0.89" />
                            <stop offset="0.0001" stop-color="#8E4DFF" stop-opacity="0.27" />
                            <stop offset="0.37063" stop-color="#6E3BC5" stop-opacity="0.46" />
                            <stop offset="1" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
 */}
            </div>
            <form onSubmit={sendEmailPopup} ref={popupformRef} style={{
                zIndex: 10,
                borderRadius: '20px',
                paddingLeft: '8%',
                paddingRight: '8%',
                border: '2px inset rgba(7, 6, 8, 0.2)',
                background: 'radial-gradient(circle at 10% 20%, rgba(90, 92, 106,0.4) 0%, rgba(32, 45, 58,0.5) 81.3%)',
                backdropFilter: 'blur(10px)',
                paddingTop: '7%',
                // background: 'red',
                paddingBottom: '5%',
            }} className='absolute w-[100%] h-[100%]'>
                <CloseIcon onClick={() => {
                    handleClose()
                    setPressed(null)
                }} className='absolute hover:scale-110 cursor-pointer right-3 top-3 text-white z-100' style={{ fontSize: '40px' }} />
                <span className='mt-[2%] text-white relative  z-99' style={{ fontWeight: '400', width: '90%', fontSize: '18px', letterSpacing: '2.5%', zIndex: 99 }}>
                    <div className='flex flex-row'>
                        <span style={{ marginRight: '20px', }}> My name is{"   "}</span>


                        <Input value={name} onChange={(e) => {
                            setname(e.target.value)
                        }} id='from_name' name="from_name" className='nameclass' placeholder="Tony stark" inputProps={ariaLabel} style={{ color: 'white', }} /><br />
                    </div>
                    <input id="select_one" value={selectedText} name="select_one" style={{ display: 'none' }} />
                    <div className='flex mb-5 mt-3 items-center z-100 '>
                        <span>I'm </span>
                        <Input value={name} onChange={(e) => {
                            setname(e.target.value)
                        }} id='' name="" className='nameclass' placeholder="CXO" inputProps={ariaLabel} style={{ color: 'white', }} />
                    </div><div className='flex mb-5 mt-3 items-center z-100 '>
                        <span>part of </span>
                        <Input value={name} onChange={(e) => {
                            setname(e.target.value)
                        }} id='' name="" className='nameclass' placeholder="Stark industries" inputProps={ariaLabel} style={{ color: 'white', }} />
                    <span>team </span></div>
                    <div className='flex mb-5 mt-3 items-center z-100 '>
                        <span>I am interested in    </span>
                        <Input value={name} onChange={(e) => {
                            setname(e.target.value)
                        }} id='' name="" className='nameclass' placeholder="Teamwork" inputProps={ariaLabel} style={{ color: 'white', }} />
                    </div>  


                    <span >I'd like to meet with your team, you can contact me at</span><br />
                    <div className='flex mt-3 items-center flex-row'>

                        <Input value={email} onChange={(e) => {
                            setemail(e.target.value)
                        }} id="user_email" name='email' className='nameclass' placeholder="YOUR EMAIL" inputProps={ariaLabel} style={{ color: 'white', width: "100%" }} />
                        <span className='font-[500]'>or</span>
                        <div style={{ zIndex: 99, width: '100%' }} className='flex flex-row relative items-center justify-start z-99'>

                            <Input value={phoneNUmber} onChange={(e) => {
                                setphoneNUmber(e.target.value)
                            }} id="phone_number" name='phone_number' className='nameclass ml-5' placeholder="PHONE NUMBER" inputProps={ariaLabel} style={{ color: 'white', width: '100%' }} />
                        </div>

                    </div>

                    <button aria-label="Send Form" name="send" type='submit' className=' absolute text-base hover:scale-105	 font-[700] px-10 py-2 flex items-center justify-center bottom-[-25%] right-[1%] cursor-pointer'
                        style={{
                            backgroundColor: "rgb(142, 77, 255)",
                            color: "black",
                            borderRadius: '30px',
                            boxShadow: 'rgb(19 18 18) 3px 2px 10px -1px'
                        }}>
                        <span>SEND</span>
                    </button>
                </span>
            </form>

        </div>
    );
}