import React from "react";
import blogimg1 from "./blogAssets/blog3.1.png";
import blogimg6 from "./blogAssets/blog3.2.png";
import BlogD from "../components/BlogD";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import downarrow from '../assets/careers_aboutus/downarrow.png'
import { motion, } from 'framer-motion';
import { Link } from 'react-scroll'; 
import BlogContactUs from '../components/BlogContactus';
function Blog3({ selectedblog }) {
    /* <h2 className='text-5xl py-2 font-semibold text-custom-purple'></h2>
        <h2 className='text-3xl my-2'></h2>
        <br></br>
        <br></br>
        <h3 className='text-xl text-custom-purple'></h3> */

    const [scrollTop, setScrollTop] = useState(0);

    const onScroll = () => {
        const winScroll = document.documentElement.scrollTop;
        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 18.5;

        setScrollTop(scrolled);
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <div>
            <div
                className="progressMainStyle"
                style={{ width: `${scrollTop * 4.7}%` }}
            ></div>
            <div
                className="progressMainStyle1 hidden lg:block"
                style={{ height: `${scrollTop}%` }}
            ></div>
            <div className="fixed right-0 top-[40%] bg-transparent hidden lg:block">
                <HashLink smooth to={"#intro"}>
                    <h2
                        style={{}}
                        className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 0 && scrollTop <= 3
                            ? "bg-sustally-violet"
                            : "bg-transparent"
                            }`}
                    >
                        Introduction
                    </h2>
                </HashLink>
                <HashLink smooth to={"#challenges"}>
                    <h2
                        className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 3 && scrollTop <= 7
                            ? "bg-sustally-violet"
                            : "bg-transparent"
                            }`}
                    >
                        Investment Flows
                    </h2>
                </HashLink>
                <HashLink smooth to={"#pitfalls"}>
                    <h2
                        className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 7 && scrollTop <= 15
                            ? "bg-sustally-violet"
                            : "bg-transparent"
                            }`}
                    >
                        Showcasing
                    </h2>
                </HashLink>
                <HashLink smooth to={"#key"}>
                    <h2
                        className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 15 && scrollTop <= 25
                            ? "bg-sustally-violet"
                            : "bg-transparent"
                            }`}
                    >
                        Role of Incubators
                    </h2>
                </HashLink>
            </div>
            <div className="mx-5 md:mx-36 py-24 my-8 font-Inter">
                <h1
                    id="intro"
                    className="text-5xl md:text-7xl  md:py-2 font-semibold font-Poppins text-sustally-violet "
                >
                    The Rise of Niche Incubators, Accelerators and Funds
                </h1>

                <br></br>

                <div className="leading-relaxed text-lg">
                    <h2 className="md:text-5xl text-3xl font-semibold text-sustally-violet  md:w-[70%]">
                        Pioneering Specialized Startup Support Systems
                    </h2>
                    <br></br>
                    <i>
                        Specialised Catalysts: How Niche Incubators and Accelerators are
                        Reshaping the Startup Landscape<br></br>
                        The Impact of Niche Support Systems on Global and Indian Startups
                    </i>
                    <br></br>
                    <br></br>
                    <div className="grid md:grid-cols-2 gap-6 text-justify">
                        <p className="leading-relaxed">
                            Have you ever marvelled at the innovative startups that seem to
                            cater to the most specialised needs? <br></br>
                            <br></br>
                            From sustainable agriculture initiatives to groundbreaking elder
                            tech solutions, there's a place where these ventures are nurtured
                            and primed for success: niche incubators and accelerators.{" "}
                            As the startup world burgeons, it's no longer just about having a
                            unique idea; it's about finding the right environment to let that
                            idea flourish. <br></br>
                        </p>
                        <p className="leading-relaxed">
                            Enter the era of specialised support systems.
                            These aren't just backdrops;they are catalysts propelling startups
                            into global recognition. <br></br>
                            In this blog, we'll spotlight the global game-changers in this
                            realm and the vibrant Indian startup ecosystem. <br></br>
                            Get ready to delve deep into the heart of this niche revolution,
                            where each incubator and accelerator, with its distinct approach,
                            is supporting and actively reshaping the contours of
                            entrepreneurial success.<br></br>
                        </p>
                    </div>
                    <br></br>
                    {/* <BlogD /> */}
                    <br></br>
                    <br></br>
                    <h2 className="md:text-5xl text-3xl font-semibold text-sustally-violet " id="challenges">
                        Investment Flows: A Global and Indian Perspective
                    </h2>
                    <img className="py-3 w-full" src={blogimg1} alt="blog3 main image" width={""} height={""} />
                    <p className="grid md:grid-cols-2 gap-6 py-4">
                        <span>
                            <p className="text-justify">
                                The global startup ecosystem has witnessed a surge in investment
                                flows into niche funds. Investors increasingly recognise the
                                potential of specialised startups, leading to a rise in
                                dedicated funds for sectors such as agro-forestry, plant-based
                                innovations, elder tech, and pet tech. <br></br>
                                The trend reflects a shift in investor sentiment, favouring
                                startups that address specific market needs with tailored
                                solutions.<br></br>
                                In India, the scenario is no different. As a burgeoning startup
                                hub, India has seen a proliferation of niche funds and
                                incubators. <br></br>
                                Investors are keen to tap into the country's diverse startup
                                landscape, which spans sectors like biotechnology,
                                nanotechnology, robotics, and green energy. <br></br>
                            </p>
                        </span>
                        <span className="text-justify">
                            Niche incubators like AIC RAISE have incubated 60+ start-ups and
                            11+ of their start-ups has raised 30+ Crores (together) in
                            external funding and also have invested 15Lakhs in 2 start-ups
                            (together).<br></br>
                            As we transition into showcasing exemplary funds and incubators,
                            we must note that the rise in investment flows into niche funds is
                            not just a passing trend. <br></br>
                            <br></br>
                            It's a testament to the growing recognition of the value that
                            specialised startups bring to the table. With their unique
                            propositions, these startups are capturing the attention of
                            investors and incubators alike.<br></br>
                        </span>
                    </p>
                    <br></br>
                    {/* <BlogD /> */}
                    <br></br>
                    <h3
                        className="md:text-5xl text-3xl text-sustally-violet font-semibold"
                        id="pitfalls"
                    >
                        Showcasing Exemplary Funds and Incubators
                    </h3>
                    <br></br>
                    <p className="text-justify">
                        <i>
                            Now let's delve into the details of some of the most innovative
                            and impactful niche funds and incubators globally and in India.{" "}
                            <br></br>
                            These entities are not just supporting startups but are actively
                            shaping the future of their respective sectors. Let's explore the
                            trailblazers that are making waves in the startup ecosystem.
                        </i>
                        <br></br>
                        <br></br>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">
                                Climate Collective
                            </h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    It is a non-profit organisation dedicated to empowering
                                    entrepreneurs in the Global South. They aim to fortify local
                                    climate startup ecosystems by bridging the gap between the
                                    development and private sectors. <br></br>
                                    The collective emphasises two primary areas: combating climate
                                    change and promoting the circular economy. They believe
                                    startups will be instrumental in developing new technologies
                                    and solutions to achieve net-zero emissions by 2070. <br></br>
                                    Notable programs under their umbrella include
                                    ClimateLaunchpad, India's largest cleantech startup program,
                                    and ElectronVibe, which aids startups in integrating clean
                                    energy into the grid.<br></br>
                                </span>
                                <span>
                                    <br></br>
                                    Here are the successful or popular startups incubated by
                                    Climate Collective<br></br>
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            S4S Technologies - Specializes in food preservation
                                            technologies, aligning with Climate Collective's focus on
                                            sustainability and circular economy.
                                        </li>
                                        <li>
                                            Carbon Masters - Provides carbon management solutions,
                                            directly contributing to Climate Collective's climate
                                            change focus.
                                        </li>
                                    </ul>
                                </span>
                            </p>
                        </span>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">Villgro</h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    Villgro is a testament to the power of innovation combined
                                    with entrepreneurship, aiming to impact underserved
                                    populations. <br></br>
                                    Founded in 2001 by Paul Basil, Villgro's mission revolves
                                    around creating impactful, innovative, and successful social
                                    enterprises. They firmly believe in market-based models as a
                                    sustainable means to solve social issues and achieve
                                    large-scale impact. <br></br>
                                    Over the past two decades, Villgro has refined its incubation
                                    model, working with over 300 entrepreneurs. Their initiatives,
                                    such as iPitch and Unconvention, have evolved into significant
                                    platforms for social entrepreneurs in India, fostering a
                                    symbiotic relationship between entrepreneurs, investors, and
                                    other stakeholders.
                                </span>
                                <span>
                                    <br></br>
                                    Here are the successful or popular startups incubated by
                                    Villgro<br></br>
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            Biosense Technologies - Specializes in non-invasive
                                            medical diagnostic devices, aligning with Villgro's focus
                                            on healthcare innovation for underserved populations.
                                        </li>
                                        <li>
                                            Aindra Systems - Provides AI-based solutions for cervical
                                            cancer screening, directly contributing to Villgro's
                                            healthcare focus.
                                        </li>
                                    </ul>
                                </span>
                            </p>
                            <br></br>
                        </span>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">
                                Elemental Accelerator
                            </h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    T is a nonprofit investor focusing on climate technologies
                                    with profound community impact. With over a decade of
                                    experience in the climate sector, Elemental boasts an active
                                    portfolio of more than 150 companies. <br></br>
                                    Their approach addresses two critical gaps in combating
                                    climate change: funding projects for climate technologies in
                                    communities and integrating equity and access into climate
                                    solutions. They aim to invest in transformative technologies
                                    that pave the way for a resilient and equitable future.{" "}
                                    <br></br>
                                    One of their notable projects includes backing Mast
                                    Reforestation (formerly DroneSeed), which deployed its
                                    technology in Oregon's Henry Creek area, significantly
                                    affected by a 2020 wildfire.
                                </span>
                                <span>
                                    Here are the successful or popular startups incubated by
                                    Elemental Accelerator:
                                    <br></br>
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            Actionfigure - Aligns with Elemental's focus on climate
                                            technologies by providing real-time transportation
                                            information to reduce emissions.
                                        </li>
                                        <li>
                                            Allume Energy - Contributes to Elemental's mission by
                                            offering shared solar solutions for multifamily homes,
                                            promoting clean energy.
                                        </li>
                                        <li>
                                            Amber Kinetics - Specializes in energy storage, a critical
                                            component for renewable energy, aligning with Elemental's
                                            goals.
                                        </li>
                                    </ul>
                                </span>
                            </p>
                            <br></br>
                        </span>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">
                                Village Capital
                            </h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    It is a pioneering force in the world of impact-driven
                                    startups. With a mission to fuel sustainable and impactful
                                    businesses, Village Capital has been unlocking capital for
                                    early-stage entrepreneurs addressing global social, economic,
                                    and environmental challenges. <br></br>
                                    Their approach spans thematic areas such as Climate Innovation
                                    & Adaptation, Economic Mobility, and Equitable Access to
                                    Essential Services. Over the years, they've run over 150
                                    accelerator programs and supported over 1,400 startups
                                    worldwide. <br></br>A notable aspect of their reach is that
                                    90% of these startups are located outside the major hubs of
                                    San Francisco, New York, and Boston, with 45% being women-led.
                                    Their platform, Abaca, has further assisted over 20,000
                                    entrepreneurs in enhancing their investment readiness.
                                </span>
                                <span>
                                    <br></br>
                                    <br></br>
                                    Here are the successful or popular startups incubated by
                                    Village Capital<br></br>
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            ColdHubs - Provides solar-powered walk-in cold rooms for
                                            food storage, aligning with Village Capital's focus on
                                            Climate Innovation & Adaptation.
                                        </li>
                                        <br></br>
                                        <li>
                                            StickEarn - An advertising platform that empowers drivers
                                            to earn extra income, aligning with Economic Mobility.
                                        </li>
                                    </ul>
                                </span>
                            </p>
                        </span>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">
                                Agrifood Accelerator - FAO and SEED
                            </h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    The Agrifood Accelerator Programme is a collaborative
                                    initiative between the Food and Agriculture Organization of
                                    the United Nations (FAO) and the SEED partnership. This
                                    program is designed to support agrifood system startups,
                                    aiming to contribute to the UN's Sustainable Development Goals
                                    (SDGs). <br></br>
                                    The accelerator acknowledges the interconnectedness of all 17
                                    SDGs and addresses challenges across a broad spectrum, from
                                    eradicating poverty and hunger to promoting good health,
                                    gender equality, and climate action. <br></br>
                                    Notable startups under this program include the Amaati Group,
                                    which empowers rural Ghanaian women by farming indigenous
                                    Fonio grains, and Kalahari Honey in Botswana, which trains
                                    farmers to use beehives as 'active' fences to deter elephants,
                                    subsequently marketing the bee products globally.
                                </span>
                                <span>
                                    <br></br>
                                    Here are the successful or popular startups incubated by
                                    Agrifood Accelerator - FAO and SEED
                                    <br></br>
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            ColdHubs - Provides solar-powered walk-in cold rooms for
                                            food storage, aligning with Village Capital's focus on
                                            Climate Innovation & Adaptation.
                                        </li>
                                        <br></br>
                                        <li>
                                            Kalahari Honey - Trains and supplies farmers with beehives
                                            that act as 'active' fences to prevent elephants from
                                            destroying their farms. The farmers sell the bee products
                                            back to Kalahari, which then markets them globally.
                                        </li>
                                    </ul>
                                </span>

                            </p>
                        </span>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">
                                SDG Incubator - AIC RAISE
                            </h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    AIC RAISE is India's first startup incubation center focusing on United Nations Sustainable Development Goals (SDGs). <br></br><br></br>

                                    They work on nurturing and supporting startups that use technology and creativity to solve social and environmental problems. They are part of the prestigious Atal Innovation Mission (AIM) and NITI Aayog, Government of India.

                                </span>
                                <span>
                                    Here are the successful or popular startups incubated by SDG Incubator - AIC RAISE
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            Buyofuel - A startup that focuses on biofuels, aligning with the SDGs related to sustainable energy.
                                        </li>
                                        <li>
                                            IppoPay - A fintech startup that aims to provide easy payment solutions, contributing to the SDGs related to financial inclusion.
                                        </li>
                                    </ul>
                                </span>

                            </p>
                        </span>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">
                                Healthcare, MedTech - TBI
                            </h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    TBI is a dedicated platform for startups in the healthcare and medtech sectors. They provide a conducive environment for startups to innovate and develop solutions that address pressing healthcare challenges. <br></br>

                                    The platform offers mentorship, resources, and support to ensure that startups in the medtech domain can scale and achieve their objectives. Specific startups linked to TBI would require a deeper dive into their portfolio.
                                </span>
                                <span>
                                    Here are the successful or popular startups incubated by Healthcare, MedTech - TBI
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            NeuroEquilibrium - Focuses on diagnosing and treating balance disorders, aligning with TBI's healthcare focus.
                                        </li>
                                        <li>
                                            Module Innovations - Specializes in developing point-of-care diagnostic solutions, directly contributing to TBI's medtech specialization. </li>
                                    </ul>
                                </span>

                            </p>
                        </span>
                        <span>
                            <h2 className="text-xl font-semibold text-sustally-violet py-5">
                                Climate Innovation Fund by Microsoft
                            </h2>
                            <p className="grid md:grid-cols-2 gap-6">
                                <span>
                                    The Climate Innovation Fund by Microsoft is a testament to Microsoft's commitment to sustainability. This fund is dedicated to backing innovative solutions that address climate change and promote environmental sustainability. <br></br><br></br>

                                    By investing in transformative technologies and solutions, Microsoft aims to accelerate the global transition to a low-carbon economy. The specifics of the startups and projects backed by this fund would need further exploration.
                                </span>
                                <span>
                                    Here are the successful or popular startups incubated by Climate Innovation Fund by Microsoft
                                    <br></br>
                                    <ul className="list-disc px-5 text-justify">
                                        <li>
                                            CarbonCure Technologies - A company that specializes in carbon capture technology, aligning with Microsoft's focus on reducing carbon emissions.</li>
                                        <li>
                                            Pachama - A startup that uses AI and machine learning to monitor and manage forest carbon stocks, directly contributing to Microsoft's climate equality and impact themes.   </li>
                                    </ul>
                                </span>

                            </p>
                        </span>
                        <br></br>
                        <br></br>

                        <i>
                            As we delve deeper into the role and impact of these niche incubators and accelerators, it becomes evident that they support startups and actively shape their respective sectors' future trajectories.<br></br>
                            <br></br>
                            <br></br>
                        </i>
                    </p>
                    {/* <BlogD /> */}
                    <br></br>
                    <h3 id="benefits" className='md:text-5xl text-3xl text-sustally-violet font-semibold'>The Role of Incubators and Accelerators in Fostering Startup Success</h3><br></br>
                    <img className="w-[100%] py-5" src={blogimg6} alt="Role of incubators image" width={""} height={""}/>
                    <br></br>
                    <span id="key">
                        <h2 className="text-xl font-semibold text-sustally-violet text-justify">
                            The Transformative Power of Niche Startup Support Systems
                        </h2>
                        <br></br>
                        <p className="grid md:grid-cols-2 gap-6 text-justify ">
                            <span>
                                In today's dynamic entrepreneurial landscape, niche incubators, accelerators, and funds have emerged as pivotal catalysts, reshaping the startup ecosystem. <br></br>

                                Their specialized approach offers startups the precision-tailored guidance and resources they need, enhancing success rates and fostering sustainable growth. This not only benefits the startups but also amplifies innovation tailored to unique market needs.<br></br>

                                For stakeholders, the value is evident. Investors are presented with opportunities that align better with market demands, minimizing risks and amplifying potential returns. Entrepreneurs, on the other hand, gain from focused mentorship and resources, ensuring their ventures are both viable and scalable. <br></br>

                            </span>
                            The ripple effect extends to the broader economy, with niche startups filling market gaps, generating employment, and propelling technological advancements.<br></br>

                            As we reflect on this transformative journey, it's clear that the future of startups hinges not just on innovation but on purpose-driven innovation, bolstered by the right support. <br></br><br></br>

                            The horizon ahead is promising, with niche support systems continuing to be the cornerstone of entrepreneurial triumph, steering change, and envisioning a future enriched by targeted innovation.<br></br>

                        </p>
                    </span>
                </div>
            </div>
        <div className="flex mx-auto justify-center items-center max-w-5xl mb-10">
        <h2 className="text-xl italic text-center">*This article is independently written and not sponsored by any of the companies mentioned; it is provided for informational purposes only and does not constitute legal or financial advice.*</h2>
        </div>
            <div className="md:mx-36 border-2 border-custom-grey dark:border-2 dark:border-custom-dark p-7 rounded-xl items-center mx-4">
        <p className="text-xl text-center"><i>If this article has captured your interest and you'd like to delve deeper, don't hesitate to connect with us. We'd love to hear your thoughts! </i></p>
      </div><br></br>
      <div className='flex justify-center py-16 min-h-[30%] items-center mx-auto'>
        <Link to="process" spy={true} smooth={true} offset={50} duration={200}>
          <motion.img
            animate={{ y: -20 }}
            transition={{ duration: 0.8, repeat: Infinity, type: 'spring', repeatDelay: 1, bounce: 0.3, mass: 0.8 }}
            src={downarrow} alt='down arrow' className='w-10 cursor-pointer' width={""} height={""}></motion.img>
        </Link>
      </div>
      {/* <div className="md:static relative">
        <BlogContactUs/>
      </div> */}
        </div>
    );
}

export default Blog3;
