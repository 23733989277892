import React from "react";

function LinkedinCard({ url, name, img, postedOn, description }) {
  return (
    <div className="w-80 md:w-96 h-full bg-secondary-light dark:bg-secondary-dark rounded-b-xl hover:scale-105 duration-300">
      <a
        className="hover:cursor-pointer h-full w-full"
        href={url}
        target="_blank"
      >
        <img
          src={require(`../blog/blogAssets/${img}`)}
          alt={name}
          className="rounded-t-xl"
        />
        <div className="text-lg p-3">
          <h3 className="hover:text-sustally-violet">{name}</h3>
          <p className="text-gray-400">{postedOn}</p>
          <p className="text-gray-500">{description} ...</p>
        </div>
      </a>
    </div>
  );
}

export default LinkedinCard;
