  import "./common/styles/styles.css";
  import React, { createContext, useState, useEffect, useRef } from "react";
  import ReactSwitch from "react-switch";
  import newLightLogo from "./assets/Logomark Black.svg";
  import newDarkLogo from "./assets/Logomark white.svg";
  import PrivacyPolicy from "./pages/PrivacyPolicy";

  import Navbar from "./components/Navbar";
  import Header from "./components/Header";
  import Showcase from "./components/Showcase";
  import Divider from "./components/Divider";
  import ProdRedirect from "./components/ProdRedirect";
  import Contact from "./components/Contact";
  import Footer from "./components/Footer";
  import darkMessage from "./assets/DarkModeMessage.svg";
  import lightMessage from "./assets/LightModeMessage.svg";
  import whyDarkMode from "./assets/whyDarkMode.svg";
  import PricingPlan from "./components/PricingPlan";
  import Underline from "./components/Underline";
  import Underline1 from "./components/Underline1";
  import moonLight from "./assets/moon-lt.svg";
  import sunDark from "./assets/sun0dk.svg";
  import Blog from "./pages/Blog";
  import Faq from "./components/Faq";
  import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
  import BookADemo from "./components/BookADemo";
  import ContactPopup from "./components/ContactPopup";
  import {
    BrowserRouter,
    useNavigate,
    Routes,
    Route,
    Link,
    useLocation,
    useParams,
  } from "react-router-dom";
  import {
    motion,
    useMotionValueEvent,
    useScroll,
    AnimatePresence,
  } from "framer-motion";
  import Pricing from "./components/Pricing";
  import CareerApp from "./pages/CareerApp";
  import Careers from "./pages/Careers";
  import AboutUs from "./pages/AboutUs";
  import ProductDescription from "./components/ProductDescription";
  import Team from "./pages/Team";
  import TermsOfService from "./pages/TermsOfService";
  import MediaKit from "./pages/MediaKit";
  import { HelmetProvider } from "react-helmet-async";
  import AdvisorProfile from './components/AdvisorProfile'
  import AdvisorProfile1 from "./components/AdvisorProfile1";
  import AdvisorProfile2 from "./components/AdvisorProfile2";
  import EmployeeDashBoard from "./components/EmployeeDashboard";
  import AssessmentForm from "./components/AssessmentForm";
  import Employeessignin from "./components/Employeessignin";
  import LearningForm from "./components/LearningForm";
  import ProductSupplyChain from "./pages/ProductSupplyChain";
  import ProductInvestors from "./pages/ProductInvestors";
  import ProductAgencies from "./pages/ProductAgencies";
  import ProductNGO from "./pages/ProductNGO";


  export const ThemeContext = createContext();





  function App() {
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') || 'dark');
    const toggleTheme = () => {
      const newTheme = isDarkMode === "dark" ? "light" : "dark";
      setIsDarkMode(newTheme);
      localStorage.setItem('theme', newTheme);
    };
    useEffect(() => {
      if (isDarkMode === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }, [isDarkMode]);

    const [isFirstPage, setIsFirstPage] = useState(true);

    const [isHovering, setIsHovering] = useState(false);
    const id = useParams;

    const handleMouseOver = () => {
      setIsHovering(true);
    };

    const handleMouseOut = () => {
      setIsHovering(false);
    };
    const [open, setOpen] = React.useState(false);

    const [pressed, setPressed] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = (event, reasor) => {
      setOpen(false);
    };

    // {/* --------------------------------Smooth Scroll on click starts here ------------------------------------------------------------- */ }
    const location = useLocation();

    const [Path, setPath] = useState(
      location.pathname.toLowerCase().substring(1)
    );
    const [IsOnOtherPage, setIsOnOtherPage] = useState(
      Path === "" ? true : false
    );

    useEffect(() => {
      console.log(location.pathname);
      setPath(location.pathname.toLowerCase().substring(1));
    }, [location.pathname]);

    useEffect(() => {
      if (Path == "") {
        setIsOnOtherPage(true);
      } else {
        setIsOnOtherPage(false);
      }
    }, [Path]);

    const navigate = useNavigate();
    const ProductAndFeatureRef = useRef(null);
    const PricingPlanRef = useRef(null);
    const ContactusRef = useRef(null);

    const [scrollToContactUs, setScrollTocontactus] = useState(false);
    const [scrollToProduct, setscrollToProduct] = useState(false);
    const [scrollToPricing, setscrollToPricing] = useState(false);

    useEffect(() => {
      if (scrollToContactUs) {
        setScrollTocontactus(false);
        ContactusRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (scrollToProduct) {
        setscrollToProduct(false);
        ProductAndFeatureRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (scrollToPricing) {
        setscrollToPricing(false);
        PricingPlanRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, [scrollToContactUs, scrollToProduct, scrollToPricing]);

    const navigateHome = () => {
      // 👇️ navigate to /
      navigate("/");
    };

    const titleRef = useRef(null);
    const { scrollY } = useScroll({
      target: titleRef,
      scale: ["end end", "start start"],
    });
    useMotionValueEvent(scrollY, "change", (latest) => {
      // console.log("Page Sroll Value" , latest);
      if (latest > 500) {
        setIsFirstPage(false);
      } else {
        setIsFirstPage(true);
      }
    });

    // --------------------------------Smooth Scroll on Click ends here -------------------------------------------------------------

    //-------------------------------------Cookies start here------------------------------------------------------------------------
    const [Cookiesopen, setCookiesopen] = React.useState(
      localStorage.getItem("visited") ? false : true
    );
    const handleCookiesOpen = () => setCookiesopen(true);
    const handleCookiesClose = () => {
      localStorage.setItem("visited", "true");
      setCookiesopen(false);
    };

    //-------------------------------------Cookies end here---------------------------------------------------------------------------

    const [showDarkModeImage, setShowDarkModeImage] = useState(isDarkMode === "dark" ? true : false);

    useEffect(() => {
      // Hide the image after a brief delay (e.g., 5 seconds)
      const hideImageTimeout = setTimeout(() => {
        setShowDarkModeImage(false);
      }, 5000);

      return () => {
        clearTimeout(hideImageTimeout);
      };
    }, []);

    const helmetContext = {};
    return (
      <HelmetProvider context={helmetContext}>
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
          <div className="p-0 overflow-hidden" id={isDarkMode}>
            {/* --------------------------------Navbar starts here ------------------------------------------------------------- */}

            <div className="flex justify-center pt-2 mx-auto ">
              <div className="navbar rounded-full shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                {/* {isFirstPage && IsOnOtherPage ? (
                  <div className="nav-left">
                    {isDarkMode === "light" ? (
                      <a href='/'><img className="nav-logo" src={lightLogo} alt="sustally" /></a>
                    ) : (
                      <a href='/'><img className="nav-logo" src={darkLogo} alt="sustally" /></a>
                    )}
                  </div>
                ) : (
                  <motion.div className="flex items-center justify-start nav-middle md:mx-auto md:pl-40 ">
                    {isDarkMode === "light" ? (
                      <img initial={{ x: '-80' }} animate={{ x: '0' }} transition={{ duration: 2, ease: "easeIn" }} className="nav-logo" src={lightLogo} alt="sustally" />
                    ) : (
                      <img initial={{ x: '-80' }} animate={{ x: '0' }} transition={{ duration: 2, ease: "easeIn" }} className="nav-logo" src={darkLogo} alt="sustally" />
                    )}
                    <h2 initial={{ x: '100' }} animate={{ x: '0' }} transition={{ duration: 0.75, ease: "easeOut" }} className='text-4xl font-Rymaneco'><a href='/'>sustally</a></h2>
                  </motion.div>
                )} */}

                {isFirstPage && IsOnOtherPage ? (
                  <div className="nav-left">
                    {isDarkMode === "light" ? (
                      <a aria-label="Navigation" href="/">
                        <img
                          className="nav-logo"
                          src={newLightLogo}
                          alt="sustally"
                        />
                      </a>
                    ) : (
                      <a aria-label="Navigation" href="/">
                        <img
                          className="nav-logo"
                          src={newDarkLogo}
                          alt="sustally"
                        />
                      </a>

                    )}
                  </div>
                ) : (
                  <motion.div className="flex items-center justify-start text-2xl nav-middle md:mx-auto md:pl-40">
                    {isDarkMode === "light" ? (
                      <img
                        initial={{ x: "-80" }}
                        animate={{ x: "0" }}
                        transition={{ duration: 2, ease: "easeIn" }}
                        className="nav-logo"
                        src={newLightLogo}
                        alt="sustally"
                      />
                    ) : (
                      <img
                        initial={{ x: "-80" }}
                        animate={{ x: "0" }}
                        transition={{ duration: 2, ease: "easeIn" }}
                        className="nav-logo"
                        src={newDarkLogo}
                        alt="sustally"
                        
                      />

                    )}
                    <h2
                      initial={{ x: "100" }}
                      animate={{ x: "0" }}
                      transition={{ duration: 0.75, ease: "easeOut" }}
                      className="text-4xl md:text-6xl font-Rymaneco"
                    >
                      <a aria-label="sustally" href="/">sustally</a>  
                    </h2>
                  </motion.div>
                )}
                <div className="nav-right ">
                  {/*<label className='switch-lable'>{isDarkMode === "dark" ? "Dark Mode:" : "Light Mode:"}</label> */}
                  <div>
                    <div
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      <div className="toggle-btn relative">
                        <ReactSwitch
                          className="switch"
                          offColor="#0000"
                          onColor="#ffff"
                          onHandleColor="#0000"
                          checked={isDarkMode === "dark"}
                          onChange={toggleTheme}
                          aria-label={isDarkMode === "dark" ? 'Switch to light mode' : 'Switch to dark mode'}
                          checkedIcon={
                            <img
                              src={sunDark}
                              alt="dark"
                              style={{ width: "28px" }}
                              width={""}
                              height={""}
                            />
                          }
                          uncheckedIcon={
                            <img
                              src={moonLight}
                              alt="light"
                              style={{ width: "28px" }}
                              width={""}
                              height={""}
                            />
                          }
                        />

                      </div>
                      {isHovering && (
                        <div>
                          {isDarkMode === "dark" ? (
                            // <div className='toggle-hv' style={{
                            //   backgroundColor: isDarkMode==="dark"? "black":"white"
                            // }}>
                            //   <p className='toggle-p'>Ready for a change of scenery? Switch to light mode and see things in a new light!</p>
                            // </div>
                            <img
                              src={lightMessage}
                              alt="Swith To Dark Mode"
                              className="dlmessage absolute md:right-2 right-6"
                              width={""}
                              height={""}
                            />
                          ) : (
                            // <div className='toggle-hv' style={{
                            //   backgroundColor: isDarkMode==="dark"? "black":"white"
                            // }}>
                            //   <p className='toggle-p'>Feeling the glare? Switch to dark mode and give your eyes a break!</p>
                            // </div>
                            <img
                              src={darkMessage}
                              alt="Swith To Light Mode"
                              className="dlmessage absolute right-8 md:right-10 "
                              width={""}
                              height={""}
                            />
                          )}
                        </div>
                      )}
                    </div>


                    <div>
                      <AnimatePresence>
                        {IsOnOtherPage && showDarkModeImage && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className=""
                          >
                            <img
                              src={whyDarkMode}
                              alt="Swith To Dark Mode"
                              className="dlmessage absolute md:right-2 right-6"
                              width={""}
                              height={""}
                            />

                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>

                  <div className="nav-m ">
                    <Navbar
                      setscrollToProduct={setscrollToProduct}
                      setscrollToPricing={setscrollToPricing}
                      navigateHome={navigateHome}
                      ProductAndFeatureRef={ProductAndFeatureRef}
                      PricingPlanRef={PricingPlanRef}
                      ContactusRef={ContactusRef}
                      isDarkMode={isDarkMode}
                      setScrollTocontactus={setScrollTocontactus}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* --------------------------------Navbar Ends here ------------------------------------------------------------- */}
            <div className="overflow-hidden App" id={isDarkMode}>
              {/* <CookiesModal
                Cookiesopen={Cookiesopen}
                setCookiesopen={setCookiesopen}
                handleCookiesOpen={handleCookiesOpen}
                handleCookiesClose={handleCookiesClose}
              /> */}

              <ContactPopup
                setPressed={setPressed}
                open={open}
                setOpen={setOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
              {/*----------------------------------------------Demo Side menu--------------------------------------------------- */}
              {IsOnOtherPage && (
                <motion.div
                  animate={{ x: [0, -56, 0] }}
                  transition={{ duration: 2 }}
                  style={{ justifyContent: "space-around", zIndex: "300" }}
                  className="floting w-[10%]  md:h-[45%] h-[28%] bottom-[27%] fixed flex flex-col items-center  "
                >
                  <ArrowBackIosIcon className="floting-arrow" />
                  <div
                    title="Contact"
                    onClick={() => {
                      handleOpen();
                      if (pressed === "contact") {
                        setPressed(null);
                        handleClose();
                      } else {
                        setPressed("contact");
                      }
                    }}
                    style={{
                      backgroundColor:
                        pressed === "contact"
                          ? "linear-gradient(#8E4DFF, #251E30)"
                          : "#e7e7e7",
                      boxShadow: "rgb(14 2 4) 1px 2px 9px",
                      justifyContent: "center",
                    }}
                    className="md:h-[22%] h-[18%] flex items-center content-center  rounded-full aspect-square cursor-pointer transition-all hover:scale-150"
                  >
                    {/* <span
                    style={{
                      color: pressed === "contact" ? "white" : "black",
                      fontSize: "10px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    Contact
                  </span> */}
                    <span
                      style={{
                        color: pressed === "contact" ? "black" : "#8e4dff",
                        alignSelf: "center",
                        textAlign: "center",
                      }}
                      class="material-symbols-outlined"
                    >
                      contact_mail
                    </span>
                  </div>
                  <div
                    title="Book A Demo"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgb(255 255 255), rgb(255 255 255))",
                      boxShadow: "rgb(14 2 4) 1px 2px 9px",
                    }}
                    className="book-a-demo md:h-[23%] h-[18%] flex items-center content-center justify-center  rounded-full aspect-square cursor-pointer transition-all hover:scale-150"
                  >
                    <BookADemo />
                  </div>
                  <Link
                    title="FAQ"
                    to="/faq"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgb(255 255 255), rgb(255 255 255))",
                      boxShadow: "rgb(14 2 4) 1px 2px 9px",
                      justifyContent: "center",
                    }}
                    className="md:h-[22%] h-[18%] flex items-center content-center  rounded-full aspect-square cursor-pointer transition-all hover:scale-150"
                  >
                    {/* <span
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    FAQ
                  </span> */}
                    <span
                      style={{
                        color: "#8e4dff",
                        textAlign: "center",
                        alignSelf: "center",
                      }}
                      class="material-symbols-outlined"
                    >
                      quiz
                    </span>
                  </Link>
                </motion.div>
              )}

              {/*-------------------------------------------End od Demo side menu----------------------------------------------- */}

              <Routes>
                <Route
                  path="/"
                  element={
                    <div>
                      <Header />
                      <Underline />

                      <Showcase isDarkMode={isDarkMode} />
                      <Underline />
                      <div className="product_redirect_page">
                        <ProdRedirect />
                      </div>
                      <div className="contactt">
                        <Contact />
                      </div>
                    </div>
                  }
                />
                <Route
                  path="/product"
                  element={
                    <div>
                      <ProductDescription />
                      {/* <ProductFeatures isDarkMode={isDarkMode} /> */}
                      <Underline />
                    </div>
                  }
                />
                <Route
                  path="/pricing"
                  element={
                    <div className="pt-10">
                      <Pricing isDarkMode={isDarkMode} />
                      <Underline1 />
                    </div>
                  }
                />
                <Route
                  path="/blog"
                  element={
                    <Blog
                      setScrollTocontactus={setScrollTocontactus}
                      setscrollToProduct={setscrollToProduct}
                      setscrollToPricing={setscrollToPricing}
                      ContactusRef={ContactusRef}
                      Path={Path}
                      setCookiesopen={setCookiesopen}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
                <Route
                  path="/teamwall"
                  element={
                    <Team
                      setScrollTocontactus={setScrollTocontactus}
                      setscrollToProduct={setscrollToProduct}
                      setscrollToPricing={setscrollToPricing}
                      ContactusRef={ContactusRef}
                      Path={Path}
                      setCookiesopen={setCookiesopen}
                      isDarkMode={isDarkMode}
                    />
                  }
                />

                <Route
                  path="/faq"
                  element={
                    <Faq
                      //   faqpopupformRef={faqpopupformRef}
                      // sendEmailForFaqPopup={sendEmailForFaqPopup}
                      setScrollTocontactus={setScrollTocontactus}
                      setscrollToProduct={setscrollToProduct}
                      setscrollToPricing={setscrollToPricing}
                      ContactusRef={ContactusRef}
                      Path={Path}
                      setCookiesopen={setCookiesopen}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
                <Route
                  path="/career"
                  element={<Careers isDarkMode={isDarkMode} />}
                />
                {/* <Route path="*" element={<PageNotFound />} />*/}
                <Route
                  path="/career/:id"
                  element={<CareerApp isDarkMode={isDarkMode} />}
                />

                <Route
                  path="/aboutus"
                  element={<AboutUs />}
                />
                <Route
                  path="/PrivacyPolicy"
                  element={<PrivacyPolicy isDarkMode={isDarkMode} />}
                />
                <Route path="/TermsOfService" element={<TermsOfService />} />
                <Route path="/MediaKit" element={<MediaKit />} />
                {/* <Route path="/benefits" element={<ProductBenefit/>}/> */}
                <Route path="/employeepage" element={<EmployeeDashBoard/>}/>
                <Route path="/advisorprofile" element={<AdvisorProfile/>}/>
                <Route path="/advisorprofile1" element={<AdvisorProfile1/>}/>
                {/* <Route path="/advisorprofile2" element={<AdvisorProfile2/>}/> */}
                <Route path="/assessmentform" element={<AssessmentForm/>}/>
                <Route path="/learningform" element= {<LearningForm/>}/>
                <Route path="/employeesignin" element={<Employeessignin/>}/>
                <Route path="/product-supply-chain" element={<ProductSupplyChain/>}/>
                <Route path="/product-investors" element={<ProductInvestors/>}/>
                <Route path="/product-agencies" element={<ProductAgencies/>}/>
                <Route path="/product-ngo" element={<ProductNGO/>}/>
              </Routes>

              {/*-------------------------------------------------Footer Starts here ------------------------------------- */}
            </div>
            <div className="foooters">
              <div className="footer">
                <Footer isDarkMode={isDarkMode} />
              </div>
            </div>
          </div>
          {/*-------------------------------------------------Footer Ends here ------------------------------------- */}
        </ThemeContext.Provider>
      </HelmetProvider>
    );
  }

  export default App;
