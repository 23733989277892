import React from "react";
import { auth } from "../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useState, useEffect } from "react";
import Airtable from "airtable";
import done from "../assets/Done.png";

function ApplicationStatus({ person }) {
  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");

  const [user] = useAuthState(auth);
  return (
    <div className="">
      {user?.email === person.fields.mail ? (
        <div className="flex justify-around flex-col md:flex-row">
          <div className="flex flex-col justify-center items-center text-center py-16">
            <img
              style={{ height: "250px", width: "250px", padding: "15px" }}
              src={done}
              alt="done"
              width={""}
              height={""}
            />
            <h2 className="text-2xl">Submitted</h2>
          </div>
          <div className="px-5 md:px-16 py-10">
            <h1 className="text-xl font-bold text-custom-purple py-0">
              Your Details:
            </h1>
            <p className="py-1">Name: {person.fields.Name}</p>
            <p className="py-1">Phone Number: {person.fields.phoneNumber}</p>
            <p className="py-1">Email: {person.fields.mail}</p>
            <p className="py-1">Gendar: {person.fields.Gender}</p>
            <p className="py-1">College Name: {person.fields.CollegeName}</p>
            <p className="py-1">Linkedin: {person.fields.LinkedinURL}</p>
            <p className="py-1">Are You: {person.fields.AreYou}</p>
            <p className="py-1">
              Graduation: {person.fields.Graduation}
              {person.fields.GraduationYear}
            </p>
            <p className="py-1">
              Degree qualification : {person.fields.DegreeQualification}
            </p>
            <p className="py-1">
              Prior Internship or experience Details:{" "}
              {person.fields.PriorExperience}
            </p>
            <p className="py-1">Role: {person.fields.Role}</p>
            <p className="py-1">
              Available work hours: {person.fields.workHours}
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default ApplicationStatus;
