import React from "react";
import sidearrow from "../assets/careers_aboutus/downarrow.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../common/styles/advisor.css";
import { motion } from 'framer-motion';
import { useState } from "react";
import sparkle from "../assets/careers_aboutus/sparkle_colour.svg";
import fotosusi from "../assets/advisor_page/foto-sushi.svg";
import photoKrishnan from "../assets/advisor_page/krishnan.png";
import fotosusi1 from "../assets/advisor_page/susihilkumar.png";
import sagar from "../assets/advisor_page/sagarphoto.png";
import prithviji from "../assets/advisor_page/Prithviji_LI1.png";
import sushilsir from "../assets/advisor_page/sushilsir.png";
import rightwhitearrow from '../assets/rightwhitearrow.svg';

const Advisors = () => {
  const advisorData = [
    {
      adname: "Prof. Sushil Kumar",
      link: "/advisorprofile",
      image: sushilsir,
      designation: "IIM Lucknow, Former IFS",
      linkedin: 'https://www.linkedin.com/in/sushil-kumar-519a886'
    },
    {
      adname: "Sagar Srijan Joshi",
      link: "/advisorprofile1",
      image: sagar,
      designation: "Climate Finance Advisor, Maldives",
      linkedin: 'https://www.linkedin.com/in/sagarsrijanjoshi/'
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const responsiveSettings = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ];

  return (
    <div className="w-full m-auto main mt-32 hover:cursor-pointer">
      <h1 className="text-center md:text-5xl text-3xl font-bold flex items-center justify-center">
        <a href="#" className="flex items-center font-Poppins">
          Discover Our Trusted Advisors

          <motion.img
            initial={{ opacity: 1 }}
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{ duration: 1, repeat: Infinity, type: 'tween' }}
            style={{ height: "60px", width: "60px" }}
            className='ml-4 ' src={sparkle}
            alt='sparkle image' width={""} height={""}>
          </motion.img>
        </a>
      </h1>

      <div className="mt-20 advisors sm:hidden">
        <Slider {...settings} responsive={responsiveSettings}>
          {advisorData.map((d, index) => {


            const isEven = index % 2 === 0;
            const cardClassName = isEven ? "even-card" : "odd-card";
            return (

              <div
                className={`adv-card flex gap-20 h-full`}
                key={d.adname}

              >
                <div className="first rounded-t-xl  pt-10">
                  <a href={d.link}>
                    <img
                      src={d.image}
                      alt={`Profile picture of ${d.adname}`}
                      className="rounded-sm h-60 ml-auto mr-auto"
                    />
                  </a>
                </div>
                <div className="adv-details grid grid-cols-4 ">
                  <div className="name col-span-3 ">
                    <div className="flex">
                      <div>
                        <p className="text-xl">{d.adname}</p>
                        <p className=" text-xs"> {d.designation}</p>
                      </div>
                      <div>
                        <a href={d.linkedin}>
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="profile">
                    <a href={d.link}>
                      <motion.img
                        animate={{ x: [20, 0] }}
                        transition={{
                          duration: 3,
                          repeat: Infinity,
                          type: "spring",
                          repeatDelay: 2,
                          bounce: 0.3,
                          mass: 0.8
                        }}
                        layout="position"
                        style={{ y: 0, rotate: 270 }}
                        src={sidearrow}
                        alt="right arrow icon"
                        width={""}
                        height={""} className="w-14 h-12"></motion.img>
                    </a>
                  </div>
                </div>
              </div>

            );
          })}
        </Slider>
      </div>
      <div className="mt-20 advisors hidden sm:grid sm:ml-auto sm:mr-auto sm:w-auto sm:grid-cols-2 gap-10 relative z-1 md:w-11/12 xl:w-3/4 ml-auto mr-auto">
        {advisorData.map((d, index) => {
          const length = d.length;
          console.log("Length:" + length)
          return (

            <div
              className="adv-card h-full ml-auto mr-auto"
              key={d.adname}

            >
              <a
                href={d.link}>
                <div className="first1 rounded-t-xl  pt-10">
                  <img
                    src={d.image}
                    alt={`Profile picture of ${d.adname}`}
                    className="rounded-sm h-60 ml-auto mr-auto"
                  />
                </div>
              </a>
              <div className="adv-details grid grid-cols-4 ">
                <div className="name col-span-3 py-2">
                  <div className="flex">
                    <div>
                      <p className="text-xl">{d.adname}</p>
                      <p className=" text-xs"> {d.designation}</p>
                    </div>
                    <div>
                      <a href={d.linkedin}>
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{
                            marginLeft: '20px',
                            height: "30px",
                            width: "30px",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="profile">
                  <a href={d.link}>
                    <motion.img
                      animate={{ x: [20, 0] }}
                      transition={{
                        duration: 3,
                        repeat: Infinity,
                        type: "spring",
                        repeatDelay: 2,
                        bounce: 0.3,
                        mass: 0.8
                      }}
                      layout="position"
                      style={{ y: 0, rotate: 270 }}
                      src={sidearrow}
                      alt="right arrow icon"
                      width={""}
                      height={""} className="w-14 h-12"></motion.img>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Advisors;
