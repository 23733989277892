import React, { useEffect, useRef, useState, useMemo } from "react";

import BlogHeader from "../components/BlogHeader";
import BlogContent from "../components/BlogContent";
import Blog1 from "../blog/Blog1";
import Blog2 from "../blog/Blog2";
import LinkedinBlog from "../blog/LinkedinBlog";
import BlogNotFound from "../blog/BlogNotFound";
import Blog3 from "../blog/Blog3";

import SEO from "../components/SEO";

const Blog = ({
  setscrollToProduct,
  setscrollToPricing,
  setScrollTocontactus,
  ContactusRef,
  isDarkMode,
  setCookiesopen,
}) => {
  const abouref = useRef(null);
  const blogRef = useRef(null);
  const timelineRef = useRef(null);
  const benefitsRef = useRef(null);

  const [activeSection, setactiveSection] = useState("about");

  /*
    const isInViewport1 = useIsInViewport(abouref);
  
    const isInViewport2 = useIsInViewport(timelineRef);
  
    const isInViewport3 = useIsInViewport(benefitsRef);
  
    useEffect(() => {
      if (isInViewport3) {
        setactiveSection("benefits");
      } else if (isInViewport2) {
        setactiveSection("timeline");
      } else if (isInViewport1) {
        setactiveSection("about");
      } else {
        setactiveSection(activeSection);
      }
    }, [isInViewport1, isInViewport2, isInViewport3]);
  
    function useIsInViewport(ref) {
      const [isIntersecting, setIsIntersecting] = useState(false);
  
      const observer = useMemo(
        () =>
          new IntersectionObserver(([entry]) =>
            setIsIntersecting(entry.isIntersecting)
          ),
        []
      );
  
      useEffect(() => {
        observer.observe(ref.current);
  
        return () => {
          observer.disconnect();
        };
      }, [ref, observer]);
  
      return isIntersecting;
    }
  */
  const [selectedblog, setSelectedBlog] = useState("");
  useEffect(() => {
    blogRef.current?.scrollIntoView({ behavior: "smooth" });
    setSelectedBlog(window.location.href.split("?")[1]);
  }, []);

  const [selectedblogContent, setSelectedBlogContent] = useState(null);

  return (
    <div>
      <SEO
        title="Blog - Sustally"
        description="Sustally blog posts"
        name="blog"
        type="article"
      />
      <div ref={blogRef}>
        <BlogHeader
          setSelectedBlog={setSelectedBlog}
          setSelectedBlogContent={setSelectedBlogContent}
          selectedblog={selectedblog}
          setactiveSection={setactiveSection}
          activeSection={activeSection}
          benefitsRef={benefitsRef}
          timelineRef={timelineRef}
          abouref={abouref}
          isDarkMode={isDarkMode}
        />

        {/* <BlogContent
        setscrollToPricing={setscrollToPricing}
        setscrollToProduct={setscrollToProduct}
        setScrollTocontactus={setScrollTocontactus}
        ContactusRef={ContactusRef}
        selectedblogContent={selectedblogContent}
        benefitsRef={benefitsRef}
        timelineRef={timelineRef}
        abouref={abouref}
        setCookiesopen={setCookiesopen}
        isDarkMode={isDarkMode}
      />*/}
        {selectedblog === "Beyond-Spreadsheets" ? (
          <Blog1 selectedblog={selectedblog} />
        ) : selectedblog === "Profit-with-Purpose" ? (
          <Blog2 selectedblog={selectedblog} />
        ) : selectedblog === "resources" ? (
          <LinkedinBlog selectedblog={selectedblog} />
        ) : (
          <BlogNotFound />
        )}
        {/* :selectedblog === "3"?<Blog3 /> */}
      </div>
    </div>
  );
};

export default Blog;
