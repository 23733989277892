import '../common/styles/styles.css'
import React, { useEffect, useRef, useState } from 'react';
import spotlight from '../assets/spotlight_gradient.png';
import { motion, useMotionValue, useSpring, useMotionValueEvent, useScroll } from 'framer-motion';
import mobileSpotlight from '../assets/mobile-ray.svg'
import mobileSpotlight2 from '../assets/light ray.svg'
import SEO from "../components/SEO";
function Header() {
  const [height, setHeight]  = useState(0);
  const [mobile, setMobile] = useState(false);
  window.addEventListener("resize", () => {
    const width = window.innerWidth;
    if(width<768){
      setMobile(true);
    }
    else{
      setMobile(false);
    }
  });
  const lineHeight = {
    hidden: {height:0},
    show : {height:'95%'},
    }
  
    const horizontalLine = {
      hidden: {width:0},
      show: {width:'95%'}
    }
  
  return (
    <>
    <SEO 
        title='Sustally'
        description="Discover global standards and best practices of measuring your firm's sustainability efforts and track your ESG impact with Sustally, the comprehensive online platform for sharing your impact with your stakeholders."
        name = 'description'
      />
    <motion.div variants={lineHeight} initial="hidden" whileInView='show' viewport={{once:false}} transition={{duration:0.9, delay:0.2}}
            style={{transformOrigin:'top-right'}}
            className='snakeline absolute w-[10px] h-full increase1 origin-top left-4 top-0 rounded-full '></motion.div>
    <motion.div variants={horizontalLine} initial="hidden" whileInView='show' viewport={{once:false}} transition={{duration:0.9, delay:1.8}}
    className='snakeline absolute w-full h-[10px] increase1 origin-left bottom-10 left-2 rounded-full '></motion.div>
    <motion.div initial ={{y: -50}} animate = {{y:0}} transition={{duration:0.75, ease : "easeOut"}} >
    {/* { mobile ?(
      <div className="spotlight"> </div>
    ) : ( */}
      {/* <div className='relative md:hidden w-screen'>
        <img src={mobileSpotlight} className='absolute rotate-12 top-10 right-6 w-[150%] '></img>
      </div> */}
      <div initial ={{opacity:-1}} animate={{opacity:1}}  style={{ width: `${85 - height}%`,top:'0%', left: '1%'}} className='absolute hidden md:block'>
      <svg
        style={{}}
        width="100%" height="100%" viewBox="0 0 1180 546" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_66_2863)">
          <path d="M399 478L17.5 -5.5L103.5 -18.5L1112 478L743 463.5L399 478Z" fill="url(#paint0_linear_66_2863)" />
        </g>
        <defs>
          <filter id="filter0_f_66_2863" x="-50.5" y="-86.5" width="1230.5" height="632.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="34" result="effect1_foregroundBlur_66_2863" />
          </filter>
          <linearGradient id="paint0_linear_66_2863" x1="755.5" y1="5.99999" x2="755.5" y2="478" gradientUnits="userSpaceOnUse">
            <stop stop-color="#4A4A4A" />
            <stop offset="1" stop-color="#4A4A4A" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
      {/* <img src={spotlight}></img> */}
    </div>
    {/* )} */}
    
    <div className="header ">
      <div className="flex-row text-center">
        <h1 className="text-7xl font-Rymaneco md:text-9xl md:mt-40">
          sustally

        </h1>  
        <sub  className='text-xl font-Inter ml-40 md:ml-72'>
        your impACT Ally</sub>   
        <p className="mt-10 max-w-xs md:max-w-lg font-semibold font-Poppins md:text-3xl text-xl">
        Amplifying your Impact! 
      
        </p>
        <p className="mt-3 max-w-xs md:max-w-lg font-semibold font-Poppins  md:text-3xl text-xl ">
        Connecting your Stakeholders! 
        </p>
      </div>
    </div>
    </motion.div>
    </>
  )
}

export default Header