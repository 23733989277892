import React from 'react'
import downarrow from '../assets/careers_aboutus/downarrow.png'
import metric from '../assets/metric.mp4'
import manageusers from '../assets/manageUsers.mp4'
import reporting from '../assets/reporting.mp4'
import impact from '../assets/impactframework.mp4'
import datacollection from '../assets/datacollection.mp4'
import {motion} from 'framer-motion'
function ProductSupplyChain() {
  return (
    <div className='min-h-screen md:pt-44 pt-20 px-4'>
        <div className='flex flex-col justify-center items-center mx-auto gap-10'>
            <h1 className='font-semibold md:text-3xl text-xl '>Measuring Supply Chain Impact Made Easy</h1>
            <h2 className='font-bold md:text-5xl text-3xl max-w-5xl md:text-center text-sustally-violet font-Poppins'>Are you a large company looking to measure impact of your supply chain?</h2>

            <p className='md:text-3xl text-xl max-w-5xl md:text-center'>Reducing Scope 3 emissions is a top worry for companies dealing with climate impact. With Sustally, transform your suppliers into sustainability partners.
             Use our insights for collaboration, tracking, emissions, risk identification, and performance improvement</p>

            <motion.img   
                        animate={{ y: -20 }}
                        transition={{ duration:0.8 , repeat:Infinity ,type: 'spring', repeatDelay: 1, bounce:0.3, mass: 0.8 }}
                        src={downarrow} alt='down arrow' className='w-16 cursor-pointer' width={""} height={""}>
                            
            </motion.img>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl md:w-full w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={metric} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <p className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet md:pl-10'>Define your supply chain metric & stay in control</p>
                    <ul className='md:text-xl text-md font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4'>
                        <li>Define your own impact metric as needed for your business</li>
                        <li>Leverage our unique Formula Editor to define inputs for the defined metrics</li>
                        <li>Lock the formula to access control, version control</li>
                        <li>Make use of Industry standard formulae curated by Sustally</li>
                    </ul>
                </div>
            </div>
        </div>


        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>        
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center md:order-1 order-2'>
                    <p className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl'>Effectively collaborate with various users across different departments</p>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4'>
                        <li>Access control by defining user roles for data entry, analysis, reporting, administration purpose</li>
                        <li>Scale from just one user to one thousand users seamlessly</li>
                        <li>Open up your portal to collect data from external users without exposing your data</li>
                    </ul>
                </div>
                <div className='flex-1 h-full md:order-2 order-1'>
                    <video loop className='rounded-2xl w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={manageusers} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl  w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={reporting} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <p className='font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10'> Generate data in just a click - SDG, BRSR, your sector specific standards</p>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14 pr-4'>
                        <li>Make use of our standard reports library and generate reports hassle free</li>
                        <li>Be in control - Group your data or modify the standards to quickly adopt to changing standards</li>
                        <li>Create reports for multiple departments managed by you</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>        
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center order-2 md:order-1'>
                    <p className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl'>Design different impact frameworks for each customer or product or vendor category</p>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4'>
                        <li>Create simple framework for small vendor with minimal metrics and a sophisticated framework for larger vendors</li>
                        <li>Categorize themes and subthemes - product wise or facility wise</li>
                        <li>Make use of Industry standard frameworks and templates curated by Sustally</li>
                    </ul>
                </div>
                <div className='flex-1 h-full order-1 md:order-2'>
                    <video loop className='rounded-2xl w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={impact} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl  w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={datacollection} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <p className='font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10'>Stay in control with your single source of truth (data)</p>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14 pr-4'>
                        <li>Capture data directly from the source, from factor personnel to board room executives</li>
                        <li>Leverage the same data collected for multiple reports</li>
                        <li>Make use of data validation and other inbuilt tools to ensure data integrity</li>
                    </ul>
                </div>
            </div>
        </div>

        
    </div>
  )
}

export default ProductSupplyChain