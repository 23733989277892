import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../common/styles/EmployeeDashBoard.css";
import useDrivePicker from "react-google-drive-picker";
import { useLocation } from "react-router-dom";
import onboard from "../common/styles/EmployeeAssets/Onboarding-amico (1).png";
import Managemoney from "../common/styles/EmployeeAssets/Manage money-amico.svg";
import Digitalpersonal from "../common/styles/EmployeeAssets/Digital personal files-amico (1).svg";
import Amico from "../common/styles/EmployeeAssets/New entries-amico.svg";
import sparkle from "../assets/careers_aboutus/sparkle_colour.svg";
import { auth, provider } from "../config/firebase";
import BackButton from "../common/styles/EmployeeAssets/back-button.png";
import Waterfall from "../common/styles/EmployeeAssets/waterfall method-amico.svg";
import Bodyamico from "../common/styles/EmployeeAssets/Editing body text-amico.svg";
import Creditamico from "../common/styles/EmployeeAssets/Credit assesment-amico.svg";
import Nftamico from "../common/styles/EmployeeAssets/NFT-amico.svg";
import Company1 from "../common/styles/EmployeeAssets/company1.svg";
import Company2 from "../common/styles/EmployeeAssets/company2.svg";
import Exit from "../common/styles/EmployeeAssets/exit.jpeg";
import Airtable from "airtable";
import { useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
Modal.setAppElement("#root");

function EmployeeDashboard() {
  // const location = useLocation();
  // const user = location.state?.user || {};

  const [user] = useAuthState(auth);
  const location = useLocation();
  const userEmail = user?.email;
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);

  const [userType, setUserType] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBoxClick = (index) => {
    setIsModalOpen(true);
    setCarouselIndex(index);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleGetStartedClick = (link) => {
    window.open(link, "_blank");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [openPicker, authResponse] = useDrivePicker();

  const listFilesInDrive = () => {
    if (window.gapi && window.gapi.client) {
      const driveClient = window.gapi.client.drive;

      if (driveClient) {
        driveClient.files
          .list({
            q: "sharedWithMe=true and name='_ED' and mimeType='application/vnd.google-apps.folder'",
            spaces: "drive",
          })
          .then((response) => {
            const folderId = response.result.files[0]?.id;
            if (folderId) {
              window.open(
                `https://drive.google.com/drive/folders/${folderId}`,
                "_blank"
              );
            } else {
              console.error("Folder '_ED' not found.");
            }
          })
          .catch((err) => {
            console.error("Error listing files in Google Drive", err);
          });
      } else {
        console.error("Google Drive client not available.");
      }
    } else {
      console.error("Google API client not available.");
    }
  };
  const leader = [
    "anitha@sustally.com",
    "dinesh@sustally.com",
    "sudip@sustally.com",
  ];
  const Inspirer = ["dinesh@sustally.com"];
  const mentor = ["dinesh@sustally.com", "ashwin@sustally.com"];
  const Buddy = ["dinesh@sustally.com", "rahul@sustally.com"];
  const entrant = [
    "dinesh@sustally.com",
    "sudip@sustally.com",
    "nandana@sustally.com",
    "madhavi@sustally.com",
  ];

  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");

  useEffect(() => {
    base("employeetable")
      .select({ view: "Grid view" })
      .eachPage((records, fetchNextPage) => {
        setEmployee(records);
        fetchNextPage();
      });
  });
  const targetMailid = userEmail;
  const specificRecord = employee.find(
    (record) => record.fields.MailId === targetMailid
  );
  useEffect(() => {
    if (specificRecord) {
      setUserType(specificRecord.fields.EmployeeType);
    }
  }, [specificRecord]);

  const AssessmentClick = () => {
    /*
  if(userType === 'Entrant'){
    document.getElementById('assess').href='/assessmentform'
  }else{ 
    window.alert("Oops!, you can't access this")
  }*/
    // document.getElementById('assess').href='/assessmentform'
    // document.getElementById('assess').href='/learningform'
    //document.getElementById('assess').href='/assessment'
    navigate("/assessment");
  };
  const documentClick = () => {
    document.getElementById("documents").href = "/document";
    console.log("hii");
  };

  /* const AssessmentClick=()=>{
    if(entrant.includes(userEmail))
    {
      document.getElementById('assess').href='/assessmentform'
    }
    else
    {
      window.alert('Not accessible')
    }
  }
*/

  // useEffect(() => {
  //   const loadClient = () => {
  //     if (window.gapi) {
  //       window.gapi.client.setApiKey("YOUR_API_KEY");
  //       return window.gapi.client
  //         .load("https://www.googleapis.com/discovery/v1/apis/drive/v3/rest")
  //         .then(
  //           () => {
  //             console.log("GAPI client loaded for API");
  //           },
  //           (err) => {
  //             console.error("Error loading GAPI client for API", err);
  //           }
  //         );
  //     } else {
  //       console.error("gapi is not available.");
  //     }
  //   };

  //   // Load GAPI client on component mount
  //   loadClient();
  // }, []);

  return (
    <div>
      <div className="empcontainer mt-32">
        <h1 className="text-center text-3xl font-bold flex items-center justify-center">
          <a href="#" className="flex items-center">
            Employee Portal
            <img
              className="ml-2" // Add margin to create space between text and image
              src={sparkle}
              alt="hii"
              style={{ height: "60px", width: "60px" }}
            />
          </a>
        </h1>
        <div className="mt- 10 firstgrid grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-16 py-3 md:w-3/4 ml-auto mr-auto">
          <div className="boxcard trans common odd !p-10 !h-auto">
            <h1 className="text-2xl">Team Collaboration</h1>
            <div>
              <img className="benefit-img" src={onboard} alt="" />
            </div>
            <div className="text-center">
              <button
                className="btn-nxt px-7 py-2 rounded-md text-xl text-white"
                onClick={() => handleBoxClick(0)}
                data-modal-target="collaboration-model"
                data-modal-toggle="collaboration-model"
              >
                Next
              </button>
            </div>
          </div>

          <div className="boxcard trans common odd !p-10 !h-auto">
            <h1 className="text-2xl">Reimbursements/ Claims</h1>
            <div>
              <img className="benefit-img" src={Managemoney} alt="" />
            </div>
            <div className="text-center">
              <button
                data-modal-target="default-modal"
                data-modal-toggle="default-modal"
                className="btn-nxt px-7 py-2 rounded-md text-xl text-white"
                onClick={() => handleBoxClick(1)}
              >
                Next
              </button>
            </div>
          </div>

          <div className="boxcard trans common odd !p-10 !h-auto">
            <h1 className="text-2xl">Employment Document</h1>
            <div>
              <img className="benefit-img" src={Digitalpersonal} alt="" />
            </div>
            <div className="text-center">
              <a
                id="documents"
                className="btn-nxt px-7 py-2 rounded-md text-xl text-white"
                // data-modal-target="documents-model"
                // data-modal-toggle="documents-model"

                // onClick={() =>
                //   window.open("https://drive.google.com/drive/", "_blank")
                // }
                onClick={documentClick}
              >
                Next
              </a>
            </div>
          </div>
        </div>

        <div className="grid grid:cols-1 md:grid-cols-4 md:w-full shapes xl:w-11/12 gap-5 xl:gap-12 md:gapx-7 py-2 mt-5">
          <div className="boxcard trans common even md:col-start-2 md:col-end-3 !p-10 !h-auto">
            <h1 className="text-2xl">Departure Exit Form</h1>
            <div>
              <img className="benefit-img" src={Waterfall} alt="" />
            </div>
            <div className="text-center">
              <button
                data-modal-target="exit-modal"
                data-modal-toggle="exit-modal"
                className="btn-nxt px-7 py-2 rounded-md text-xl text-white"
                // onClick={() => handleBoxClick(3)}
              >
                Next
              </button>
            </div>
          </div>
          <div className="boxcard trans common even !p-10 !h-auto">
            <h1 className="text-2xl">Employee Assessment</h1>
            <div>
              <img className="benefit-img" src={Bodyamico} alt="" />
            </div>
            <div className="text-center">
              <a
                className="btn-nxt px-7 py-2 rounded-md text-xl text-white"
                id="assess"
                onClick={AssessmentClick}
              >
                Next
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* carousel for Employee onboarding Forms */}
      <div
        id="collaboration-model"
        tabindex="-1"
        aria-hidden="true"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative p-4 w-full max-w-2xl max-h-full">
          <div class="relative rounded-lg mt-20 md:mt-0 mymodal">
            <div class="flex items-center justify-between border-b rounded-t dark:border-gray-600">
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="collaboration-model"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-10">
              <div className="popupcard trans common odd !p-10 !h-auto">
                <h1 className="text-2xl text-center">Begin Onboarding</h1>
                <div>
                  <img className="benefit-img" src={Waterfall} alt="" />
                </div>
                <div className="text-center">
                  <button
                    className="px-5 py-2 rounded-md text-md modal-btn text-white"
                    onClick={() =>
                      handleGetStartedClick(
                        "https://airtable.com/appuJ9tErxapMp0GT/shrxfy7arqCUQPt9l?prefill_Status=Todo&hide_Status=true"
                      )
                    }
                  >
                    Click
                  </button>
                </div>
              </div>

              <div className="popupcard trans common odd !p-10 !h-auto ">
                <h1 className="text-2xl text-center">View or Update</h1>
                <div>
                  <img className="benefit-img" src={Bodyamico} alt="" />
                </div>
                <div className="text-center">
                  <button
                    className="px-5 py-2 rounded-md text-md modal-btn text-white"
                    onClick={() =>
                      handleGetStartedClick("https://another-link.com")
                    }
                  >
                    Click
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* carousel for Reimbursements/claims */}
      <div
        id="default-modal"
        tabindex="-1"
        aria-hidden="true"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative p-4 w-full max-w-2xl max-h-full">
          <div class="relative rounded-lg mt-20 md:mt-0 mymodal">
            <div class="flex items-center justify-between border-b rounded-t dark:border-gray-600">
              {/* <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Terms of Service
              </h3> */}
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="default-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-10">
              <div className="popupcard trans common odd !p-10 !h-auto">
                <h1 className="text-2xl text-center">Training Reimbursement</h1>
                <div>
                  <img className="benefit-img" src={Nftamico} alt="" />
                </div>
                <div className="text-center">
                  <button
                    className="px-5 py-2 rounded-md text-md modal-btn text-white"
                    onClick={() =>
                      handleGetStartedClick(
                        "https://airtable.com/appuJ9tErxapMp0GT/shrxfy7arqCUQPt9l?prefill_Status=Todo&hide_Status=true"
                      )
                    }
                  >
                    Click
                  </button>
                </div>
              </div>

              <div className="popupcard trans common odd !p-10 !h-auto ">
                <h1 className="text-2xl text-center">Travel Reimbursement</h1>
                <div>
                  <img className="benefit-img" src={Creditamico} alt="" />
                </div>
                <div className="text-center">
                  <button
                    className="px-5 py-2 rounded-md text-md modal-btn text-white"
                    onClick={() =>
                      handleGetStartedClick("https://another-link.com")
                    }
                  >
                    Click
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* carousel for Employee Documents */}
      <div
        id="documents-model"
        tabindex="-1"
        aria-hidden="true"
        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative p-4 w-full max-w-2xl max-h-full">
          <div class="relative rounded-lg mt-20 md:mt-0 mymodal">
            <div class="flex items-center justify-between border-b rounded-t dark:border-gray-600">
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="documents-model"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-10 md:p-16">
              <div className="popupcard trans common odd !p-10 !h-auto">
                <h1 className="text-2xl text-center">Employee Documents</h1>
                <div>
                  <img className="benefit-img" src={Company2} alt="" />
                </div>
                <div className="text-center">
                  <button
                    className="px-5 py-2 rounded-md text-md modal-btn text-white"
                    onClick={listFilesInDrive}
                  >
                    Click
                  </button>
                </div>
              </div>

              <div className="popupcard trans common odd !p-10 !h-auto ">
                <h1 className="text-2xl text-center"> Company Documents</h1>
                <div>
                  <img className="benefit-img" src={Company1} alt="" />
                </div>
                <div className="text-center">
                  <button
                    className="px-5 py-2 rounded-md text-md modal-btn text-white"
                    onClick={() =>
                      window.open("https://drive.google.com/drive/", "_blank")
                    }
                  >
                    Click
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* carousel for Exit Forms */}
      <div
        id="exit-modal"
        tabindex="-1"
        aria-hidden="true"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative p-4 w-full max-w-2xl max-h-full">
          <div class="relative rounded-lg mt-20 md:mt-0 mymodal">
            <div class="flex items-center justify-between border-b rounded-t dark:border-gray-600">
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="exit-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div className="grid grid-cols-1 p-10">
              <div className="popupcard trans common odd !p-10 !h-auto">
                <h1 className="text-2xl text-center">Exit Form</h1>
                <div>
                  <img className="benefit-img" src={Exit} alt="" />
                </div>
                <div className="text-center">
                  <button
                    className="px-5 py-2 rounded-md text-md modal-btn text-white"
                    onClick={() =>
                      handleGetStartedClick(
                        "https://airtable.com/appuJ9tErxapMp0GT/shrxfy7arqCUQPt9l?prefill_Status=Todo&hide_Status=true"
                      )
                    }
                  >
                    Click
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeDashboard;
