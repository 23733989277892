import React from "react";
import prodimage from "../assets/prodredirect.svg"
import sidearrow from '../assets/careers_aboutus/downarrow.png'
import rightwhitearrow from '../assets/rightwhitearrow.svg'
import {motion} from 'framer-motion';
const lineHeight = {
    hidden: {height:0},
    show : {height:'95%'},
}
const horizontalLine = {
    hidden: {width:0},
    show: {width:'100%'}
}

function ProdRedirect(){
    return(
        <div className="relative">
    <motion.div variants={lineHeight} initial="hidden" whileInView='show' viewport={{once:false}} transition={{duration:0.9, delay:0.5}}
            style={{transformOrigin:'top-right'}}
            className='snakeline absolute w-[10px]  increase1 origin-top -top-20 -left-20 rounded-full'></motion.div>
    <motion.div variants={horizontalLine} initial="hidden" whileInView='show' viewport={{once:false}} transition={{duration:0.9, delay:1}}
    className='snakeline absolute w-full h-[10px] increase1 origin-left bottom-36 rounded-full'></motion.div>

        <div className="md:h-screen px-8">
            <div className="flex md:flex-row flex-col gap-10 justify-center mx-auto items-center ">
                <div className="flex flex-col md:gap-14 gap-8 max-w-3xl font-Inter px-4 rounded-2xl py-8 ">
                    <h2 className="md:text-3xl text-2xl font-Poppins">Do you believe your business creates positive impact to the lives it touch upon?</h2>     
                    <div className="flex flex-col gap-2 ">
                    <h2 className="md:text-5xl text-3xl font-semibold font-Poppins">Show the world how <span className="text-sustally-violet">awesome </span></h2>
                    <h2 className="md:text-5xl text-3xl font-semibold font-poppins">your business is</h2> 
                    </div> 
                    <h2 className="md:text-3xl text-2xl font-Poppins">by measuring and sharing your impact to the world in more engaging and timely manner...</h2>               
                </div>
                <div className="mt-10 flex flex-col mx-auto justify-center items-center gap-10 mb-40 md:mb-0" >
                    <img src={prodimage} className="md:w-[55%] w-[45%]" alt="product image" width={""} height={""}></img>
                    <div className="flex items-center justify-center mx-auto  ">
                    <a aria-label="Learn More from Product Page" href="/product" className="flex text-lg items-center justify-center bg-sustally-violet gap-4 px-8 md:px-10 py-2 rounded-full hover:scale-105 duration-200">
                        <h2 className="font-Inter text-lg text-center text-white">Learn More</h2>
                        <motion.img 
                        animate={{ x: [20,0] }}
                        transition={{
                        duration: 3,
                        repeat: Infinity,
                        type: "spring",
                        repeatDelay: 1,
                        bounce: 0.3,
                        mass:0.8
                        }}
                        layout="position"
                        style={{ y: 0 }}
                        src={rightwhitearrow} 
                        alt="right arrow icon"
                        width={""}
                        height={""} className="w-20 h-10"></motion.img>
                    </a>
                    
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ProdRedirect;