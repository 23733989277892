import React from "react";
import LinkedinCard from "./LinkedinCard";
function LinkedinBlog(selectedblog) {
  return (
    <div className="mt-10">
      <div className="pt-36 md:pt-28 md:px-16 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6 md:gap-4 justify-items-center mx-auto items-center">
        <LinkedinCard
          url={
            "https://www.linkedin.com/pulse/nature-society-business-sustally-2gvuc/?trackingId=fEKbpyso5Wvs41lvdyTT8g%3D%3D"
          }
          name={"Nature - Society - Business"}
          img={"naturebusiness.png"}
          postedOn={"August 27, 2024"}
          description={
            "Today, let's delve into the critical topic of how your business interacts with nature and society"
          }
        />
        <LinkedinCard
          url={
            "https://www.linkedin.com/pulse/golden-tap-competitive-advantage-esg-sustally-k8hsc/?trackingId=aM5vr72ZaWQm3txxVy6e9g%3D%3D"
          }
          name={"The Golden Tap for Competitive Advantage in ESG"}
          img={"esg.png"}
          postedOn={"August 16, 2024"}
          description={
            "In this article, we'll help you reflect on the various stages you might go through before making sustainability a competitive advantage in your company"
          }
        />
        <LinkedinCard
          url={
            "https://www.linkedin.com/pulse/cost-europes-cbam-indian-textile-industry-sustally-ojycc/?trackingId=yFdKwcKAN05QAvRtXQ3mLg%3D%3D"
          }
          name={"Cost of Europe's CBAM on Indian Textile Industry"}
          img={"cbam.png"}
          postedOn={"August 7, 2024"}
          description={
            "Follow Sustainable Manufacturing Practices. Save Money for your EU Customer. Otherwise, lose Business to competitors or competing countries. The textile industry could be dealing with a lot more challenges already. One more interesting challenge lingering around is Europe's Carbon Border Adjustment Mechanism (CBAM)"
          }
        />
        {/* <LinkedinCard
          url={
            "https://www.linkedin.com/pulse/nature-society-business-sustally-2gvuc/?trackingId=fEKbpyso5Wvs41lvdyTT8g%3D%3D"
          }
          name={"Nature - Society - Business"}
          img={"naturebusiness.png"}
          postedOn={"August 27, 2024"}
          description={
            "Today, let's delve into the critical topic of how your business interacts with nature and society"
          }
        /> */}
      </div>
    </div>
  );
}

export default LinkedinBlog;
