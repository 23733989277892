import React, { useEffect, useState } from "react";
import {
  Paper,
  IconButton,
  InputBase,
  Divider,
  useTheme,
  withTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../common/styles/styles.css";
import { HashLink } from "react-router-hash-link";
import FaqNotFoundPopup from "../components/FaqNotFoundPopup";
import SEO from "../components/SEO";
import login from "../assets/login.svg";
import support from "../assets/support.svg";
import onboarding from "../assets/onboarding.svg";
import payment from "../assets/payment.svg";
import product from "../assets/product.svg";
import login1 from "../assets/login-bgremove.png";
import support1 from "../assets/support-bgremove.png";
import onboarding1 from "../assets/onboarding1-bgremove.png";
import payment1 from "../assets/payment1-bgremove.png";
import product1 from "../assets/product-bgremove.png";

import { color } from "framer-motion";

const data = [
  {
    id: "LDS1",
    tag: "Login",
    Question: "How can I request a demo of Sustally?",
    Answer: (
      <div>
        To request a demo of Sustally, simply visit our website and fill out the
        demo request form. Our team will get in touch with you to schedule a
        personalized demo session. If you have any other login or demo-related
        inquiries, feel free to{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
  {
    id: "LDS2",
    tag: "Login",
    Question: "I forgot my password. What should I do?",
    Answer: (
      <div>
        If you forget your password, you can easily reset it by clicking on the
        "Forgot Password" link on the login page. Follow the instructions
        provided, and a password reset link will be sent to your registered
        email address. If you encounter any issues during the password recovery
        process, please let us know via our{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>{" "}
        form.
      </div>
    ),
  },
  {
    id: "CSGOQ1",
    tag: "Support",
    Question: "How can I provide feedback or suggestions for improvement?",
    Answer: (
      <div>
        We appreciate your input as it helps us continuously enhance our
        product. If you have any feedback or suggestions to share, please do not
        hesitate to contact us via{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
  {
    id: "CSGOQ2",
    tag: "Support",
    Question: "What should I do if I have a complaint?",
    Answer: (
      <div>
        If you have a complaint, please contact our customer support team
        directly. We are committed to addressing any concerns or issues promptly
        and working with you to find a satisfactory resolution. Your
        satisfaction is our top priority, so please don't hesitate to reach out
        to us via our{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
  {
    id: "CSGOQ3",
    tag: "Support",
    Question: "How can I reach customer support?",
    Answer: (
      <div>
        You can contact our customer support team by sending an email to
        support@sustally.com or by using the support chat feature available
        within the Sustally platform. Our support team is available to assist
        you during regular business hours and will respond to your inquiries as
        soon as possible. If you have any general queries or other
        support-related questions, please feel free to reach out to us via our{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
  {
    id: "TR1",
    tag: "Onboarding",
    Question: "Are there any training resources available?",
    Answer: (
      <div>
        Yes, we provide comprehensive training resources, including user guides,
        tutorials, and video walkthroughs, to help you make the most of
        Sustally. These resources are accessible within the platform's help
        center and are designed to empower you with the knowledge to use
        Sustally effectively. For additional guidance or specific training
        needs, please don't hesitate to contact us through our{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
  {
    id: "BILPAY1",
    tag: "Payment",
    Question: "What are the accepted payment methods?",
    Answer: (
      <div>
        We accept major credit cards, such as Visa, Mastercard, and American
        Express. We also offer the option of electronic bank transfers for
        annual subscription plans.
      </div>
    ),
  },
  {
    id: "BILPAY2",
    tag: "Payment",
    Question: "Is there a refund policy?",
    Answer: (
      <div>
        Yes, we offer a 30-day money-back guarantee. If you are not satisfied
        with our service within the first 30 days of your subscription, we will
        provide a full refund.
      </div>
    ),
  },
  {
    id: "BILPAY3",
    tag: "Payment",
    Question: "How can I update my billing information?",
    Answer: (
      <div>
        You can easily update your billing information by accessing your account
        settings within the Sustally platform. Follow the instructions provided
        to make the necessary changes. If you need any assistance, feel free to
        reach out to us via our{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
  {
    id: "PRFE1",
    tag: "Product",
    Question:
      "What impact / sustainability metrics can I measure with Sustally? ",
    Answer: (
      <div>
        Sustally offers fully customizable framework, configurable to suit your
        business operational needs and ensures the same framework gets linked to
        widely accepted global sustainability reporting standards. Our platform
        allows you to customize and track the specific metrics that matter most
        to your organization.
      </div>
    ),
  },
  {
    id: "PRFE2",
    tag: "Product",
    Question: "Can I integrate Sustally with other software systems?",
    Answer: (
      <div>
        Yes, Sustally provides integration capabilities with popular software
        systems, allowing you to connect and exchange data seamlessly. Our APIs
        and integrations enable smooth collaboration with existing tools,
        maximizing the value of your sustainability data. If you have any
        questions about integration or need assistance, please don't hesitate to
        contact us through our{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
  {
    id: "PRFE3",
    tag: "Product",
    Question: "How secure is my data on Sustally? ",
    Answer: (
      <div>
        We prioritize the security and privacy of your data. Sustally follows
        industry-standard security protocols and uses encryption to protect your
        information. We also regularly conduct security audits and maintain
        strict access controls to ensure the confidentiality and integrity of
        your data. If you have any concerns regarding data security, please
        reach out to us via our{" "}
        <HashLink smooth to="#footer-contact" className="footer-a">
          {" "}
          Contact Us{" "}
        </HashLink>
        .
      </div>
    ),
  },
];

const centeredArray = [2, 5, 8, 11];

function containsOnlySpaces(str) {
  return /^\s*$/.test(str);
}

const Faq = ({
  faqpopupformRef,
  sendEmailForFaqPopup,
  setScrollTocontactus,
  setscrollToProduct,
  setscrollToPricing,
  ContactusRef,
  Path,
  isDarkMode,
  setCookiesopen,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isDivOpen, setIsDivOpen] = useState(false);
  const [dataMain, setdataMain] = useState(data);
  const [search, setSearch] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };

  useEffect(() => {
    if (dataMain.length == 0) {
      setOpen(true);
    }
  }, [dataMain]);
  const filterQuestions = () => {
    if (search == "") {
      setdataMain(data);
    } else {
      let resultingData = data.filter((item, index) => {
        if (item.Question.toLowerCase().includes(search.toLowerCase())) {
          return item;
        }
      });
      setdataMain(resultingData);
    }
  };
  useEffect(() => {
    filterQuestions();
    if (containsOnlySpaces(search) && search !== "") {
      setSearch("");
    }
  }, [search]);

  const theme = useTheme();
  const [selectedTag, setSelectedTag] = useState(null);
  const mode = localStorage.getItem("theme") || "";
  const [activeState, setActiveState] = useState(false);
  console.log("madhu", mode);
  return (
    <>
      <SEO
        title="FAQ - Sustally"
        description="Have questions?  Find answers to your queries related to the product on Sustally's informative FAQ page."
        name="FAQ"
      />
      <div className="Faq_main_container pt-2">
        <div className="Faq_subcontainer h-[40%] pt-[2%] pb-[2%] pr-[2%] mt-20 flex items-center rounded-lg mx-2 ">
          <FaqNotFoundPopup
            faqpopupformRef={faqpopupformRef}
            sendEmailForFaqPopup={sendEmailForFaqPopup}
            open={open}
            setOpen={setOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            search={search}
            setSearch={setSearch}
          />
          <div className="flex flex-col ml-[6%] w-[70%]">
            <span className="font-semibold md:text-5xl text-3xl font-Poppins">
              Ask Us Anything
            </span>
            <span className="font-semibold font-Inter text-xl my-[2%]">
              Have any question? We are here to assist you
            </span>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
                [theme.breakpoints.down("sm")]: {
                  width: 200,
                },
                backgroundColor: mode == "dark" ? "#1e1e1e" : "#eceff8",
              }}
            >
              <IconButton
                sx={{
                  p: "10px",
                  color: "white",
                  pointerEvents: "none",
                  cursor: "default",
                }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ ml: 1, flex: 1, color: "white" }}
                placeholder="Search here"
                inputProps={{ "aria-label": "search google maps" }}
              />

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </Paper>
          </div>
          <div className="sections-title-list-container flex flex-col font-Inter tracking-wide justify-center items-center">
            {["Login", "Support", "Onboarding", "Payment", "Product"].map(
              (item, index) => {
                let content;
                let image;
                switch (item) {
                  case "Login":
                    content = "Login";
                    image = (
                      <img
                        src={mode == "dark" ? login1 : login}
                        alt="Login"
                        className="w-8 h-8 dark:w-6 dark:h-6 md:mx-4 mx-1"
                      />
                    );
                    break;
                  case "Support":
                    content = "Support";
                    image = (
                      <img
                        src={mode == "dark" ? support1 : support}
                        alt="Support"
                        className="w-8 h-8 dark:w-6 dark:h-6 md:mx-4 mx-1"
                      />
                    );
                    break;
                  case "Onboarding":
                    content = "Onboarding";
                    image = (
                      <img
                        src={mode == "dark" ? onboarding1 : onboarding}
                        alt="Onboarding"
                        className="w-8 h-8 dark:w-6 dark:h-6 md:mx-4 mx-1"
                      />
                    );
                    break;
                  case "Payment":
                    content = "Payment";
                    image = (
                      <img
                        src={mode == "dark" ? payment1 : payment}
                        alt="Payment"
                        className="w-8 h-8 dark:w-6 dark:h-6 md:mx-4 mx-1"
                      />
                    );
                    break;
                  case "Product":
                    content = "Product";
                    image = (
                      <img
                        src={mode == "dark" ? product1 : product}
                        alt="Product"
                        className="w-8 h-8 dark:w-6 dark:h-6 md:mx-4 mx-1"
                      />
                    );
                    break;
                  default:
                    content = item;
                    break;
                }

                
                return (
                  <div style={{}}>
                    <div
                      onClick={() => {
                        if (selectedTag === item) {
                          setSelectedTag(null);
                        } else {
                          setSelectedTag(item);
                        }
                      }}
                      
                      className={`${selectedTag === item} my-2 md:mx-2 flex  justify-start items-center w-full`}
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor:
                          selectedTag === item
                            ? "#8e4dfe"
                            : mode == "dark"
                              ? "#1e1e1e"
                              : "#eceff7",
                              ":hover": {
                                backgroundColor: "#8e4dff", // Change the background color on hover
                              },
                        ":nth-child(even)": {
                          marginright: "100px",
                          marginBottom:"0px",
                        },
                      }}
                    >
                      {image}
                      <p className="text-xs md:text-lg py-2">{content}</p>
                      
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        {isDivOpen && (
          <div
            className="backdrop"
            onClick={() => {
              setIsDivOpen(false);
              setSelectedQuestion(null);
            }}
          ></div>
        )}
        <div className="h-[90%]  justify-center grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 mt-10 px-10 grid-auto-flow">
          {dataMain.map((item, index) => {
            // console.log(item)
            if (selectedTag === null || selectedTag === item.tag) {
              return (
                <div
                  onClick={() => {
                    if (item.id === selectedQuestion) {
                      setIsDivOpen(false);
                      setSelectedQuestion(null);
                    } else {
                      setIsDivOpen(true);
                      setSelectedQuestion(item.id);
                    }
                  }}
                  style={{
                    // border: "1px solid grey",
                    marginLeft: "10px",
                    maxHeight: "300px",
                  }}
                  className="faq-cards relative w-[95%] h-[200px] md:h-[120px] cursor-pointer rounded-2xl  mb-5 flex items-center justify-center hover:duration-200"
                >
                  <div className="absolute inset-4 bg-tertiary-light dark:bg-tertiary-dark flex justify-center items-center px-4  text-lg rounded-xl">
                  {/* <KeyboardArrowDownIcon style={{ color: isDarkMode ? 'white' : 'white' }} className='absolute top-[10%] right-[2%]' /> */}
                  <span className="relative  faq-card-questions font-Inter text-center">
                    <b></b>
                    {item.Question}
                  </span>
                  {selectedQuestion && selectedQuestion === item.id && (
                    <div
                      onClick={() => {
                        setIsDivOpen(false);
                      }}
                      style={{
                        display: "none",
                        zIndex: 1000,
                        
                      }}
                      className={`${
                        selectedQuestion &&
                        "popupQandA rounded-lg popupQandA_shadow"
                      } fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-[70%] dark:bg-secondary-light bg-secondary-dark px-10 py-10 cursor-pointer z-20 text-white dark:text-black`}
                    >
                      <CloseIcon
                        onClick={() => {
                          setSelectedQuestion(null);
                        }}
                        className="absolute right-3 top-3  z-100"
                        style={{ fontSize: "40px" }}
                      />

                      <h1
                        style={{ marginBottom: "10px" }}
                        className=""
                      >
                        <b className="">Question: </b>

                        {
                          data.filter(
                            (item, index) => item.id === selectedQuestion
                          )[0].Question
                        }
                      </h1>
                      <span className="text-white dark:text-black">
                        {
                          data.filter(
                            (item, index) => item.id === selectedQuestion
                          )[0].Answer

                        }
                      </span>
                    </div>
                  )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default Faq;