import React from "react";
import blogimg1 from "./blogAssets/blog2.1.png";
import blogimg2 from "./blogAssets/blog2.2.png";
import blogimg3 from "./blogAssets/blog2.3.png";
import blogmg4 from "./blogAssets/blog2.4.png";
import blogimg5 from "./blogAssets/blog1.5.png";
import blogimg6 from "./blogAssets/blog1.6.png";
import BlogD from "../components/BlogD";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import downarrow from '../assets/careers_aboutus/downarrow.png'
import { motion, } from 'framer-motion';
import { Link } from 'react-scroll';
import BlogContactUs from '../components/BlogContactus';

function Blog2({ selectedblog }) {
  /* <h2 className='text-5xl py-2 font-semibold text-custom-purple'></h2>
        <h2 className='text-3xl my-2'></h2>
        <br></br>
        <br></br>
        <h3 className='text-xl text-custom-purple'></h3> */

  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 19;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div>
      <div
        className="progressMainStyle"
        style={{ width: `${scrollTop * 4.54}%` }}
      ></div>
      <div
        className="progressMainStyle1 hidden lg:block"
        style={{ height: `${scrollTop}%` }}
      ></div>
      <div className="fixed right-0 top-[40%] bg-transparent hidden lg:block">
      
        <HashLink smooth to={"#intro"}>
          <h2
            style={{}}
            className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 0 && scrollTop <= 3
                ? "bg-sustally-violet"
                : "bg-transparent"
              }`}
          >
            Introduction
          </h2>
        </HashLink>
        <HashLink smooth to={"#challenges"}>
          <h2
            className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 3 && scrollTop <= 10
                ? "bg-sustally-violet"
                : "bg-transparent"
              }`}
          >
            Case studies
          </h2>
        </HashLink>
        <HashLink smooth to={"#pitfalls"}>
          <h2
            className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 10 && scrollTop <= 15
                ? "bg-sustally-violet"
                : "bg-transparent"
              }`}
          >
            Positive Outcomes
          </h2>
        </HashLink>
        <HashLink smooth to={"#key"}>
          <h2
            className={`py-1 rounded-lg dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 15 && scrollTop <= 19
                ? "bg-sustally-violet"
                : "bg-transparent"
              }`}
          >
            Summary
          </h2>
        </HashLink>
      </div>
      <div className="mx-5 md:mx-36  py-24 my-8 font-Inter">
        <h1
          id="intro"
          className="text-5xl md:text-7xl  md:py-2 font-semibold font-Poppins text-sustally-violet "
        >
          Profit with Purpose
        </h1>

        <br></br>

        <div className="leading-relaxed text-lg">
          <h2 className="md:text-5xl text-3xl font-semibold text-sustally-violet  md:w-[70%]">
            How Ethical Initiatives Boost Business Success
          </h2>
          <br></br>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-justify">
            <p className="leading-relaxed">
              All Factors Considered: How Doing Good (and Bad) Influences the
              Bottom Line<br></br>
              <br></br>
              Ethical Excellence: How Doing Good Translates to Business Growth
              <br></br>
              <br></br>
              Have you ever considered running a profitable and good business
              for the world? Or pondered if doing the right thing can help your
              bottom line?<br></br>
              Today's whirlwind of a business scene is not just about the cash.
              It's about creating an impact.
              <br></br>
            </p>
            <p className="leading-relaxed">
              Businesses that care? They're in. Those that don't? They're out.
              It's not just about being kind anymore; it's a must-have. Why?
              Because it's crucial for how a company is seen and performs.
              <br></br>
              But here's the thing: 'doing good' isn't just about giving money
              to charity. Nope. It's about weaving goodness right into what the
              business is all about.<br></br>
              Think about it. If you're a customer, an employee, or even someone
              supplying goods, wouldn't you prefer a company that cares? And
              guess what? Companies that get this? They're not just like;
              they're thriving.<br></br>
              So, let's get into the nitty-gritty. How can businesses benefit
              from being good?
            </p>
          </div>
          <br></br>
          {/* <BlogD /> */}
          <br></br>
          <br></br>
          <div className="flex flex-col md:flex-row items-center gap-6" id="challenges">
            <img className="md:py-4 md:w-[60%] h-[100%]" src={blogimg1} alt="blog2 main image" width={""} height={""}/>
            <br></br>
            <p className="leading-relaxed text-justify">
              It's not just about the dollars and cents. It's about building
              something that lasts, that's good for everyone.<br></br>
              Let's dive into some stories. Stories of companies that didn't
              just do the bare minimum. They went above and beyond for their
              people.<br></br>
              These initiatives, from physical health programs to mental
              well-being support, showcase how businesses can thrive by doing
              good.<br></br>
              Expect to be inspired by these tales of corporate responsibility
              and the tangible benefits they bring.<br></br>
            </p>
          </div>
          <p className="grid grid-cols-1 md:grid-cols-2 gap-6 py-4">
            <span>
              <span className="text-xl text-sustally-violet  font-semibold">
                1. Zerodha's 'Get Healthy' Initiative: Incentivizing Employee
                Health Goals Post-COVID
              </span>
              <br></br>
              <p className="text-justify">
                Zerodha, a fintech unicorn, launched the 'Get Healthy' program
                in response to the toll the COVID-19 pandemic had on people's
                mental and physical well-being, especially due to prolonged work
                from home. This unique program incentivizes employees to set and
                meet their 12-month health goals. Those who achieve their
                targets are rewarded with a one-month salary as a bonus.
                <br></br>
                <br></br>
                <br></br>
                Additionally, there's a lucky draw with a prize of Rs 10 lakh
                for individuals who meet their health objectives. The program
                was designed to nudge the team towards healthier lifestyles,
                especially after the first lockdown, which saw a decline in
                overall employee health due to factors like lack of physical
                activity, work-life imbalance, and poor diet.
              </p>
            </span>
            <span className="text-justify">
              <span className="text-xl text-sustally-violet  font-semibold text-justify">
                2. Zoho University's Unique Approach: Prioritizing Potential
                Over Degrees and Nurturing Local Talent
              </span>
              <br></br>
              Zoho Corp, known for its suite of software products, has always
              believed in looking beyond conventional wisdom. This belief led to
              the establishment of Zoho University (ZU) in 2005. Instead of
              placing importance on college degrees, Zoho focuses on the
              potential of individuals. ZU started with just six students and
              has, over the years, trained about 300 students. Most of these
              students, many of whom are college dropouts, are now employed by
              Zoho.<br></br>
              The university offers an 18-month training program, including a
              six-month Zoho internship. Students are taught English, math, and
              computer science and receive a stipend during training. Notably,
              there's no job that's off-limits for ZU graduates. They've taken
              roles ranging from engineers to product managers. One standout
              example is Saran Babu, a product of ZU's first batch, who now
              heads Zoho Creator, a popular Zoho product.
            </span>
            <span className="text-justify">
              <span className="text-xl text-sustally-violet  font-semibold text-justify">
                3. Amazon India's Workplace Culture: Fostering Innovation and
                Empowering Employees
              </span>
              <br></br>
              Amazon India, a subsidiary of the global e-commerce giant, has
              been at the forefront of creating a fulfilling workplace for its
              employees. The company's 'Two Pizza Team' approach fosters a
              start-up-like culture, promoting innovation, collaboration, and
              nimbleness. Amazon India empowers its employees to continuously
              improve their skills and innovate on behalf of their customers.{" "}
              <br></br>
              The company has introduced programs such as the 'Single-Threaded
              Leaders' concept, which offers leaders autonomy in problem-solving
              and team management. Amazon's 'Talent flywheel' enables employees
              to break free from self-imposed limitations and make high-judgment
              decisions at scale. The company also offers leadership development
              programs like the Amazon Future Builders Program. It has
              initiatives like 'Svasthya' for overall well-being, 'Ramp Back'
              for new parents, and 'hush-hours' for personal and professional
              tasks.
            </span>
            <span className="text-justify">
              <span className="text-xl text-sustally-violet  font-semibold text-justify">
                4. TCS's Environmental Commitment: Aiming for Zero Emissions and
                Promoting Renewable Energy
              </span>
              <br></br>
              TCS, a global leader in IT services, consulting, and business
              solutions, has consistently demonstrated its commitment to
              sustainability and the well-being of its employees. By 2030, TCS
              got a goal: zero greenhouse gas emissions. Ambitious? Absolutely.
              But it's all part of their grand plan to improve the world for
              their team and everyone. But wait, there's more. <br></br>
              <br></br>
              <br></br>
              They're not just cutting down on the bad stuff. They're upping
              their game with renewable energy. And if there's any bad stuff
              left? They're planting trees to make up for it. TCS isn't just
              talking the talk; they're walking the walk. And it's all about
              creating a green, clean space for their people and the world.
            </span>
          </p>
          <p className="text-justify">
            <span className="text-xl text-sustally-violet font-semibold">
              5.Tech Mahindra's Mantra: Prioritizing Holistic Employee
              Well-being in the Digital Age
            </span>
            <br></br>
            Tech Mahindra, a global leader in digital transformation, has always
            prioritized the holistic well-being of its employees. The company's
            mantra, "Wellness before Business," reflects its commitment to
            ensuring the health and happiness of its workforce. Tech Mahindra
            has introduced many initiatives to enhance the employee experience,
            from the Wealth of Wellness (WoW) program that addresses multiple
            dimensions of wellness to the AMIGO Program and People Care Manager
            Program that focus on mental well-being. <br></br>
            The company also launched Emotional Wellness Self-Assessment Tools
            and curated webinars to help employees manage stress and achieve a
            balanced work-life. Additionally, Tech Mahindra has leveraged
            technology to foster communication, innovation, and bonding among
            teams, even in a remote working environment.
          </p>
          <br></br>
          {/* <BlogD /> */}
          <br></br>
          <h3
            className="md:text-5xl text-3xl text-sustally-violet font-semibold"
            id="pitfalls"
          >
            Summary of Positive Outcomes Across Brands
          </h3>
          <br></br>
          <p className="text-justify">
            <span className="grid gap-6 grid-cols-1 md:grid-cols-2">
              <span>
                <span className="text-xl text-sustally-violet font-semibold">
                  Brand Reputation:
                </span>
                <br></br>
                <img className="py-2" src={blogimg2} alt="Brand reputation image" width={""} height={""} />
                <br></br>
                Across the board, companies like Zerodha, Zoho, Amazon India,
                TCS, and Tech Mahindra have demonstrated a strong commitment to
                their employees, the environment, and innovative practices.{" "}
                <br></br>
                Their initiatives, ranging from employee well-being programs to
                sustainability efforts, have positioned them as industry leaders
                and showcased their dedication to societal and environmental
                responsibilities. <br></br>
                These efforts have garnered significant media attention,
                solidifying their reputations as forward-thinking and
                responsible entities in their respective industries.<br></br>
              </span>
              <span>
                <span className="text-xl text-sustally-violet font-semibold">
                  Financial Impact:
                </span>
                <br></br>
                <img className="py-2" src={blogimg3} alt="Financial impact image" width={""} height={""}/>
                <br></br>
                On the financial front, these companies have observed tangible
                benefits from their initiatives. By focusing on employee health,
                well-being, and upskilling, they have boosted productivity,
                reduced absenteeism, and ensured a workforce that's aligned with
                the company's values and culture. <br></br>
                It leads to direct cost savings and ensures business continuity
                and growth. Moreover, sustainability initiatives, like those of
                TCS, can lead to reduced operational costs in the long run.
                <br></br>
              </span>
            </span>
            <span className="">
              <span className="text-xl text-sustally-violet font-semibold">
                Workforce and Productivity:
              </span>
              <br></br>
              <span className="grid gap-x-6 gap-y-0 items-center   md:grid-cols-2">
                <img className="py-2" src={blogmg4} alt="Workforce and productivity image" width={""} height={""}/>
                <p>
                  {" "}
                  A common theme among these companies is their focus on the
                  holistic well-being of their employees. Providing platforms
                  for continuous learning, feedback, and health ensures a
                  motivated, skilled, and satisfied workforce. <br></br>
                  <br></br>
                  This focus on employee experiences and continuous improvement
                  has cultivated inclusive, empathetic, and productive work
                  environments.<br></br>
                </p>
              </span>
              These brands have set commendable examples by intertwining their
              business goals with societal and environmental responsibilities.
              Their efforts enhance their brand image and ensure a sustainable
              and prosperous future for their businesses and stakeholders.
              <br></br>
              But not all companies in the business arena follow these
              initiatives, which can hinder their brand value and halt their
              long-term growth.<br></br>
            </span>
          </p>
          {/* <BlogD /> */}
          <br></br>
          <h3 className='text-3xl text-sustally-violet font-semibold'>The Cost of Neglecting Positive Initiatives</h3><br></br>
          <br></br>
          <p className="text-justify" id="">
            While positive initiatives can bolster a brand's image, adverse actions, especially those affecting employees, can have lasting repercussions.

            Layoffs, in particular, can cast a shadow over a company's reputation, influencing perceptions of job security and impacting future recruitment efforts.<br></br> <br></br>

            Let's delve into a real-life example to understand this better.<br></br><br></br>

            <span className="">
              <span className="w-[70%]">
                <span className="text-xl text-sustally-violet  font-semibold">
                  BYJU’s: A Case of Massive Layoffs in the Ed-tech Sector
                </span><br></br>
                <br></br>
                Layoffs can tarnish a company's reputation, especially on such a scale. Potential employees might see the company as unstable, deterring top talent from applying.<br></br>

                When giants like BYJU’s conduct layoffs, it raises job security concerns. In contrast, startups might seem riskier. <br></br>

                However, startups offering a nurturing environment might attract talent, especially when established companies are seen as unstable. For instance, while TCS offers perceived job stability, a startup might appeal to candidates by emphasizing growth and well-being initiatives.<br></br>

              </span>
            </span>
            <br></br>
            <br></br>
          </p>
          {/* <BlogD /> */}
          {/* <br></br> */}
          <h3 className='text-3xl text-sustally-violet font-semibold'>The Tangible Impact of Corporate Responsibility</h3><br></br>
          <br></br>
          <p className="grid md:grid-cols-2 text-justify gap-6">
            <span>
              Making money and looking out for everyone involved? It's a tightrope walk. But here's the twist: companies that really care about their people? They often end up with fatter wallets. <br></br><br></br>

              Yep, it's been proven time and again.<br></br><br></br>

              The initiatives undertaken by companies like Zerodha, Zoho, Amazon India, TCS, and Tech Mahindra underscore a clear correlation between corporate responsibility and long-term success. <br></br>

              McKinsey's research encompasses over five million survey responses from employees across 1,700 organizations, indicates that organizational health correlates with financial performance and reflects employee well-being and meaning at work. <br></br>

            </span>
            <span>

              For instance, a banking network's efforts to improve retention among its 12,000 individuals found that organizational health determinants, especially those connected to meaning, correlated more with employees' intention to stay than demographic data.<br></br>

              Doing good in the corporate world isn't just about enhancing brand reputation; it directly impacts a company's financial performance. <br></br>

              Zerodha's wellness program, for example, aims to boost productivity, while Zoho's unique recruitment model leads to significant cost savings. Amazon India's focus on employee well-being can reduce absenteeism, and TCS's sustainability initiatives might lead to reduced operational costs in the long run.<br></br>

            </span>
          </p><br></br>
          <h3 className='text-xl text-sustally-violet font-semibold'>Aligning Social Responsibility with Core Values</h3><br></br>
          <p className="grid md:grid-cols-2 text-justify gap-6">
            <span>
              Companies must ensure that their social responsibility initiatives align with their core values and long-term objectives. Such alignment guarantees that the initiatives are sustainable and resonate with employees and customers. <br></br>

              Tech Mahindra's "Wellness before Business" mantra is a prime example of a company's commitment to its employees being in perfect harmony with its core values.<br></br>

              McKinsey's Organizational Health Index (OHI) is a testament to aligning corporate responsibility with business goals. The index shows organizational health concerns financial performance, meaning, and employee well-being.<br></br>

            </span>
            <span>
              For instance, companies connecting daily work to a grander goal, like Zoho and Zerodha, are seen as providing more meaningful workplaces. Such environments contribute to the overall health of employees and the organization.<br></br><br></br>

              Companies that understand the importance of balancing profits with responsibility are the ones that will thrive in the long run. As we've seen, doing good isn't just morally right; it's also good for business.<br></br>

            </span>
          </p>
          <br></br>
          {/* <BlogD /> */}
          <br></br>
          <span id="key">
            <h2 className="md:text-5xl text-3xl font-semibold text-sustally-violet text-justify">
              Takeaway
            </h2>
            <br></br>
            <p className="grid md:grid-cols-2 gap-6 text-justify ">
              <span>
                The narratives of companies like Zerodha and Zoho underscore a clear message: corporate responsibility and profitability go hand in hand. Combined, these aren't opposing forces but synergistic elements that lead to sustained business success.<br></br>

                Doing good isn't just a moral choice; it's a strategic one. It enhances brand reputation and positively impacts the bottom line. <br></br>

                For businesses already on this path, the journey extends beyond implementation. It's about transparently communicating these efforts to stakeholders.<br></br>


              </span>
              If you're a 'for-profit' business championing impactful initiatives, we invite you to collaborate. <br></br>

              Let's amplify your efforts, align them with global standards, and showcase your commitment. Reach out, and let's redefine what a successful business looks like together.<br></br>

              If you're a 'for-profit' business championing impactful initiatives, we invite you to collaborate. <br></br>

              Let's amplify your efforts, align them with global standards, and showcase your commitment. Reach out, and let's redefine what a successful business looks like together.

            </p>
          </span>
        </div>
      </div>
      <div className="flex mx-auto justify-center items-center max-w-5xl mb-10">
        <h2 className="text-xl italic text-center">*This article is independently written and not sponsored by any of the companies mentioned; it is provided for informational purposes only and does not constitute legal or financial advice.*</h2>
      </div>
      <div className="md:mx-36 border-2 border-custom-grey dark:border-2 dark:border-custom-dark p-7 rounded-xl items-center mx-4">
        <p className="text-xl text-center"><i>If this article has captured your interest and you'd like to delve deeper, don't hesitate to connect with us. We'd love to hear your thoughts! </i></p>
      </div><br></br>
      <div className='flex justify-center py-16 min-h-[30%] items-center mx-auto'>
        <Link to="process" spy={true} smooth={true} offset={50} duration={200}>
          <motion.img
            animate={{ y: -20 }}
            transition={{ duration: 0.8, repeat: Infinity, type: 'spring', repeatDelay: 1, bounce: 0.3, mass: 0.8 }}
            src={downarrow} alt='down arrow' className='w-10 cursor-pointer' width={""} height={""}></motion.img>
        </Link>
      </div>
      {/* <div className="md:static relative">
        <BlogContactUs/>
      </div> */}
    </div>
  );
}

export default Blog2;
