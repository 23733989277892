import React from 'react'
import downarrow from '../assets/careers_aboutus/downarrow.png'
import metric from '../assets/metric.mp4'
import manageusers from '../assets/manageUsers.mp4'
import reporting from '../assets/reporting.mp4'
import impact from '../assets/impactframework.mp4'
import auditlog from '../assets/auditlog.mp4'
import datacollection from '../assets/datacollection.mp4'
import {motion } from 'framer-motion'
function ProductAgencies() {
  return (
    <div className='min-h-screen md:pt-44 pt-20 px-4'>
        <div className='flex flex-col justify-center items-center mx-auto gap-10'>
            <h1 className='font-semibold md:text-3xl text-xl max-w-4xl text-center'>Streamlining Data for Analyst and reporting Agencies</h1>
            <h2 className='font-bold md:text-5xl text-3xl max-w-3xl md:text-center text-sustally-violet font-Poppins'>Are you a reporting agency working with multiple clients?</h2>

            <p className='md:text-3xl text-xl max-w-5xl md:text-center'>Clients not having systems to capture data at source is a top reason for delayed submission of compliance reports. With Sustally, you can setup year-through systematic data collection at source. Make life easy for your analysts, report creators and certification partners</p>

            <motion.img   
                        animate={{ y: -20 }}
                        transition={{ duration:0.8 , repeat:Infinity ,type: 'spring', repeatDelay: 1, bounce:0.3, mass: 0.8 }}
                        src={downarrow} alt='down arrow' className='w-16 cursor-pointer' width={""} height={""}>
                            
            </motion.img>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl md:w-full w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={datacollection} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet md:pl-10'>Collect data points with easy user interface accessible by field incharge to CXOs</h4>
                    <ul className='md:text-xl text-md font-Inter list-disc pb-10 pt-4 pl-10 md:pl-14'>
                        <li>Simplified user interface for data entry</li>
                        <li>Collect data from excel, simple web forms or any existing software </li>
                        <li>Use validation rules to ensure data quality</li>
                    </ul>
                </div>
            </div>
        </div>


        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>        
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center md:order-1 order-2'>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl'>Keep your framework dynamic to changing regulations</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14'>
                        <li>Create multiple frameworks to cater to your clients from different sector</li>
                        <li>Leverage Sustally curated inbuilt frameworks</li>
                        <li>Integrate your framework to your customized reporting requirements</li>
                    </ul>
                </div>
                <div className='flex-1 h-full md:order-2 order-1'>
                    <video loop className='rounded-2xl w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={impact} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl  w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={reporting} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10'> Customize the data points in a single click for various reporting requirements</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14'>
                        <li>Make reporting easy for various standard reports like SDG, BRSR</li>
                        <li>Create brand specific, division specific reports for your clients</li>
                        <li>Reports and dashboard can be accessed from anywhere in a simple browser</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>        
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center order-2 md:order-1'>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl'>Audit trails for transparent reporting and auto notifications for data updates</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14'>
                        <li>Maintain audit log of every activity in Sustally</li>
                        <li>Provide multi-level timestamped data access to your auditors/certification bodies</li>
                        <li>Automatically notify users based on events and alert upcoming deadlines</li>
                    </ul>
                </div>
                <div className='flex-1 h-full order-1 md:order-2'>
                    <video loop className='rounded-2xl w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={auditlog} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl  w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={manageusers} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10'>Access control for data with user management</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14'>
                        <li>Access control and lock edit features based on user permissionst</li>
                        <li>Define user roles for data entry, analysis, reporting, administration purposes</li>
                        <li>Handle multiple clients and their data separately aligning to their respective data-protection policy</li>
                    </ul>
                </div>
            </div>
        </div>

        
    </div>
  )
}

export default ProductAgencies

