import React from 'react'
import downarrow from '../assets/careers_aboutus/downarrow.png'
import metric from '../assets/metric.mp4'
import manageusers from '../assets/manageUsers.mp4'
import reporting from '../assets/reporting.mp4'
import impact from '../assets/impactframework.mp4'
import datacollection from '../assets/datacollection.mp4'
import auditlog from '../assets/auditlog.mp4'
import {motion} from 'framer-motion'
function ProductNGO() {
  return (
    <div className='min-h-screen md:pt-44 pt-24 px-4'>
        <div className='flex flex-col justify-center items-center mx-auto gap-10'>
            <h1 className='font-bold md:text-3xl text-xl max-w-4xl text-center'>Proving with data for NGO or Government Projects</h1>
            <h2 className='font-bold md:text-5xl text-3xl max-w-5xl md:text-center text-sustally-violet font-Poppins'>Are you an NGO or Government wanting to track your project's impact?</h2>

            <p className='md:text-3xl text-xl max-w-5xl md:text-center'>Showcasing your local impact through Globally trusted frameworks, makes your stakeholders relate and appreciate your work.<br></br> With Sustally, you can setup clear output metrics, align them to the intended project outcome and prove impact scientifically</p>

            <motion.img   
                        animate={{ y: -20 }}
                        transition={{ duration:0.8 , repeat:Infinity ,type: 'spring', repeatDelay: 1, bounce:0.3, mass: 0.8 }}
                        src={downarrow} alt='down arrow' className='w-16 cursor-pointer' width={""} height={""}>
                            
            </motion.img>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full justify-center items-center mx-auto'>
                    <video loop className='rounded-2xl md:w-full w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={datacollection} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet md:pl-10'>Easy interface to collect data directly at source</h4>
                    <ul className='md:text-xl text-md font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4'>
                        <li>Collect data from a single person anywhere in the world or from the whole project population at ease</li>
                        <li>Easy to use data collection mechanism like excel, simple web forms or any existing software </li>
                        <li>Use validation rules to ensure data quality </li>
                    </ul>
                </div>
            </div>
        </div>


        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>        
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center md:order-1 order-2'>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl'>Setup Audit trails and maintain data integrity, build trust</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4'>
                        <li>Maintain audit log of every activity in Sustally</li>
                        <li>Provide multi-level timestamped data access to your funding agencies</li>
                        <li>Give access to your live reports, accessible from anywhere in a web browser</li>
                    </ul>
                </div>
                <div className='flex-1 h-full md:order-2 order-1'>
                    <video loop className='rounded-2xl w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={auditlog} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl  w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={impact} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10'>Track multiple projects together and consolidate your overall impact</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14 pr-4'>
                        <li>Create macro framework for your organization and micro frameworks for each projects</li>
                        <li>Access Control who views what, with enhanced data security</li>
                        <li>Use inbuilt metric definitions tool to define impact metrics for various projects</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>        
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center order-2 md:order-1'>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl'>One-Click reporting to your key stakeholders anytime</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4'>
                        <li>Make reporting easy for various standard reports like SDG and other impact reports</li>
                        <li>Create funder specific, geography specific reports for your clients</li>
                        <li>Setup auto email updates for key stakeholders</li>
                    </ul>
                </div>
                <div className='flex-1 h-full order-1 md:order-2'>
                    <video loop className='rounded-2xl w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={reporting} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10 mt-32'>
            <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto'>
                
                <div className='flex-1 h-full'>
                    <video loop className='rounded-2xl  w-[95%] h-full' autoPlay muted playsInline onContextMenu={(e) => e.preventDefault()}>
                    <source src={manageusers} type='video/mp4' />
                    Video about the metric feature in Sustally App.
                    </video>
                </div>
                <div className='flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center '>
                    <h4 className='font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10'>Scale from one user to one thousand users as your project scales</h4>
                    <ul className='md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14 pr-4'>
                        <li>Grow the number of users as the project grows</li>
                        <li>Intuitive user interface, anybody can start using from day 1</li>
                        <li>Group users based on projects with powerful admin management tools</li>
                    </ul>
                </div>
            </div>
        </div>

        
    </div>
  )
}

export default ProductNGO
