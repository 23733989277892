import React from "react";
import "../common/styles/AdvisorProfile.css";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import fotosusi from "../assets/advisor_page/foto-sushi.svg";
import Eplise2 from "../assets/advisor_page/Ellipse 2.svg";
import Eplise3 from "../assets/advisor_page/Ellipse 7@2x.svg";
import Group1 from "../assets/advisor_page/Group 1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eplise11 from "../assets/advisor_page/Ellipse 11.svg";
import eplise10 from "../assets/advisor_page/Ellipse 10.svg";
import eplise8 from "../assets/advisor_page/Ellipse 8.svg";
import sagar from '../assets/advisor_page/sagarphoto.png'
import Ellipse1 from "../assets/advisor_page/Ellipse 1.svg"
const AdvisorProfile1 = () => {
  const isSmallScreen = window.innerWidth < 425;
  let fontstyle = null;
  if (isSmallScreen) {
    fontstyle = {
      height: "20px",
      width: "20px",
    };
  } else {
    fontstyle = {
      height: "50px",
      width: "50px",
    };
  }
  let mode;
  const isDark = localStorage.getItem("theme");
  if (isDark === "dark") {
    mode = true;
  } else {
    mode = false;
  }
  console.log("Dark mode is" + isDark);
  return (
    <div>
      <div className="main3 p-5 md:p-0" style={{ overflow: "visible" }}>
        <div className="first-container grid grid-cols-1 md:grid-cols-2 mt-20 p-6 sm:pb-20">
          <div className="naming items-center justify-center">
            <div className="subname sm:mt-36 sm::ml-20">
              <div className="text-center">
                <h1 className="text-3xl md:text-5xl mt-2 font-bold">
                  Sagar Srijan Joshi
                </h1>
                <p className="text-xl md:text-2xl mt-2">
                Climate Finance Advisor, Maldives
                </p>
                <div className="profile">
                  <a href="#">
                    <FontAwesomeIcon
                      className="icons mt-2"
                      icon={faLinkedin}
                      style={fontstyle}
                    />
                  </a>
                </div>
                {/* <a href="#">
                  <FontAwesomeIcon
                    className="icons"
                    icon={faTwitter}
                    style={fontstyle}
                  />
                </a> */}
              </div>
            </div>
          </div>
          <div className="person relative max-w-96 md:ml-20">
            <div className="person_container rounded-b-[93px] overflow-hidden">
              <img 
                src={Ellipse1}
                className="absolute object-cover inset-x-0 top-auto -bottom-0 ms-auto "
                alt=""
              />
              <img
              src={sagar}
              className="relative h-80 w-80 ml-auto mr-auto sm:mt-4"
              alt=""
            />
            </div>
          </div>
        </div>
        <div className="sec-main md:p-20">
          <div className="second-container">
            <div className="first-img">
              {mode ? (
                <img
                  src={Eplise2}
                  style={{ marginLeft: "-270px" }}
                  className="hidden lg:block absolute h-70 w-70 mt-32"
                  alt=""
                />
              ) : (
                <img
                  src={eplise10}
                  style={{ marginLeft: "-270px" }}
                  className="hidden lg:block absolute h-70 w-70 mt-32"
                  alt=""
                />
              )}
            </div>
            <div className=" md:p-16">
              <h1 className="text-3xl md:text-5xl">About Sagar</h1>

              <p className="text-lg md:text-xl mt-5">
                Sagar Srijan Joshi is an experienced climate finance and
                sustainability professional, dedicated to catalyzing positive
                change in the global fight against climate change. He currently
                serves as a Climate Finance Advisor for the Commonwealth in the
                Maldives, where he leverages his extensive experience to drive
                climate resilience through innovative financial and risk
                management strategies.
                <br />
                <br />
                In his previous work, Sagar supported national-level policy
                drafting in India, co-managed technical assistance programs, and
                developed integrated sustainable strategies for leading Indian
                conglomerates. In addition to his role with the Commonwealth,
                Sagar has been advising Sustally from its inception. His support
                includes advising on product design and development and
                collaborating closely with the company's leadership and staff to
                ensure every aspect of Sustally's work aligns with
                sustainability goals and to aid the company's overarching vision
                where sustainability and profitability go hand in hand.
              </p>
            </div>
          </div>
          <div className="third-container mt-10">
            <div className="md:p-16">
              <h1 className="text-3xl md:text-5xl">Career Highlights</h1>

              <ul
                style={{ paddingLeft: "13px" }}
                className="list-disc border-l-2 pl-4 mt-10 highlight-list"
              >
                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">The Commonwealth</h1>
                  <p className="text-lg md:text-xl">2022-present</p>
                </li>
                
                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    Climate Policy Initiative
                  </h1>
                  <p className="text-lg md:text-xl">2017-2022</p>
                </li>
                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">Tata Sustainability</h1>
                  <p className="text-lg md:text-xl">2016-2017</p>
                </li>

                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    Mahindra Sustainability
                  </h1>
                  <p className="text-lg md:text-xl">2016</p>
                </li>

                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    Ministry Of Corporate Affairs IICA
                  </h1>
                  <p className="text-lg md:text-xl">2016</p>
                </li>

                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    ICICI Wealth Management
                  </h1>
                  <p className="text-lg md:text-xl">2010-2016</p>
                </li>
              </ul>
            </div>
            <div className="second-img">
              <img
                src={Eplise3}
                style={{ marginTop: "-300px", marginLeft: "1200px" }}
                className="hidden lg:block h-70 w-70"
                alt=""
              />
            </div>
          </div>
          <div className="fourth-container mt-10">
            <div className="third-image">
              <img
                src={Group1}
                style={{
                  marginLeft: "-300px",
                }}
                className=" h-72 w-72 hidden md:block absolute mt-16"
                alt=""
              />
            </div>

            <div className="think mt-10">
              <div className="left md:p-16">
                <h1 className=" text-3xl md:text-5xl mb-5">
                  What does Sagar think of Sustally
                </h1>
                <div className="flex h-7">
                  <p className="text-7xl">“</p>
                  <div
                    style={{
                      borderTop: "3px solid",
                      width: "97%",
                      marginTop: "15px",
                      fontFamily: "Verdana, sans-serif",
                    }}
                  ></div>
                </div>
                <p
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                  className="kalam-light text-lg md:text-2xl  pl-5 pr-5 pt-5 "
                >
                  “We are witnessing a mindset shift where sustainability is
                  becoming a fundamental consideration for businesses worldwide.
                  The measurement of sustainability today is similar to the
                  moment five decades ago when measuring and reporting on
                  financial parameters emerged as a critical aspect of business
                  operations. Regulations are tightening, and major players are
                  setting the bar high by requiring partners and vendors - many
                  of which are MSMEs and startups - to incorporate and
                  communicate on sustainability parameters. 
                  
                  <br/>
                  <br/>
                  However, for these
                  enterprises, - many of which are yet to take their first step
                  towards sustainability - integrating it into business
                  operations and managing the complexities of sustainability
                  reporting can be challenging, as they often lack the
                  resources, expertise, and infrastructure necessary for
                  defining the right set of parameters, collecting periodic data
                  and reporting. Sustally aims to bridge this gap for medium and
                  small C&I companies, including MSMEs and startups.
                  
                  <br/>
                  <br/>
                   Sustally
                  can empower these enterprises to define what is material, help
                  them track the data which could then be acted upon to improve
                  the overall organization’s performance and effectively report
                  on key performance metrics. 
                  
                  Sustally can enable even the most
                  resource-constrained businesses to effectively take their
                  initial steps in sustainability and empower companies of all
                  sizes to meet these evolving demands by providing a
                  user-friendly and fully customizable tool to meet their unique
                  needs and thereby helping them to thrive in an increasingly
                  sustainability-conscious business landscape."
                </p>
                <div className="flex h-7">
                  <div
                    style={{
                      borderTop: "3px solid",
                      width: "97%",
                      marginTop: "15px",
                    }}
                  ></div>
                  <p className=" text-7xl">”</p>
                </div>
              </div>
              {/* <div className="right">
              <div className="video-container">
                <iframe
                  className="rounded-lg vdoframe"
                  width="400"
                  height="250"
                  src="https://www.youtube.com/embed/tgbNymZ7vqY"
                ></iframe>
              </div>
            </div> */}

              <div className="forth-image">
                {mode ? (
                  <img
                    src={eplise8}
                    style={{ marginLeft: "1200px", marginTop: "-140px" }}
                    className="hidden lg:block h-72 w-72"
                    alt=""
                  />
                ) : (
                  <img
                    src={eplise11}
                    style={{ marginLeft: "1200px", marginTop: "-140px" }}
                    className="hidden lg:block h-72 w-72"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorProfile1;

// const AdvisorProfile1 = () => {
//   const isSmallScreen = window.innerWidth < 425;
//   let fontstyle = null;
//   if (isSmallScreen) {
//     fontstyle = {
//       height: "20px",
//       width: "20px",
//     };
//   } else {
//     fontstyle = {
//       height: "50px",
//       width: "50px",
//     };
//   }
//   let mode;
//   const isDark = localStorage.getItem("theme");
//   if (isDark === "dark") {
//     mode = true;
//   } else {
//     mode = false;
//   }
//   console.log("Dark mode is" + isDark);
//   return (
//     <div>
//       <div className="main3 p-5 md:p-0" style={{ overflow: "visible" }}>
//         <div className="first-container grid grid-cols-1 md:grid-cols-2 mt-20">
//           <div className="naming items-center justify-center">
//             <div className="subname mt-36 md:ml-20">
//               <div className="w-3/4 text-center">
//                 <h1 className="text-3xl md:text-5xl mt-1">Sagar Srijan Joshi</h1>
//                 <p className="text-xl md:text-2xl mt-1">Climate Finance Advisor, Maldives</p>
//                 <div className="profile">
//                   <a href="#">
//                     <FontAwesomeIcon
//                       className="icons mt-1"
//                       icon={faLinkedin}
//                       style={fontstyle}
//                     />
//                   </a>
//                 </div>
//                 {/* <a href="#">
//                   <FontAwesomeIcon
//                     className="icons"
//                     icon={faTwitter}
//                     style={fontstyle}
//                   />
//                 </a> */}
//               </div>
//             </div>
//           </div>
//           <div className="image">
//             <img src={fotosusi} className="relative" alt="" />
//           </div>
//         </div>
//         <div className="sec-main md:p-20">
//           <div className="second-container">
//             <div className="first-img">
//               {mode ? (
//                 <img
//                   src={Eplise2}
//                   style={{ marginLeft: "-270px" }}
//                   className="hidden lg:block absolute h-70 w-70 mt-32"
//                   alt=""
//                 />
//               ) : (
//                 <img
//                   src={eplise10}
//                   style={{ marginLeft: "-270px" }}
//                   className="hidden lg:block absolute h-60 w-60 mt-32"
//                   alt=""
//                 />
//               )}
//             </div>
//             <div className=" md:p-16">
//               <h1 className="text-3xl md:text-5xl">About Sushil</h1>

//               <p className="text-lg md:text-xl mt-5">
//               Sagar Srijan Joshi is an experienced climate finance and sustainability professional,
//              dedicated to catalyzing positive change in the global fight against climate change.
//              He currently serves as a Climate Finance Advisor for the Commonwealth in the Maldives,
//              where he leverages his extensive experience to drive climate resilience through innovative
//              financial and risk management strategies.
//              <br/>
//              <br/>

//              In his previous work, Sagar supported national-level
//              policy drafting in India, co-managed technical assistance programs, and developed integrated
//              sustainable strategies for leading Indian conglomerates.
//              In addition to his role with the Commonwealth, Sagar has been advising Sustally from its inception.
//              His support includes advising on product design and development and collaborating closely with the
//              company's leadership and staff to ensure every aspect of Sustally's work aligns with sustainability
//               goals and to aid the company's overarching vision where sustainability and profitability go hand
//             in hand.
//               </p>
//             </div>
//           </div>
//           <div className="third-container mt-10">
//             <div className="md:p-16">
//               <h1 className="text-3xl md:text-5xl">Highlights</h1>

//               <ul
//                 style={{ paddingLeft: "13px" }}
//                 className="list-disc border-l-2 pl-4 mt-10 highlight-list"
//               >
//                 <li>
//                   <h1 className="text-xl md:text-2xl">
//                     Professor, IIM Lucknow
//                   </h1>
//                   <p className="text-lg md:text-xl">2009-present</p>
//                 </li>

//                 <li className="mt-5">
//                   <h1 className="text-xl md:text-2xl">
//                     Ph. D, University Of Toronto
//                   </h1>
//                   <p className="text-lg md:text-xl">2004-2005</p>
//                 </li>

//                 <li className="mt-5">
//                   <h1 className="text-xl md:text-2xl">
//                     Indian Forest Service (IFS) officer
//                   </h1>
//                   <p className="text-lg md:text-xl">1998-2004</p>
//                 </li>
//               </ul>
//             </div>
//             <div className="second-img">
//               <img
//                 src={Eplise3}
//                 style={{ marginTop: "-300px", marginLeft: "1230px" }}
//                 className="img-sec hidden lg:block h-70 w-70"
//                 alt=""
//               />
//             </div>
//           </div>
//           <div className="fourth-container mt-10">
//             <div className="third-image">
//               <img
//                 src={Group1}
//                 style={{
//                   marginLeft: "-300px",
//                 }}
//                 className=" h-72 w-72 hidden md:block absolute mt-16"
//                 alt=""
//               />
//             </div>

//             <div className="think mt-10">
//               <div className="left md:p-16">
//                 <h1 className=" text-3xl md:text-5xl mb-5">
//                   What does Sushil think of Sustally
//                 </h1>
//                 <div className="flex h-7">
//                   <p className="text-7xl">“</p>
//                   <div
//                     style={{
//                       borderTop: "3px solid",
//                       width: "97%",
//                       marginTop: "15px",
//                       fontFamily: "Verdana, sans-serif",
//                     }}
//                   ></div>
//                 </div>
//                 <p style={{textAlign:'justify',
//   textJustify: "inter-word"}} className="text-lg md:text-xl border-r-2 border-l-2 pl-5 pr-5 pt-5 border-black dark:border-white">
//                   “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                   do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   Ut enim ad minim veniam, quis nostrud exercitation ullamco
//                   laboris nisi ut aliquip ex ea commodo consequat. Duis aute
//                   irure dolor in reprehenderit in voluptate velit esse cillum
//                   dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//                   cupidatat non proident, sunt in culpa qui officia deserunt
//                   mollit anim id est laborum.
//                   <br />
//                   <br />
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                   do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                   Ut enim ad minim veniam, quis nostrud exercitation ullamco
//                   laboris nisi ut aliquip ex ea commodo consequat. Duis aute
//                   irure dolor in reprehenderit in voluptate velit esse cillum
//                   dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//                   cupidatat non proident, sunt in culpa qui officia deserunt
//                   mollit anim id est laborum.sed do eiusmod tempor incididunt ut
//                   labore et dolore magna aliqua. Ut enim ad minim veniam, quis
//                   nostrud exercitation ullamco laboris nisi ut aliquip ex ea
//                   commodo consequat. “
//                 </p>
//                 <div className="flex h-7">
//                   <div
//                     style={{
//                       borderTop: "3px solid",
//                       width: "97%",
//                       marginTop: "15px",
//                     }}
//                   ></div>
//                   <p className=" text-7xl">”</p>
//                 </div>
//               </div>
//               {/* <div className="right">
//               <div className="video-container">
//                 <iframe
//                   className="rounded-lg vdoframe"
//                   width="400"
//                   height="250"
//                   src="https://www.youtube.com/embed/tgbNymZ7vqY"
//                 ></iframe>
//               </div>
//             </div> */}

//               <div className="forth-image">
//                 {mode ? (
//                   <img
//                     src={eplise8}
//                     style={{ marginLeft: "1150px", marginTop: "-140px" }}
//                     className="hidden lg:block h-72 w-72"
//                     alt=""
//                   />
//                 ) : (
//                   <img
//                     src={eplise11}
//                     style={{ marginLeft: "1150px", marginTop: "-140px" }}
//                     className="hidden lg:block h-72 w-72"
//                     alt=""
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default AdvisorProfile1;
