import React from "react";
import "../common/styles/AdvisorProfile.css";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import fotosusi from "../assets/advisor_page/susihilkumar.png";
import Eplise2 from "../assets/advisor_page/Ellipse 2.svg";
import Eplise3 from "../assets/advisor_page/Ellipse 7@2x.svg";
import Group1 from "../assets/advisor_page/Group 1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eplise11 from "../assets/advisor_page/Ellipse 11.svg";
import eplise10 from "../assets/advisor_page/Ellipse 10.svg";
import eplise8 from "../assets/advisor_page/Ellipse 8.svg";
import sushilsir from "../assets/advisor_page/sushilsir.png";
import Ellipse1 from '../assets/advisor_page/Ellipse 1.svg';
const Advisor = () => {
  const isSmallScreen = window.innerWidth < 425;
  let fontstyle = null;
  if (isSmallScreen) {
    fontstyle = {
      height: "20px",
      width: "20px",
    };
  } else {
    fontstyle = {
      height: "50px",
      width: "50px",
    };
  }
  let mode;
  const isDark = localStorage.getItem("theme");
  if (isDark === "dark") {
    mode = true;
  } else {
    mode = false;
  }
  console.log("Dark mode is" + isDark);
  return (
    <div>
      <div className="main3 p-5 md:p-0" style={{ overflow: "visible" }}>
        <div className="first-container grid grid-cols-1 md:grid-cols-2 mt-20 p-6 md:p-20">
          <div className="naming items-center justify-center">
            <div className="subname sm:mt-28 ">
              <div className="text-center">
                <h1 className="text-3xl md:text-5xl font-bold">
                  Prof. Sushil Kumar
                </h1>
                <p className="text-xl md:text-2xl mt-2">
                  Professor, IIM Lucknow
                </p>
                <div className="profile">
                  <a href="#">
                    <FontAwesomeIcon
                      className="icons mt-2"
                      icon={faLinkedin}
                      style={fontstyle}
                    />
                  </a>
                </div>
                {/* <a href="#">
                  <FontAwesomeIcon
                    className="icons"
                    icon={faTwitter}
                    style={fontstyle}
                  />
                </a> */}
              </div>
            </div>
          </div>
          <div className="person relative max-w-96 md:ml-20">
            <div className="person_container rounded-b-[90px] overflow-hidden">
              <img 
                src={Ellipse1}
                className="absolute object-cover inset-x-0 top-auto -bottom-0 ms-auto "
                alt=""
              />
              <img
                src={sushilsir}
                className="relative h-80 w-80"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="sec-main md:p-20">
          <div className="second-container">
            <div className="first-img">
              {mode ? (
                <img
                  src={Eplise2}
                  style={{ marginLeft: "-270px" }}
                  className="hidden lg:block absolute h-70 w-70 mt-32"
                  alt=""
                />
              ) : (
                <img
                  src={eplise10}
                  style={{ marginLeft: "-270px" }}
                  className="hidden lg:block absolute h-70 w-70 mt-32"
                  alt=""
                />
              )}
            </div>
            <div className=" md:p-16">
              <h1 className="text-3xl md:text-5xl">About Sushil</h1>

              <p className="text-lg md:text-xl mt-5">
                Prof. Sushil Kumar is a Professor of Food and Agribusiness,
                Business Sustainability and Human Resource Management in the
                Indian Institute of Management (IIM) Lucknow. Prior to joining
                IIM Lucknow in April 2005, he was SSHRC postdoctoral fellow at
                the University of Toronto, Toronto (Canada) from where he
                completed his Doctoral studies too. Earlier, he was a member of
                the prestigious All India Service - Indian Forest Services (IFS)
                - belonging to the 1988 batch. He has been the founding Chairman
                of Centre for Business Sustainability and Post Graduate
                Programme (PGP) in Sustainable Management at IIM Lucknow. He has
                worked as Dean (Academic Affairs) at IIM Lucknow and Dean,
                School of Management, and Dean (Academics) at Gautam Buddha
                University. He has been teaching Business Sustainability and
                Externalities Markets, Social Entrepreneurship and Innovations,
                Creative and Nudge Thinking for Transforming Systems, and
                Structural Equation Modeling. His research areas include
                Organizational Inertia and Change, Business Sustainability,
                e-Governance, and Public Private Partnerships.
                <br />
                <br />
                As Sustally Sustainability Guru, he keeps us updated on the
                latest trends and emerging frameworks in the fields of social
                entrepreneurship and sustainability. Through his deep knowledge
                and experience in these domains, he shares his insights and best
                practices with our team. He motivates us to think creatively and
                innovatively, and to apply first principles thinking to solve
                complex problems. He brings different perspectives to question
                our assumptions, thereby helping us to continuously improve our
                product value proposition and find solutions to our client's
                context. He is a supportive and inspiring mentor who helps our
                start-up marching steadily towards our goals and vision.
              </p>
            </div>
          </div>
          <div className="third-container mt-10">
            <div className="md:p-16">
              <h1 className="text-3xl md:text-5xl">Career Highlights</h1>

              <ul
                style={{ paddingLeft: "13px" }}
                className="list-disc border-l-2 pl-4 mt-10 highlight-list"
              >
                <li>
                  <h1 className="text-xl md:text-2xl">
                    Faculty Member, IIM Lucknow
                  </h1>
                  <p className="text-lg md:text-xl">2005-present</p>
                </li>
                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    Dean, School of Management, and Dean, Academics, Gautam
                    Buddha University, Greater Noida (U.P.),
                  </h1>
                  <p className="text-lg md:text-xl">2009</p>
                </li>

                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    Dean, Academic Affairs, IIM Lucknow
                  </h1>
                  <p className="text-lg md:text-xl">2013-2016</p>
                </li>

                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    Ph. D, University of Toronto, Toronto, Canada
                  </h1>
                  <p className="text-lg md:text-xl">1999-2004</p>
                </li>

                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    SSHRC Postdoctoral Fellow, University of Toronto, Toronto,
                    Canada
                  </h1>
                  <p className="text-lg md:text-xl">2004-2005</p>
                </li>

                <li className="mt-5">
                  <h1 className="text-xl md:text-2xl">
                    Indian Forest Service (IFS) officer
                  </h1>
                  <p className="text-lg md:text-xl">1988-2004</p>
                </li>
              </ul>
            </div>
            <div className="second-img">
              <img
                src={Eplise3}
                style={{ marginTop: "-300px", marginLeft: "1200px" }}
                className="hidden lg:block h-70 w-70 img-highlights"
                alt=""
              />
            </div>
          </div>
          <div className="fourth-container mt-10">
            <div className="third-image">
              <img
                src={Group1}
                style={{
                  marginLeft: "-300px",
                }}
                className=" h-72 w-72 hidden md:block absolute mt-16"
                alt=""
              />
            </div>

            <div className="think mt-10">
              <div className="left md:p-16">
                <h1 className=" text-3xl md:text-5xl mb-5">
                  What does Sushil think of Sustally
                </h1>
                <div className="flex h-7">
                  <p className="text-7xl">“</p>
                  <div
                    style={{
                      borderTop: "3px solid",
                      width: "97%",
                      marginTop: "15px",
                      fontFamily: "Verdana, sans-serif",
                    }}
                  ></div>
                </div>
                <p
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                  className="kalam-light text-lg md:text-2xl pl-5 pr-5 pt-5 "
                >
                  I think every business in the future will have to start
                  measuring socio-environmental impact or socio-environmental
                  return metrics beyond financial metrics because they are
                  essential for not only building trust, reputation, and
                  resilience but also for legal compliances, in a rapidly
                  changing world. I believe that Sustally can provide very
                  effective solutions to MSMEs, supply chain partners by helping
                  them optimize their environmental, social, and governance
                  performance and align with global standards and best
                  practices.
                </p>
                <div className="flex h-7">
                  <div
                    style={{
                      borderTop: "3px solid",
                      width: "97%",
                      marginTop: "15px",
                    }}
                  ></div>
                  <p className=" text-7xl">”</p>
                </div>
              </div>
              {/* <div className="right">
              <div className="video-container">
                <iframe
                  className="rounded-lg vdoframe"
                  width="400"
                  height="250"
                  src="https://www.youtube.com/embed/tgbNymZ7vqY"
                ></iframe>
              </div>
            </div> */}

              <div className="forth-image">
                {mode ? (
                  <img
                    src={eplise8}
                    style={{ marginLeft: "1200px", marginTop: "-140px" }}
                    className="hidden lg:block h-72 w-72 img-think"
                    alt=""
                  />
                ) : (
                  <img
                    src={eplise11}
                    style={{ marginLeft: "1200px",marginTop: "-140px" }}
                    className="hidden lg:block h-72 w-72 img-think"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advisor;
