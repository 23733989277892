import React from "react";
import { motion } from "framer-motion";
import downarrow from "../assets/careers_aboutus/downarrow.png";
import starsLight from "../assets/stars_dark.svg";
import starsDark from "../assets/stars_white.svg";
import { Link } from "react-scroll";
import collaboration from "../assets/collaboration.svg";
import handArrowLight from "../assets/careers_aboutus/handArrowLight.svg";
import handArrowDark from "../assets/careers_aboutus/handArrowDark.svg";
import SEO from "../components/SEO";
import rightwhitearrow from '../assets/rightwhitearrow.svg'
function ProductDescription() {
  const tiltVariants = {
    hover: {
      rotateY: 25, // Adjust the degree of rotation as needed
      transition: {
        duration: 0.3,
      },
      scale:1.07
    },
  };
  return (
    <>
      <SEO
        title="Product Description - Sustally"
        description="Sustally enables growing companies to systematically measure their impact and share it with their stakeholders."
        name="product-description"
      />
      <div className=" px-4 md:pt-44 pt-32 font-Inter">
        <div className="flex flex-col items-center justify-center max-w-6xl mx-auto text-center">
          <h1 className="text-xl md:text-3xl ">
            If you're here, it's because you're keen on discovering how our
            product would propel your tale forward.
          </h1>
          <div className="flex flex-col md:flex-row md:gap-10 md:ml-36">
            <div className="flex flex-col max-w-5xl mt-8  gap-14">
              <h2 className="font-Poppins text-3xl md:text-5xl  font-bold">
                Share your journey{" "}
                <span className="text-sustally-violet">your impact </span>and
                your aspirations.
              </h2>
              <h2 className="font-Inter md:text-xl text-lg">
                You have the ability to make a difference, and we're here to
                help you measure and report it.
              </h2>
            </div>
            <img
              src={starsLight}
              alt="starsLight"
              width={""}
              height={""}
              className="md:w-[10%] w-[50%] dark:hidden mx-auto pt-4"
            />
            <img
              src={starsDark}
              alt="starsDark"
              width={""}
              height={""}
              className="md:w-[10%] w-[50%] hidden dark:block mx-auto pt-4"
            />
          </div>
          <Link to="whats-sustally" spy={true} smooth={true} duration={200}>
            <motion.img
              animate={{ y: -20 }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                type: "spring",
                repeatDelay: 1,
                bounce: 0.3,
                mass: 0.8,
              }}
              src={downarrow}
              alt="down arrow"
              className="w-16 mt-20 cursor-pointer"
              width={""} height={""}
            ></motion.img>
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center px-4 mx-auto whats-sustally md:h-screen md:flex-row justify-center mt-32 gap-10 md:gap-0">
        <div className="flex flex-col items-center md:items-start  bg-secondary-light dark:bg-secondary-dark px-6 py-10 rounded-2xl ">
          <h2 className="pb-10 text-3xl font-semibold leading-relaxed font-Inter md:text-5xl">
            What is Sustally?
          </h2>
          <h2 className="text-xl font-Inter md:text-3xl md:max-w-3xl md:text-left">
            Sustally is a Software as a Service (SaaS) product, that enables different
            functions within a company track their impact in quantified and
            globally recognized approach, simplifies this impact - e.g.,{" "}
            <span className="text-sustally-violet">
              Economical, Environmental, Social, Governance{" "}
            </span>{" "}
            information sharing with all interested stakeholders - investors,
            value chain partners, development agencies.
          </h2>

        </div>
        <img
          src={collaboration}
          alt="collaboration"
          width={""}
          height={""}
          className="md:w-[35%] w-[70%]  md:ml-20"
        />
      </div>
      <div className="px-4 mt-32 flex md:flex-row flex-col gap-10 ">
        <div className="flex flex-col py-10 px-8 bg-secondary-light dark:bg-secondary-dark rounded-2xl max-w-3xl ">
          <h2 className="pb-10 text-3xl font-bold font-Poppins md:text-5xl text-sustally-violet">
            Why should you try Sustally?
          </h2>
          <h2 className="text-lg font-Inter md:text-xl ">
            We firmly believe every company irrespective of its stage or size,
            has the power to make a meaningful impact.<br></br> <br></br>Using
            Sustally technological platform, you will be able to systematically
            track your company or portfolio projects' impact and share
            information seamlessly with your stakeholders in timely and credible
            manner. <br></br> <br></br>Ready to harness the potential of
            Sustally and elevate stakeholder trust while amplifying your impact?{" "}
          </h2>
        </div>
        <div className="flex flex-col items-center justify-center text-center md:gap-10 gap-6">
          <h2 className="pb-2 text-3xl font-bold leading-relaxed font-Poppins md:text-5xl text-sustally-violet">
            Not convinced yet?
          </h2>
          <h2 className="text-lg font-Inter md:text-xl ">
            Dive into the product cards based on your impact tracking requirements. 
          </h2>
          <div className="flex mx-auto justify-center">
          <img
            src={handArrowLight}
            alt="hand drown arrow"
            width={""}
            height={""}
            className="w-[20%] rotate-90 dark:hidden block "
          />
          <img
            src={handArrowDark}
            alt="hand drown arrow"
            width={""}
            height={""}
            className="w-[20%]  rotate-90 dark:block hidden "
          />
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col md:gap-32 gap-20 mt-32 justify-center items-center  mx-auto">

        <div className="w-[300px] md:w-[400px] xl:w-[420px]">
          <motion.div 
          style={{transformStyle:"preserve-3d"}}
          whileHover="hover"
          variants={tiltVariants}
          className="bg-secondary-light dark:bg-secondary-dark  h-[500px] relative rounded-3xl">
            <motion.div 
            style={{
              transform: "translateZ(35px)",
              transformStyle:"preserve-3d"}}
            className="bg-tertiary-light dark:bg-tertiary-dark  absolute inset-4 rounded-2xl px-2">
                <p 
                style={{
                  transform: "translateZ(55px) ",
                  transformStyle:"preserve-3d"}} 
                className="px-3 py-2 my-2 mx-2  rounded-xl dark:bg-black bg-white relative w-52">
                  Supply Chain Partners
                </p>
                <motion.h2 
                
                style={{
                  transform: "translateZ(75px)",
                  transformStyle:"preserve-3d"}}
                className="font-Poppins font-bold lg:text-4xl text-2xl pl-3 pt-2 text-sustally-violet"
                >
                Are you a large company looking  to measure impact of your supply chain? 
                </motion.h2>
                
                <motion.div
                style={{ 
                  transform: "translateZ(85px) ",
                  transformStyle: "preserve-3d" }}
                className="m-2 ml-5 absolute bottom-4"
                >
                <motion.a
                href="/product-supply-chain"
                className="bg-sustally-violet hover:bg-custom-purple hover:w-[80%] hover:px-28 duration-200 w-[210px] md:px-16 px-12 py-2 rounded-full flex gap-4 items-center"
                
                >
                <p className="text-xl font-semibold text-white">Yes</p>
                <motion.img 
                animate={{ x: [20,0] }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  type: "spring",
                  repeatDelay: 1,
                  bounce: 0.3,
                  mass:0.8
                }}
                layout="position"
                style={{ y: 0 }}
                src={rightwhitearrow} 
                alt="right arrow icon"
                width={""}
                height={""} className="w-20 h-10"></motion.img>
                </motion.a>
                </motion.div>
                
            </motion.div>
          </motion.div>


          <motion.div 
          style={{transformStyle:"preserve-3d"}}
          whileHover="hover"
          variants={tiltVariants}
          className="bg-secondary-light dark:bg-secondary-dark  h-[500px] relative rounded-3xl mt-24 md:mt-32">
            <motion.div 
            style={{
              transform: "translateZ(35px)",
              transformStyle:"preserve-3d"}}
            className="bg-tertiary-light dark:bg-tertiary-dark  absolute inset-4 rounded-2xl px-2">
                <p 
                style={{
                  transform: "translateZ(55px) ",
                  transformStyle:"preserve-3d"}} 
                className="px-3 py-2 my-2 mx-2 rounded-xl bg-white dark:bg-black relative w-52">
                  Analysts and Agencies
                </p>
                <h2 
                style={{
                  transform: "translateZ(75px)",
                  transformStyle:"preserve-3d"}}
                className="font-Poppins font-bold lg:text-4xl text-2xl pl-3 pt-2 text-sustally-violet"
                >
                Are you a reporting agency working with multiple clients? 
                </h2>

                <motion.div
                style={{ 
                  transform: "translateZ(85px) ",
                  transformStyle: "preserve-3d" }}
                className="m-2 ml-5 absolute bottom-4"
                >
                <motion.a
                href="/product-agencies"
                className="bg-sustally-violet hover:bg-custom-purple hover:w-[80%] hover:px-28 duration-200 w-[210px] md:px-16 px-12 py-2 rounded-full flex gap-4 items-center"
                
                >
                <p className="text-xl font-semibold text-white">Yes</p>
                <motion.img 
                animate={{ x: [20,0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  type: "spring",
                  repeatDelay: 1,
                  bounce: 0.3,
                  mass: 0.8,
                }}
                layout="position"
                style={{ y: 0 }}
                src={rightwhitearrow} 
                alt="right arrow icon"
                width={""}
                height={""} className="w-20 h-10"></motion.img>
                </motion.a>
                </motion.div>
                
            </motion.div>
          </motion.div>

        </div>

        {/* 2nd Col */}
        <div className="w-[300px] md:w-[400px] xl:w-[420px]">
        <motion.div 
          style={{transformStyle:"preserve-3d"}}
          whileHover="hover"
          variants={tiltVariants}
          className="bg-secondary-light dark:bg-secondary-dark  h-[500px] relative rounded-3xl">
            <motion.div 
            style={{
              transform: "translateZ(35px)",
              transformStyle:"preserve-3d"}}
            className="bg-tertiary-light dark:bg-tertiary-dark  absolute inset-4 px-2 rounded-2xl">
                <p 
                style={{
                  transform: "translateZ(55px) ",
                  transformStyle:"preserve-3d"}} 
                className="px-3 py-2 my-2 mx-2 rounded-xl bg-white dark:bg-black relative md:w-72 w-60">
                  Investors & Startups Ecosystem
                </p>
                <h2 
                style={{
                  transform: "translateZ(75px)",
                  transformStyle:"preserve-3d"}}
                className="font-Poppins font-bold lg:text-4xl text-2xl pl-4 pt-2 text-sustally-violet"
                >
                Are you an impact investor or startup ecosystem enabler?
                </h2>
              
                <motion.div
                style={{ 
                  transform: "translateZ(85px) ",
                  transformStyle: "preserve-3d" }}
                className="m-2 ml-5 absolute bottom-4"
                >
                <motion.a
                href="/product-investors"
                className="bg-sustally-violet hover:bg-custom-purple hover:w-[80%] hover:px-28 duration-200 w-[210px] md:px-16 px-12 py-2 rounded-full flex gap-4 items-center"
                
                >
                <p className="text-xl font-semibold text-white">Yes</p>
                <motion.img 
                animate={{ x: [20,0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  type: "spring",
                  repeatDelay: 1,
                  bounce: 0.3,
                  mass: 0.8,
                }}
                layout="position"
                style={{ y: 0 }}
                src={rightwhitearrow} 
                alt="right arrow icon"
                width={""}
                height={""} className="w-20 h-10"></motion.img>
                </motion.a>
                </motion.div>
                
            </motion.div>
          </motion.div>


          <motion.div 
          style={{transformStyle:"preserve-3d"}}
          whileHover="hover"
          variants={tiltVariants}
          className="bg-secondary-light dark:bg-secondary-dark  h-[500px] relative rounded-3xl mt-24 md:mt-32">
            <motion.div 
            style={{
              transform: "translateZ(35px)",
              transformStyle:"preserve-3d"}}
            className="bg-tertiary-light dark:bg-tertiary-dark  absolute inset-4 rounded-2xl px-2">
                <p 
                style={{
                  transform: "translateZ(55px) ",
                  transformStyle:"preserve-3d"}} 
                className="px-3 py-2 mx-2 my-2 rounded-xl bg-white dark:bg-black relative xl:w-64 w-60">
                  NGO or Government Partners
                </p>
                <h2 
                style={{
                  transform: "translateZ(75px)",
                  transformStyle:"preserve-3d"}}
                className="font-Poppins font-bold lg:text-4xl text-2xl pl-4 pt-2 text-sustally-violet"
                >
                Are you an NGO or Government wanting to track your projects impact?
                </h2>
                
                <motion.div
                style={{ 
                  transform: "translateZ(85px) ",
                  transformStyle: "preserve-3d" }}
                className="m-2 ml-5 absolute bottom-4"
                >
                <motion.a
                href="/product-ngo"
                className="bg-sustally-violet hover:bg-custom-purple hover:w-[80%] hover:px-28 duration-200 w-[210px] md:px-16 px-12 py-2 rounded-full flex gap-4 items-center"
                
                >
                <p className="text-xl font-semibold text-white">Yes</p>
                <motion.img 
                animate={{ x: [20,0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  type: "spring",
                  repeatDelay: 1,
                  bounce: 0.3,
                  mass: 0.8,
                }}
                layout="position"
                style={{ y: 0 }}
                src={rightwhitearrow} 
                alt="right arrow icon"
                width={""}
                height={""} className="w-20 h-10"></motion.img>
                </motion.a>
                </motion.div>
                
            </motion.div>
          </motion.div>
        </div>

      </div>
    </>
  );
}

export default ProductDescription;
