import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Airtable from "airtable";
import { useState } from "react";
import { useParams } from "react-router-dom";

function ApplicationDetails() {
  let { id } = useParams();
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");

  useEffect(() => {
    base("posts")
      .select({ view: "Grid view" })
      .eachPage((records, fetchNextPage) => {
        setPosts(records);
        fetchNextPage();
      });
  });
  let postData = posts?.find((post) => post.fields.name === id);
  const reLoad = () => {
    navigate();
  };

  // Function to format requirements as a list
  const formatRequirements = (requirements) => {
    if (!requirements) return null;

    // Split the string by dashes
    const requirementsArray = requirements
      .split("-")
      .filter((item) => item.trim() !== "");

    // Map over the array to create <li> elements
    return (
      <ul className="list-disc list-inside text-lg">
        {" "}
        {/* Added className for custom styling */}
        {requirementsArray.map((requirement, index) => (
          <li className="pb-2" key={index}>
            {requirement.trim()}
          </li>
        ))}
      </ul>
    );
  };

  const formatLearningOutcomes = (Outcomes) => {
    if (!Outcomes) return null;

    // Split the string by dashes
    const outcomesArray = Outcomes.split("-").filter(
      (item) => item.trim() !== ""
    );

    // Map over the array to create <li> elements
    return (
      <ul className="list-disc list-inside text-lg">
        {" "}
        {/* Added className for custom styling */}
        {outcomesArray.map((Outcome, index) => (
          <li className="pb-2" key={index}>
            {Outcome.trim()}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div>
      <div>
        <div className="mt-8" style={{ width: "100%" }}>
          <h1 className="md:text-5xl text-3xl font-semibold text-custom-purple pb-10">
            {postData?.fields.name}
          </h1>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-5 font-Poppins">
              About Company:
            </h2>
            <p className="text-lg">{postData?.fields.aboutCompany}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              Position:
            </h2>
            <p className="text-lg">{postData?.fields.position}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              Work Mode:
            </h2>
            <p className="text-lg">{postData?.fields.workMode}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              Duration:
            </h2>
            <p className="text-lg">{postData?.fields.Duration}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              {postData?.fields.salaryType}:
            </h2>
            <p className="text-lg">{postData?.fields.Stipend}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              Work Hours:
            </h2>
            <p className="text-lg">{postData?.fields.workHour}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              Work Description:
            </h2>
            <p className="text-lg">{postData?.fields.workDec}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              Key Responsibilities:
            </h2>
            <p className="text-lg">{postData?.fields.keyRes}</p>
          </div>
          <div className="py-4">
            <h2 className="md:text-3xl font-2xlfont-semibold: pb-2 font-Poppins">
              Expected Learning Outcomes:
            </h2>
            {formatLearningOutcomes(postData?.fields.Outcomes)}
          </div>
          <div className="py-4">
            <h2 className="text-3xl font-semibold: pb-2">Requirements:</h2>
            {formatRequirements(postData?.fields.Requirments)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationDetails;
