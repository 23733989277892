import React,{useState} from 'react'
import Product from '../assets/careers_aboutus/product.svg'
import People from '../assets/careers_aboutus/people_colour.svg'
import Pride from '../assets/careers_aboutus/pride_color.svg'
import { motion, useMotionValue, useTransform } from 'framer-motion';
function ProductPeoplePride() {
    const [title, setTitle] = useState("Product")
    const handleProduct = () =>{
        setTitle("Product")
    }
    const handlePeople = () =>{
        setTitle("People")
    }
    const handlePride = () =>{
        setTitle("Pride")
    }
    const getParagraph = (title) => {
        switch (title) {
            case "Product":
                return `At the heart of our approach is the belief that a great product must be more than just a solution to a problem, it should be a manifestation of our commitment to excellence. We understand that industry requirements provide a foundation, but we don't stop there. 
                Instead, we take the extra steps to create solutions that truly stand out. We walk the talk by pushing the boundaries of what's possible, constantly innovating to deliver exceptional value to our users and partners.`;
            case "People":
                return "We view every individual, both within our team and in the broader community, as an end in themselves, not merely as a means to an end. Our success isn't measured solely by the bottom line but by the positive impact we have on people's lives. We believe in a non-zero sum game, where success doesn't come at the expense of others. By valuing each person's unique contributions and well-being, we foster an environment where collaboration, empathy, and inclusivity thrive.";
            case "Pride":
                return "In moments of uncertainty, our compass is guided by a simple yet profound principle: choose the path that allows us to walk with pride. This pride isn't rooted in boasting but in our commitment to making the world a better place. We recognize our responsibility to lead by example, to protect our planet, and to uplift the lives of people everywhere. With unwavering determination, we embark on a journey towards a brighter, more sustainable future, leaving a positive legacy for generations to come.";
            default:
                return "";
        }
    };
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const handleMouseMove = (x, y, setX, setY)=>(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const width = rect.width;
        const height = rect.height;
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        const xPct = mouseX / width - 0.5;
        const yPct = mouseY / height - 0.5;

        x.set(xPct);
        y.set(yPct);
    };

    const handleMouseLeave = (x, y) => () => {
        x.set(0);
        y.set(0);
    };
    const Card = ({title,paragraph}) => {
        const x = useMotionValue(0);
        const y = useMotionValue(0);

        const rotateX = useTransform(
            y,
            [-0.5, 0.5],
            ["17.5deg", "-17.5deg"]
        );
        const rotateY = useTransform(
            x,
            [-0.5, 0.5],
            ["-17.5deg", "17.5deg"]
        );

        return (
            <motion.div
                onMouseMove={handleMouseMove(x, y, x.set, y.set)}
                onMouseLeave={handleMouseLeave(x, y)}
                style={{
                    rotateY,
                    rotateX,
                    transformStyle: "preserve-3d",
                }}
                className='w-[95%] h-auto md:w-[50%] dark:bg-secondary-dark bg-secondary-light rounded-2xl duration-150'
            >
                <h2
                style={{
                    transform: "translateZ(75px)",
                    transformStyle: "preserve-3d",
                }}
                className='md:text-5xl text-3xl font-Poppins font-semibold text-sustally-violet md:px-8 px-8 py-4'>{title}</h2>
                
                <p 
                style={{
                    transform: "translateZ(75px)",
                    transformStyle: "preserve-3d",
                }}
                className="md:px-8 py-4 my-4 px-4 mx-4 md:text-2xl text-xl dark:bg-tertiary-dark bg-tertiary-light  rounded-2xl md:text-justify">{paragraph}</p>
                
                
            </motion.div>
        );
    };
    return (
        <div className='flex flex-col md:flex-row gap-20 justify-center items-center mx-auto '>
            <div className='flex flex-row md:flex-col gap-4 md:gap-10'>
                <img  onClick={handleProduct} src={Product} className={`${title === "Product" ? 'shadow-md shadow-sustally-violet' : ''} w-24 md:w-32 dark:bg-secondary-dark bg-secondary-light  px-4 py-4 rounded-full hover:cursor-pointer`} alt='product image' width={""} height={""}></img>
                <img  onClick={handlePeople} src={People} className={`${title === "People" ? 'shadow-md shadow-sustally-violet' : ''} w-24 md:w-32 dark:bg-secondary-dark  bg-secondary-light px-4 py-4 rounded-full hover:cursor-pointer`} alt='people image' width={""} height={""}></img>
                <img onClick={handlePride} src={Pride} className={`${title === "Pride" ? 'shadow-md shadow-sustally-violet' : ''} w-24 md:w-32 dark:bg-secondary-dark bg-secondary-light px-4 py-4 rounded-full hover:cursor-pointer`} alt='pride image' width={""} height={""}></img>
            </div>
            <Card title={title} paragraph={getParagraph(title)} x={x} y={y}/>
        </div>
    )
}

export default ProductPeoplePride