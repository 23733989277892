import React from "react";
import SEO from "../components/SEO";
import LogoB from '../assets/Logomark Black.svg'
import LogoW from '../assets/Logomark white.svg'
import LogoBb from '../assets/logoblack.png'
import LogoWw from '../assets/logowhite.png'
import TypemarkWhite from '../assets/typemarkWhite.svg'
import TypemarkBlack from '../assets/typemarkBlack.svg'

function MediaKit() {
  return (
    <div className="xl:mx-80 mt-32 mx-10">
      <SEO
        title="Media Kit - Sustally"
        description="Explore our Media Kit page to access our brand resources conveniently. simplify your utilization of our brand components, including our logo, company description, whenever you reference Sustally in your content."
        name="mediakit"
      />
      <div>
        <p className="text-5xl font-bold font-Poppins text-sustally-violet">Media Kit</p>
        <br></br>
        <h3 className="text-xl text-Inter">Hello!</h3>
        <p className="text-xl">
          <br></br>
          We provide you with the flexibility to access our brand resources
          conveniently. You can opt to choose from our readily available logo
          files in this Sustally Media Kit. These assets are intended to
          simplify your utilization of our brand components, including our logo,
          company description, whenever you reference Sustally in your content,
          without the necessity of exchanging mails for each usage.
        </p>
        <br></br>
        <p className="text-xl">media@sustally.com</p>
        <br></br>
        <br></br>
        <h2 className="text-4xl font-bold font-Poppins">Short Description</h2>
        <br></br>
        <p className="text-xl">
          "Sustally SaaS platform simplifies sustainability tracking, empowering
          startups and MSMEs to integrate ESG seamlessly from the start."
        </p>
        <br></br>
        <br></br>
        <h2 className="text-4xl font-bold font-Poppins">Long Description</h2>
        <br></br>
        <p className="text-xl">
          "Sustally, niche B2B SaaS company, simplifies sustainability tracking
          for startups and MSMEs. Our platform allows systematic measurement and
          management of your business' economic, environmental, social, and
          governance (EESG) impacts. We empower startups and MSMEs to integrate
          sustainability from the start, showcasing a commitment to responsible
          business practices and growth, building trust with all stakeholders.
          <br></br>
          <br></br>
          Our fundamental principle is, 'What gets measured not only gets
          improved, but also gets valued and rewarded.' By choosing Sustally,
          firms irrespective of their stage or size, embrace a journey towards a
          better and more sustainable future. <br></br>
          <br></br>
          Join us in redefining business standards, making a positive impact on
          your value chain, business ecosystem and our world."
        </p>
        <br></br>
        <br></br>
        <h2 className="text-4xl font-bold font-Poppins">Logo</h2>
        <div className="mt-12 gap-4">

          <div className="flex justify-between items-center my-4 flex-col gap-4 md:flex-row">
            <img className="bg-secondary-light h-28 rounded-lg" src={LogoB} alt="Logo white" width={""} height={""}/>
            <div className="flex justify-end gap-6 md:gap-10">
            <a
              aria-label="Download Logo"
              href={LogoB}
              download="Logo-black-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download SVG" name="download svg" className="md:px-5 md:py-3 px-4 py-2 bg-sustally-violet text-white rounded-full font-Inter text-lg hover:scale-105 duration-200">Download svg</button>
            </a>
            <a
              aria-label="Download Logo"
              href={LogoBb}
              download="Logo-black-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download PNG" name="download png" className="md:px-5 md:py-3 px-4 py-2 bg-sustally-violet text-white rounded-full font-Inter text-lg hover:scale-105 duration-200">Download png</button>
            </a>
            </div>
          </div>
          <div className="flex my-4 justify-between items-center flex-col gap-4 md:flex-row">
            <img className="h-28 bg-secondary-dark dark:bg-transparent rounded-lg" src={LogoW} alt="Logo Black" width={""} height={""}/>
            <div className="flex justify-end gap-6 md:gap-10">
            <a
              aria-label="Download Logo"
              href={LogoW}
              download="Logo-black-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download SVG" name="download svg" className="md:px-5 md:py-3 px-4 py-2 text-lg bg-sustally-violet text-white rounded-full font-Inter hover:scale-105 duration-200">Download svg</button>
            </a>
            <a
              aria-label="Download Logo"
              href={LogoWw}
              download="Logo-black-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download PNG" name="download png" className="md:px-5 md:py-3 px-4 py-2  text-lg bg-sustally-violet text-white rounded-full font-Inter hover:scale-105 duration-200">Download png</button>
            </a>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <h2 className="text-4xl font-bold font-Poppins">Typemark</h2>
        <div className="mt-12 gap-4">

          <div className="flex justify-between items-center my-4 flex-col gap-4 md:flex-row">
            <img className="bg-secondary-light h-28 rounded-lg" src={TypemarkBlack} alt="Logo white" width={""} height={""}/>
            <div className="flex justify-end gap-6 md:gap-10">
            <a
              aria-label="Download Logo"
              href={TypemarkBlack}
              download="typemark-white-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download SVG" name="download svg" className="md:px-5 md:py-3 px-4 py-2 bg-sustally-violet text-white rounded-full font-Inter text-lg hover:scale-105 duration-200">Download svg</button>
            </a>
            <a
              aria-label="Download Logo"
              href={TypemarkBlack}
              download="typemark-black-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download PNG" name="download png" className="md:px-5 md:py-3 px-4 py-2 bg-sustally-violet text-white rounded-full font-Inter text-lg hover:scale-105 duration-200">Download png</button>
            </a>
            </div>
          </div>
          <div className="flex my-4 justify-between items-center flex-col gap-4 md:flex-row">
            <img className="h-28 bg-secondary-dark dark:bg-transparent rounded-lg" src={TypemarkWhite} alt="Logo Black" width={""} height={""}/>
            <div className="flex justify-end gap-6 md:gap-10">
            <a
              aria-label="Download Logo"
              href={TypemarkWhite}
              download="Logo-black-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download SVG" name="download svg" className="md:px-5 md:py-3 px-4 py-2 text-lg bg-sustally-violet text-white rounded-full font-Inter hover:scale-105 duration-200">Download svg</button>
            </a>
            <a
              aria-label="Download Logo"
              href={TypemarkWhite}
              download="Logo-black-svg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button aria-label="Download PNG" name="download png" className="md:px-5 md:py-3 px-4 py-2  text-lg bg-sustally-violet text-white rounded-full font-Inter hover:scale-105 duration-200">Download png</button>
            </a>
            </div>
          </div>
        </div>
        <h2 className="text-4xl font-semibold font-Inter mt-10">Registered Address</h2>
        <br></br>
        <br></br>
        <p className="text-xl">824, LGF, Arjun Nagar, K. M. Pur, New Delhi - 110003</p>
        <br></br>
        <br></br>
        <h2 className="text-4xl font-semibold font-Inter">Contact</h2>
        <br></br>
        <br></br>
        <p className="text-xl">media@sustally.com</p>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default MediaKit;
