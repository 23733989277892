import React, { useEffect, useState } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link, useNavigate } from 'react-router-dom';
import  Divider  from "../components/Divider";
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from "framer-motion";

const BlogContactus = ({isDarkMode}) => {
      
    const [isOpen, setIsOpen] = useState(false);
    
    const openModal = () => {
        setIsOpen(true);
    };
    
    const closeModal = () => {
        setIsOpen(false);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        openModal();
        // Perform your form submission logic here
    };

    const [buttonPressed, setIsButtonPressed] = useState(false);
    const handleButtonPress = () =>{
        setIsButtonPressed(true)
    }
    const handleContactClose = () =>{
        setIsButtonPressed(false)
    }

    return (
        <>
        <div className=' flex mx-auto justify-center items-center '>
            {!buttonPressed && (<button aria-label="Contact Us" name="contact us" onClick={handleButtonPress} id='footer-contact' className=" hover:bg-sustally-violet  dark:text-white font-semibold hover:text-white py-8 px-10 border-4 border-custom-grey dark:border-custom-dark  hover:border-transparent rounded-t-full">Contact us</button>)}
            {buttonPressed && 
            <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className='blog-contact ease-in w-[96%] h-[60%] md:h-[65%] md:w-[65%]  rounded-lg lg:rounded-t-full bg-custom-grey dark:bg-custom-dark z-50 '>
                <div className='relative'>
                    <CloseIcon
                    onClick={handleContactClose} // Add this line to close the form
                    className='absolute top-2 right-1/2 text-sustally-violet cursor-pointer z-50'
                    style={{ fontSize: '24px' }}
                    />
                    <div className='flex mx-auto justify-center items-center px-4 pt-10 md:pt-20 md:pl-20'>
                        <form  className='relative my-12' name="submit-to-google-sheet" id="blog-contact-form" 
                        onSubmit={async (e) => {
                            e.preventDefault(); // Prevent the default form submission
                            const scriptURL = 'https://script.google.com/macros/s/AKfycbxczOnNOfsDea4DBme1GX11x7rTkE5tJl7-nKe8y40Y-GhD99aoKhu3KNung-kl4SMF/exec';
                            const form = document.forms['submit-to-google-sheet'];
        
                            try {
                                const response = await fetch(scriptURL, { method: 'POST', body: new FormData(form) });
        
                                if (response.ok) {
                                    form.reset();
                                    handleContactClose(); // Close the modal if needed
                                } else {
                                    console.error('Error in form submission:', response.statusText);
                                }
                            } catch (error) {
                                console.error('Error in form submission:', error.message);
                            }
                            }}
                        > 

                            {/* <Modal
                            shouldCloseOnOverlayClick={false}
                            isOpen={isOpen}
                            onRequestClose={closeModal}
                            contentLabel="Modal"
                            overlayClassName="fixed inset-0 bg-black bg-opacity-70"
                            className="overflow-hidden z-50 w-[85%] h-[25%] top-[30%] left-[8%] md:w-[45%] md:top-[35%] md:left-[28%] absolute  p-10 text-custom-dark bg-custom-grey font-Inter rounded-lg"
                            >
                            <div className='flex justify-between'>
                            <h2 className='font-semibold pb-4 text-3xl'>Form Submitted</h2>
                            <CloseIcon onClick={closeModal} className='absolute right-3 top-6 text-sustally-violet z-100 cursor-pointer' style={{ fontSize: '30px' }} />
                            </div>
                            <p className='text-xl'>Thanks for reaching out to us. We will get in touch with you shortly.</p>

                            </Modal> */}

                            <h2 className='  py-2 text-xl'>My name is  <span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Name" placeholder="Tony Stark" aria-label="Full name"></input></span></h2>

                            {/* <div className='contact-footer font-semibold py-1  text-xl md:text-3xl max-w-7xl'> */}
                            {/* <h2 className='text-lg font-semibold py-1 sm:text-2xl'>I am <span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Position" placeholder="CEO" aria-label="Full name"></input></span>, p</h2> */}
                            <h2 className='  py-2 text-xl'>I am <span className='border-b border-custom-purple py-1'>
                            <select name="Position" id="small" className=" text-md  text-gray-600  border appearance-none bg-transparent border-none py-1 px-2 leading-tight focus:outline-non">
                            <option  className='bg-gray-800 text-white'>CXO</option>
                            <option  className='bg-gray-800 text-white'>Founder</option>
                            <option  className='bg-gray-800 text-white'>Functional Lead</option>
                            <option  className='bg-gray-800 text-white'>Sustainability Team</option>
                            <option  className='bg-gray-800 text-white'>HR Team</option>
                            <option  className='bg-gray-800 text-white'>Operations Lead</option>
                            <option   className='bg-gray-800 text-white'>General Admin Team</option>
                            <option  className='bg-gray-800 text-white'>Team Member</option>
                            </select>
                            </span>
                            </h2>
                            <h2 className='py-2 text-xl'>part of<span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Company" placeholder="Stark Industries" aria-label="Full name"></input></span>team</h2>
                            {/* </div> */}
                            <h2 className=' py-2 text-xl'>I am interested in  <span className='border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" name="Purpose" type="text" placeholder="interacting with your team" aria-label="Full name"></input></span></h2>
                            <h2 className=' py-2 text-xl'>I'd like to meet with your team, you can contact me at </h2>
                            <div className='contact-footer py-2 text-xl'><span className='border-b border-custom-purple font-semibold py-1 text-xl'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Email" placeholder="tony@stark.com" aria-label="Full name"></input></span><h2 className='text-xl ml-1 mr-1 py-1'>Or</h2><span className=' text-xl border-b border-custom-purple py-1'><input required class="appearance-none bg-transparent border-none text-white-700 py-1 px-2 leading-tight focus:outline-non" type="text" name="Phone" placeholder="+91 12345 67890" aria-label="Full name"></input></span></div>
                            {/* <Button/> */}
                            <button aria-label="Submit Form" name="submit" type="submit"  className="btn rounded-full bg-sustally-violet font-Inter px-4 py-2 mt-4 hover:scale-105 duration-200 text-white"><span>Submit</span></button>

                        </form>
                    </div>
                </div>
            </motion.div>
            }
        </div>
    
        </>
    )
}

export default BlogContactus