import React, { useEffect } from "react";
import { PopupButton } from "react-calendly";

const BookADemo = () => {
  useEffect(() => {
    // Load the Calendly script dynamically
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="relative">
      {/* Provide an accessible name for the PopupButton using aria-label */}
      <PopupButton
        aria-label="Book a demo button"
        role="button"
        name="book a demo"
        url="https://calendly.com/sustally_team/product_demo"
        rootElement={document.getElementById("root")}
      />

      {/* Provide an accessible name for the span using aria-label */}
      <span
        role="button"
        aria-label="Open Calendly popup"
        style={{
          position: "absolute",
          color: "#8e4dff",
          textAlign: "center",
          alignSelf: "center",
        }}
        className="material-symbols-outlined"
        onClick={() => {
          if (window.Calendly) {
            window.Calendly.initPopupWidget({
              url: "https://calendly.com/sustally_team/product_demo",
            });
          }
        }}
      >
        calendar_add_on
      </span>
    </div>
  );
};

export default BookADemo;
