import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import Webinar from "../common/styles/EmployeeAssets/Webinar-amico.png";
import Computer from "../common/styles/EmployeeAssets/computer.png";
import "../common/styles/EmployeeDashBoard.css";

import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../config/firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";

import googleLogo from "../assets/googleLogo.png";

const Employeessignin = () => {
  const navigate = useNavigate();
  const [backgroundImage, setBackgroundImage] = useState(Webinar);
  const [userdata, setUserData] = useState({});

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user.email.endsWith("@sustally.com")) {
        navigate("/employeepage");
      } else {
        console.error("Invalid email domain. Only @sustally.com is allowed.");
        await auth.signOut();
      }
    } catch (error) {
      console.error("Error during sign-in with Google:", error.message);
    }
  };
  const [user] = useAuthState(auth);
  const signUserOut = async () => {
    await signOut(auth);
  };
  const ceo = [];

  return (
    <div>
      {/*
      <GoogleOAuthProvider clientId="856691360459-lr8psrdlaf4visj9q92j7kdmg8ktc43r.apps.googleusercontent.com">
        <div>
          <div id="main-box">
            <div className="sigin-pic">
              <img className="loginpage-image" src={backgroundImage} alt="Webinar" />
            </div>
            <div className="signpage">
              <div id="mainbox">
                <h2 className="logintext">Sustally Team Sign-In</h2>

                <div id="signinDiv">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      const credenticalresponseDecoded = jwtDecode(
                        credentialResponse.credential
                      );
                      console.log(credenticalresponseDecoded);
                      setUserData(credenticalresponseDecoded);
                      if (
                        credenticalresponseDecoded?.email.includes("@sustally.com")
                      ) {
                        navigate("/employeepage", { state: { email: credenticalresponseDecoded.email } });
                      } else {
                        setBackgroundImage(Computer);
                      }
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
                <p>only sustally mail account accepted</p>
              </div>
            </div>
          </div>
        </div>
                  </GoogleOAuthProvider>*/}
      {/* <div>
      <div id="signin-box grid grid-cols-2">
            <div className="sigin-pic">
              <img className="loginpage-image" src={backgroundImage} alt="Webinar" />
            </div>
            <div className="signpage">
              <div id="mainbox">
                <h2 className="logintext">Sustally Team Sign-In</h2>

                <div id="signinDiv">
                  <button className="bg-slate-300 border-2 text-black p-2" onClick={signInWithGoogle}>Sign in with google</button>
                </div>
                <p>only sustally mail account accepted</p>
              </div>
            </div>
          </div>
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 mt-28 rounded-2xl w-5/6 md:w-2/3 p-5 md:p-20 ml-auto mr-auto bg-secondary-light dark:bg-secondary-dark  ">
        <div className="">
          <img
            className="loginpage-image h-64 w-64 ml-auto mr-auto"
            src={backgroundImage}
            alt="Webinar"
          />
        </div>
        <div className="">
              <div className="md:mt-12" id="text-center">
                <h2 className="text-center md:text-3xl text-lg font-bold mb-2 font-Poppins">Sustally Team Sign-In</h2>

                <div className="text-center flex border-2 md:w-52 ml-auto mr-auto mb-2" id="">
                  <button className= "p-2 ml-8 md:ml-0" onClick={signInWithGoogle}>Sign in with google</button>
                  <img
                    src={googleLogo}
                    className=" h-9 w-9"
                    alt=""
                  />
                </div>
                <p className="text-center text-sustally-violet">only sustally mail account accepted</p>
              </div>
            </div>
      </div>
    </div>
  );
};

export default Employeessignin;
