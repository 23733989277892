import React from "react";
// import TeamCard1 from "../components/TeamCard1";
// import TeamCard2 from "../components/TeamCard2";
// import TeamCard3 from "../components/TeamCard3";
// import TeamCard4 from "../components/TeamCard4";
// import sidearrow from "../assets/careers_aboutus/downarrow.png";
// import TeamCard5 from "../components/TeamCard5";
// import TeamCard6 from "../components/TeamCard6";
import sidearrow from "../assets/careers_aboutus/downarrow.png";
import OurTeam from "../components/OurTeam";
import Alumni from "../components/Alumni";
import { useState, useEffect, useRef } from "react";
import "../common/styles/teamwall.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import shreyImg from "../common/styles/AlumniAssets/shrey.jpg";
import nandiniImg from "../common/styles/AlumniAssets/Nandini1.jpg";
import priyaiImg from "../common/styles/AlumniAssets/Priya1.jpg";
import MadhaviImg from "../common/styles/AlumniAssets/Madhavi.jpg";
import NandanaImg from "../common/styles/AlumniAssets/NandanaImg.png";
import sudipImg from "../common/styles/AlumniAssets/sudipImg.png";
function Teamwall() {
  const [reloadCounter, setReloadCounter] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      // Increment the reload counter to trigger a re-render
      setReloadCounter((prevCounter) => prevCounter + 1);
    }, 4000); // 5000 milliseconds = 5 seconds

    return () => {
      // Clear the timer when the component unmounts to prevent memory leaks
      clearInterval(timer);
    };
  }, []);
  const alumniData = [
    {
      name: "Priyadarshini C",
      decription:
        "Working with the passionate founder, i've had the privilege of contributing to an amazing product which has significantly increased my learning curve.  The transparent approach to understanding the project and concept has made it feel more like a fulfilling experience than a job. I'm proud that I've been a part of Sustally, and I'm excited about its future plan",
      linkedin: "https://www.linkedin.com/in/priyadharshinic/",
      image: priyaiImg,
      duration: "February 2023 - June 2023",
    },
    {
      name: "Nandhini Venkat",
      decription: (
        <div>
          <br />
          <p
            style={{ textAlign: "justify", textJustify: "inter-word" }}
            class="text-xl md:text-2xl kalam-light"
          >
            Sustally is a community of encouragement and growth. From day one,
            they welcomed me with open arms and provided the support I needed to
            excel in my role. They nurture talents and inspire us to reach our
            full potential. It's been an incredible journey with them.
          </p>
          <br />
        </div>
      ),
      linkedin: "https://www.linkedin.com/in/nandhini-venkat-829618242/",
      image: nandiniImg,
      duration: "June 2023 - August 2023",
    },
    {
      name: "Shrey Aswal",
      decription:
        "During my 6 months at Sustally, I actively contributed to projects like Notifier and OCR Tool, navigating challenges with support from the team. The work was challenging, but team's culture, allowing me room for mistakes and improvement, made it a valuable and growth-oriented experience. Learning the importance of communication in a project setting was a key takeaway.",

      linkedin: "https://www.linkedin.com/in/shreyaswal/",
      image: shreyImg,
      duration: "June 2023 - November 2023",
    },
    {
      name: "Madhavi Latha",
      decription: (
        <div>
          <br />
          <p
            style={{ textAlign: "justify", textJustify: "inter-word" }}
            class="text-xl md:text-2xl kalam-light"
          >
            I am truly grateful for the invaluable experience I gained during my
            time at sustally. Being part of this company has been filled with
            opportunities for learning and skill development. The company's
            commitment to fostering a culture of continuous learning has
            provided me with an excellent platform to enhance and showcase my
            skills.
          </p>
        </div>
      ),

      linkedin: "https://www.linkedin.com/in/reddivari-nandana-9b68aa251/",
      image: MadhaviImg,
      duration: "November 2023 - February 2023",
    },
    {
      name: "Nandana Reddivari",
      decription:
        "I wanted to take a moment to express my gratitude for the time I spent with the company and for the opportunities it provided me to grow both professionally and personally. The lessons I learned, the relationships I built, and the experiences I had during my time at Sustally have been invaluable. I often reflect on how much I’ve grown since then, and I’m proud to be part of Sustally.",

      linkedin: "https://www.linkedin.com/in/reddivari-nandana-9b68aa251/",
      image: NandanaImg,
      duration: "November 2023 - August 2024",
    },
    {
      name: "Sudip Bhanja",
      decription:
        "During my time at Sustally, I had the opportunity to work with a highly collaborative and innovative team. The daily sprints kept us focused and aligned, while the weekly Tech Learning Forum sessions encouraged us to share technical knowledge, making every challenge a valuable learning experience. The company’s dedication to sustainability, through the bi-weekly sessions led by Anitha Chinnasamy, expanded my understanding of how technology can contribute to a sustainable future.",

      linkedin: "https://www.linkedin.com/in/shreyaswal/",
      image: sudipImg,
      duration: "November 2023– June 2024",
    },
  ];
  const data = [
    {
      name: "Anitha",
      role: "CEO",
      id: "anitha",
      decription:
        "In the present era (2022-) at Sustally, I am juggling roles of researcher to marketeer to brand ambassador. I break my own shell, consistently iterating deliverables while staying as topic agnostic curious learner.",
      animateId: "a",
      dummy: "dummyRect1",
      bounce: ["bounce6", "bounce7", "bounce8", "bounce9", "bounce10"],
      linkedin: "https://www.linkedin.com/in/anithachinnasamy/",
    },
    {
      name: "Akshay",
      role: "Full Stack Developer",
      id: "akshay",
      decription:
        "Full Stack Software Developer passionate about coding, with expertise in JavaScript, React, and Node.js. Loves playing cricket, exploring mountain trips, and cooking delicious meals. Combining creativity and technical skills to build innovative solutions.",
      animateId: "a",
      dummy: "dummyRect2",
      bounce: ["bounce6", "bounce7", "bounce8", "bounce9", "bounce10"],
      linkedin: "https://www.linkedin.com/in/akshay-gupta1397/",
    },
    {
      name: "Aman",
      role: "Backend Developer",
      id: "aman",
      decription:
        "I am a dedicated software developer known for my innovative solutions.I find joy in reading newspapers to stay informed and exploring new cultures through travel. These pursuits enrich my personal life and creativity that I bring to work every day.",
      animateId: "a",
      dummy: "dummyRect3",
      bounce: ["bounce6", "bounce7", "bounce8", "bounce9", "bounce10"],
      linkedin: "https://www.linkedin.com/in/aman-gupta-518a75259/",
    },
    {
      name: "Ashwin",
      role: "Full Stack Developer",
      id: "ashwin",
      decription:
        "Developer by the day and a gamer plus weeb by night. Always up to play basketball on weekends. I won’t be the first one to initiate the conversation but if its about any of the above things then we will be talking for hours. ",
      animateId: "p",
      dummy: "dummyRect4",

      bounce: ["bounce1", "bounce2", "bounce3", "bounce4", "bounce5"],
      linkedin: "https://www.linkedin.com/in/venkata-sai-ashwin/",
    },
    // {
    //   name: "Dinesh",
    //   role: "Intern",
    //   id: "dinesh",
    //   decription:
    //     "I'm a highly dedicated full-stack web developer with a deep love for gaming and anime. When I'm not coding, you'll find me at the gym, staying active and healthy.",
    //   animateId: "e",
    //   dummy: "dummyRect",

    //   bounce: ["bounce16,", "bounce17", "bounce18", "bounce19", "bounce20"],
    //   linkedin: "https://www.linkedin.com/in/dinesh-babu-ch-4b9902136/",
    // },
    {
      name: "Krishnan",
      role: "Community Evangelist",
      id: "krishnan",
      decription:
        "I am in for all things Sustainability. I enjoy communicating Sustally's story to the bigger world. Through our Social channels, I make it easy for entrepreneurs and business leader to decode the complex world of Business Sustainability.",
      animateId: "j",
      dummy: "dummyRect5",

      bounce: ["bounce41,", "bounce42", "bounce43", "bounce44", "bounce45"],
      linkedin: "https://www.linkedin.com/in/nasukrishnan/",
    },
    // {
    //   name: "Nandana",
    //   role: "Developer Trainee",
    //   id: "nandana",
    //   decription:
    //     "I really enjoy coding and am eager to learn new things. I like to visit places with waterfalls and hills. I also like to express my emotions to my pillow.",
    //   animateId: "g",
    //   dummy: "dummyRect6",
    //   bounce: ["bounce31", "bounce32", "bounce33", "bounce34", "bounce35"],

    //   linkedin: "https://www.linkedin.com/in/reddivari-nandana-9b68aa251/",
    // },
    {
      name: "Rahul",
      role: "Frontend Developer",
      id: "rahul",
      decription:
        "While my heart is dedicated to coding, I venture into realms beyond. Crafting code, relishing cinema, dancing to melodies, sketching thoughts, and staying fit through workouts, I embrace a rich tapestry of interests alongside my coding journey.",
      animateId: "h",
      dummy: "dummyRect7",
      bounce: ["bounce11,", "bounce12", "bounce13", "bounce14", "bounce15"],

      linkedin: "https://www.linkedin.com/in/rahul-compani-713a12210/",
    },

    // {
    //   name: "Sudip",
    //   role: "Intern",
    //   id: "sudip",
    //   decription:
    //     "I am an enthusiastic and dedicated software developer. With a strong passion for coding and a keen desire to learn. Outside the realm of coding, my joys lie in the cricket field, where I find the thrill of competition and teamwork.",
    //   animateId: "c",
    //   dummy: "dummyRect8",

    //   bounce: ["bounce21,", "bounce22", "bounce23", "bounce24", "bounce25"],
    //   linkedin: "https://www.linkedin.com/in/sudip-bhanja-744935257",
    // },

    {
      name: "",
      role: "",
      id: "somu",
      decription: "",
      animateId: "f",
      dummy: "dummyRect9",
      bounce: ["bounce26,", "bounce27", "bounce28", "bounce29", "bounce30"],

      linkedin: "",
    },

    // {
    //   name: "",
    //   role: "",
    //   id: "somu",
    //   decription: "",
    //   animateId: "i",
    //   dummy: "dummyRect7",

    //   bounce: ["bounce36,", "bounce37", "bounce38", "bounce39", "bounce40"],
    //   linkedin: "",
    // },
  ];

  const [isRotated, setRotated] = useState(false);
  const teamWallRef = useRef(null);
  const handleButtonClick = () => {
    const isSmallScreen = window.innerWidth < 768;

    // Perform actions only on small screens
    if (isSmallScreen) {
      // window.scrollTo({ top: 700, behavior: "smooth" });

      window.scrollTo({ top: 700, behavior: "smooth" });
    }
    setRotated(!isRotated);
  };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           // Entry is in view, trigger rotation with a delay
  //           setTimeout(() => {
  //             setRotated(true);

  //             // Reset rotation after a short delay (adjust as needed)
  //             setTimeout(() => {
  //               setRotated(false);
  //             }, 1500); // 1000 milliseconds = 1 second
  //           }, 700); // 500 milliseconds = 0.5 seconds
  //         }
  //       });
  //     },
  //     { threshold: 0.25 } // Trigger when 25% of the component is visible
  //   );

  //   if (teamWallRef.current) {
  //     observer.observe(teamWallRef.current);
  //   }

  //   return () => {
  //     if (teamWallRef.current) {
  //       observer.unobserve(teamWallRef.current);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //     const observer = new IntersectionObserver(
  //       (entries) => {
  //         entries.forEach((entry) => {
  //           if (entry.isIntersecting) {
  //             // Entry is in view, trigger rotation
  //             setRotated(true);

  //             // Reset rotation after a short delay (adjust as needed)
  //             setTimeout(() => {
  //               setRotated(false);
  //             }, 1000); // 1000 milliseconds = 1 second
  //           }
  //         });
  //       },
  //       { threshold: 0.25 } // Trigger when 25% of the component is visible
  //     );

  //     if (teamWallRef.current) {
  //       observer.observe(teamWallRef.current);
  //     }

  //     return () => {
  //       if (teamWallRef.current) {
  //         observer.unobserve(teamWallRef.current);
  //       }
  //     };
  //   }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const screenWidth = window.innerWidth;

            // Apply rotation only if the screen width is greater than 768px
            if (screenWidth > 768) {
              // Entry is in view, trigger rotation with a delay
              setTimeout(() => {
                setRotated(true);

                // Reset rotation after a short delay (adjust as needed)
                setTimeout(() => {
                  setRotated(false);
                }, 1500); // 1000 milliseconds = 1.5 seconds
              }, 700); // 500 milliseconds = 0.7 seconds
            }
          }
        });
      },
      { threshold: 0.25 } // Trigger when 25% of the component is visible
    );

    if (teamWallRef.current) {
      observer.observe(teamWallRef.current);
    }

    return () => {
      if (teamWallRef.current) {
        observer.unobserve(teamWallRef.current);
      }
    };
  }, []);
  const isEven = data.length % 2 === 0;

  const chkEven = alumniData.length % 2 === 0;

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var AlumniPcSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  // Split the data into chunks of 4 for 2 rows x 2 columns per "slide"
  const chunkedAlumniData = [];
  for (let i = 0; i < alumniData.length; i += 2) {
    chunkedAlumniData.push(alumniData.slice(i, i + 2));
  }
  return (
    //     <div className='min-h-screen  md:mt-20 mt-24'>
    //     {/* <h2 className='text-sustally-violet text-3xl md:text-5xl font-semibold text-center md:text-right mx-0 md:mx-10 font-roboto'>Team Wall<br></br>
    // <span className='text-lg  md:text-xl px-0 md:px-6 font-Roboto'>Would you like to work with this amazing set of people. Join Us <a href='/career'><img src={sidearrow} alt='side arrow' className="w-[10%] md:w-[3%] absolute right-[15%] md:right-[8%] top-[19.8%] md:top-[21%] -rotate-90 hover:scale-105 "></img></a></span></h2> */}
    //       <h1 className='text-sustally-violet text-3xl md:text-5xl font-semibold md:text-right text-left font-Roboto mx-4 z-100'>Team Wall</h1>
    //       <h2 className='text-xl text-left md:text-right text-Roboto font-semibold mx-4 mt-2 mb-4 md:mb-0 z-100'>Would you like to work with this amazing set of people. <a href='/career' className='text-2xl md:text-xl'>Join Us </a> <a href='/career'><img src={sidearrow} alt='side arrow' className="hidden lg:block lg:w-[3%] absolute right-[15%] md:right-[4%] top-[23%] md:top-[19%] -rotate-90 hover:scale-105 " width={""} height={""}></img></a></h2>
    //       <div className='flex flex-col md:flex-row gap-5 mx-20 md:mx-0 '>

    //         <TeamCard1 />
    //         <TeamCard2 />
    //         <TeamCard3 />
    //         <TeamCard4 />
    //         <TeamCard5 />
    //         {/* <TeamCard6 /> */}
    //       </div>

    //     </div>
    // <div>

    <div ref={teamWallRef}>
      <div className={`parent mt-20 ${isRotated ? "rotate" : ""}`} id="in">
        <div className={`child ${isRotated ? "rotate h-back" : ""}`} id="in">
          <div className="front side">
            <div className="content md:-ml-8">
              {/* <h1 className="text-sustally-violet text-3xl md:text-3xl font-semibold md:text-right text-center font-Roboto mx-4 z-100 mt-20 md:mt-0">
                Our Team{" "}
              </h1> */}
              <h2 className="text-sustally-violet text-3xl md:text-5xl font-semibold text-center md:text-right mx-0 md:mx-10 font-Poppins">
                Team Wall
                <br />
                <span className="text-lg  md:text-xl px-0 md:px-6 font-Inter ">
                  Would you like to work with this amazing set of people?  
                  <a className="inline-flex items-center" href="/career">
                    Join Us 
                    <img
                      src={sidearrow}
                      alt="side arrow"
                      className="w-12 -rotate-90 hover:scale-105"
                    ></img>
                  </a>
                </span>
              </h2>
              <div className="sm:hidden mt-10 md:mt-0">
                <Slider {...settings}>
                  {data.map((element, text) => {
                    return <OurTeam data={element} />;
                  })}
                </Slider>
              </div>
              {isEven ? (
                // For even number of rows
                <div className="hidden md:grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-10 w-11/12  back-cards content-center ml-auto mr-auto">
                  {data.map((element, index) => (
                    <OurTeam key={index} data={element} />
                  ))}
                </div>
              ) : (
                // For odd number of rows
                <div
                  className={`hidden md:grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-5 content-center`}
                >
                  <div className="row-span-1 md:row-span-2 mt-56">
                    <OurTeam data={data[0]} />
                  </div>

                  {data.slice(1).map((element, index) => (
                    <OurTeam key={index} data={element} />
                  ))}
                </div>
              )}

              {/* <div className="hidden back-cards content-center ml-auto mr-auto sm:grid sm:grid-cols-2 sm:w-3/4 md:w-11/12 sm:gap-10 sm:ml-auto sm:mr-auto md:grid-cols-3 xl:grid-cols-4 md:gap-5">
                  {data.map((element, index) => (
                    <OurTeam key={index} data={element} />
                  ))}
                </div> */}
            </div>
          </div>
          <div className="back side">
            <div className="content">
              <h1 className="text-sustally-violet text-3xl md:text-5xl font-semibold md:text-right text-left font-Poppins mx-4 z-100 mt-4">
                Our Alumni{" "}
              </h1>
              {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3 md:gap-5 mt-0 md:mt-10">
                {alumniData.map((element, text) => {
                  return <Alumni data={element} />;
                })}
              </div> */}
              <div className="sm:hidden">
                <Slider {...settings}>
                  {alumniData.map((element, text) => {
                    return <Alumni data={element} />;
                  })}
                </Slider>
              </div>

              {/* <div className="hidden md:block lg:hidden sm:grid grid-cols-2 mt-20">
                {alumniData.map((element, text) => {
                  return <Alumni data={element} />;
                })}
              </div> */}

              {/* {chkEven ? (
                <div className="hidden md:mt-10 sm:grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3 md:gap-5">
                  {alumniData.map((element, text) => {
                    return <Alumni data={element} />;
                  })}
                </div>
              ) : (
                <div className="hidden sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3 md:gap-5 mt-0 md:mt-24">
                  <div className="row-span-1 md:row-span-2 mt-56">
                    <Alumni data={alumniData[0]} />
                  </div>
                  {alumniData.slice(1).map((element, text) => {
                    return <Alumni data={element} />;
                  })}
                </div>
              )} */}

              <div className="hidden md:block md:mt-10">
                <Slider {...AlumniPcSettings}>
                  {chunkedAlumniData.map((chunk, index) => (
                    <div key={index} className="gap-5">
                      {" "}
                      {/* Ensuring grid layout */}
                      {chunk.map((element, idx) => (
                        <div key={idx} className="p-2">
                          {" "}
                          {/* Each grid item */}
                          <Alumni data={element} />
                        </div>
                      ))}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <span
          onClick={handleButtonClick}
          style={{ marginTop: "-500px" }}
          className=" text-sustally-violet text-5xl cursor-pointer absolute material-symbols-outlined right-5"
        >
          360
        </span>
        <button
          className="hidden  p-3 mt-2 btn-flip rounded-2xl text-base hover:scale-105	 font-[700] px-10 py-2  items-center justify-center"
          onClick={handleButtonClick}
        >
          Flip
        </button>
      </div>
    </div>
  );
}

export default Teamwall;
