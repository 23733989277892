import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Airtable from "airtable";
import { useState } from "react";
import { useParams } from "react-router-dom";

function ApplicationForm() {
  let { id } = useParams();
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");

  useEffect(() => {
    base("posts")
      .select({ view: "Grid view" })
      .eachPage((records, fetchNextPage) => {
        setPosts(records);
        fetchNextPage();
      });
  });
  let postData = posts?.find((post) => post.fields.name === id);
  const reLoad = () => {
    navigate();
  };
  return (
    <div>
      <div className="py-11">
        <p className="pb-2">**Fill this Form to apply for the job**</p>
        <iframe
          className="airtable-embed"
          src={postData?.fields.fLink}
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="533"
          style={{ background: "transparent", border: "1px solid #ccc" }}
        ></iframe>
      </div>
      <p className="pb-3 text-center">After Submission of Application</p>
    </div>
  );
}

export default ApplicationForm;
