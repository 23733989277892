import React from 'react'

function CareerPost({post}) {

  return (
    <div className='relative bg-secondary-light dark:bg-secondary-dark font-Inter w-72 h-96 rounded-2xl '>
    <div className='absolute inset-4 shadow-xl bg-tertiary-light dark:bg-tertiary-dark  px-10 rounded-2xl flex flex-col items-center justify-center'>
      <div className='relative '>
        <h1 className='text-2xl md:text-3xl text-sustally-violet font-semibold pt-5 pb-5 '>{post.fields.name}</h1>
        <p className='opacity-75 pb-10'>{post.fields.mdescription}</p>
        <div className='transform transition duration-200 hover:scale-[102%] pb-10'>
        <a aria-label="View Career Post" className='px-4 py-2 rounded-full btn-careerpost text-white bg-sustally-violet ' href={'career/'+post.fields.name}>Apply Here</a>
        </div>
      </div>
    </div>
    </div>
  )
}

export default CareerPost