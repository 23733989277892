import React from 'react'
import { signInWithPopup } from "firebase/auth"
import { auth, provider } from "../config/firebase"
import { useNavigate } from "react-router-dom"
import { useAuthState } from 'react-firebase-hooks/auth'
import { signOut } from "firebase/auth"
import googleLogo from '../assets/googleLogo.png'
import Pages from '../pages/Pages'

function CareerApp({ isDarkMode }) {

  const navigate = useNavigate()
  const isDark = isDarkMode

  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);
    navigate("/career")
  }
  const [user] = useAuthState(auth);
  const signUserOut = async () => {
    await signOut(auth);
  }
  return (

    <div>
      {/*
      {!user ? (
        <div className='car_login'>
          <h2>Login With Google </h2>
          <button onClick={signInWithGoogle} className='car_lin'><img src={googleLogo} /></button>
        </div>
      ) : (
        <div  className='mt-20' style={{ minHeight: '100vh'}}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '20px' }}>
            {
              user && (
                <div className='flex items-center gap-4'>
                  <p className="user_para">{user?.displayName}</p>
                  <img style={{ height: '50px', borderRadius: '50%' }} src={user?.photoURL} />
                  <button style={{ border: '1px solid white', padding: '5px 10px', borderRadius: '10px' }} onClick={signUserOut} >Sign Out</button>
                </div>
              )
            }



          </div>
          <Pages
            isDark={isDark}
          />
          </div>
      )}
*/}
      <div className='my-20'>
      <Pages
        isDark={isDark}
      /></div>

    </div>
  )
}

export default CareerApp