import React from "react";
//import { auth } from "../config/firebase"
//import { useNavigate } from "react-router-dom"
//import { useAuthState } from 'react-firebase-hooks/auth'
//import { signOut } from "firebase/auth"
import CareerPost from "../components/CareerPost";
import { useEffect } from "react";
import Airtable from "airtable";
import { useState } from "react";
import jdColor from "../assets/careers_aboutus/jd_colour.svg";
import chatColor from "../assets/careers_aboutus/chat_colour.svg";
import codeColor from "../assets/careers_aboutus/code_colour.svg";
import mailcolor from "../assets/careers_aboutus/mail_colou.svg";
import circlecolor from "../assets/careers_aboutus/circle_colour.svg";

import downarrow from "../assets/careers_aboutus/downarrow.png";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import SEO from "../components/SEO";

function Careers(isDarkMode) {
  const [posts, setPosts] = useState([]);
  /* const navigate = useNavigate()*/
  /*
  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);
    console.log(result)
    navigate("/career")
  }
  */
  //const [user] = useAuthState(auth);
  /*const signUserOut = async () => {
    await signOut(auth);
  }

*/

  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");

  useEffect(() => {
    base("posts")
      .select({ view: "Grid view" })
      .eachPage((records, fetchNextPage) => {
        setPosts(records);
        fetchNextPage();
      });
  });

  return (
    <div className="font-Inter">
      <SEO
        title="Careers - Sustally"
        description="Join Sustally and be part of a team that's redefining impact measurement for growing companies. Explore rewarding career opportunities with us today."
        name="career"
      />
      <div style={{ minHeight: "100vh", margin: "6% 0%" }}>
        {/* <div className='flex items-center gap- justify-end mt-24 mr-4'>
          {!user ? (
            <div className='flex items-center'>
              <h2>Login With Google : </h2>
              <button onClick={signInWithGoogle}><img className='car_line' src={googleLogo} /></button>
            </div>
          ):(
            <div>
            {
                  user && (
                    <div className='flex items-center gap-4 '>
                      <p className="user_para">{user?.displayName}</p>
                      <img style={{ height: '50px', borderRadius: '50%' }} src={user?.photoURL} />
                      <button style={{ border: '1px solid white', padding: '5px 10px', borderRadius: '10px' }} className='hover:bg-custom-purple' onClick={signUserOut} >Sign Out</button>
                    </div>
                  )
                }

      
              
            </div>
          )}
        </div> */}
        <div className="intro min-h-screen flex flex-col mx-auto justify-center gap-20">
          <h1 className="md:text-7xl text-5xl text-center md:ml-0 text-sustally-violet font-bold font-Poppins">
            Hey there... future ally!
          </h1>
          <p className="text-center md:text-3xl text-xl font-Inter px-4 ">
            Ready to embark on a journey to join our awesome team? <br></br>
            <br></br>
            Our interview process is as breezy as an evening at the beach{" "}
            <br></br>
            <br></br>
            <div className="flex items-center justify-center">
              <span className="font-semibold relative flex gap-2">
                Here's the{" "}
                <span>
                  <img
                    className="absolute w-32 md:w-44 md:-top-16 md:left-32  left-14 -top-12"
                    src={circlecolor}
                    alt="hand drawn circle"
                  ></img>
                  specifics..
                </span>
              </span>
            </div>
          </p>
          <div className="flex justify-center items-center mx-auto">
            <Link
              to="process"
              spy={true}
              smooth={true}
              offset={50}
              duration={200}
            >
              <motion.img
                animate={{ y: -20 }}
                transition={{
                  duration: 0.8,
                  repeat: Infinity,
                  type: "spring",
                  repeatDelay: 1,
                  bounce: 0.3,
                  mass: 0.8,
                }}
                src={downarrow}
                alt="down arrow"
                className="w-16 cursor-pointer"
                width={""}
                height={""}
              ></motion.img>
            </Link>
          </div>
        </div>

        <div className="process md:min-h-screen grid md:grid-cols-4 grid-rows-3 gap-10 md:gap-0 md:p-16 p-4">
          <div className="w-200 h-200 md:row-start-1 md:col-span-2">
            <div className="md:flex text-center p-2  mr-4">
              <div className="flex flex-col justify-center items-center">
                <img
                  src={jdColor}
                  alt="Job Description"
                  className="w-28 md:w-[80%]  py-4"
                  width={""}
                  height={""}
                ></img>
              </div>
              <div className="flex flex-col gap-4 font-Inter">
                <h2 className="text-3xl font-semibold">
                  Dive into the Job Description
                </h2>
                <p className="text-xl">
                  First things first, check out the job description (JD) and get
                  a feel for what we're all about. If it sounds like your kind
                  of gig, hit us up with your application.
                </p>
              </div>
            </div>
          </div>
          <div className="w-200 h-200 md:col-start-2 md:col-span-2 md:row-start-2 pt-10 md:pt-0">
            <div className="md:flex text-center p-2 mr-4">
              <div className="flex flex-col justify-center items-center">
                <img
                  src={codeColor}
                  alt="Code"
                  className="w-28 md:w-[80%] "
                  width={""}
                  height={""}
                ></img>
              </div>
              <div className="flex flex-col gap-4 font-Inter">
                <h2 className="text-3xl font-semibold">
                  Little Warm-Up Exercise
                </h2>
                <p className="text-xl">
                  We like to keep things fun! Once you apply, we'll send you a
                  cool screening task. Think of it as a mini- mission to show
                  off your skills. You'll ace it!
                </p>
              </div>
            </div>
          </div>
          <div className="w-200 h-200  md:col-start-3 md:col-span-2 md:row-start-3">
            <div className="md:flex text-center p-2 mr-4">
              <div className="flex flex-col justify-center items-center">
                <img
                  src={chatColor}
                  alt="Chat"
                  className="w-28 md:w-[90%] py-4"
                  width={""}
                  height={""}
                ></img>
              </div>
              <div className="flex flex-col gap-4 font-Inter">
                <h2 className="text-3xl font-semibold">Let's Chat!</h2>
                <p className="text-xl">
                  Once you've aced the screening task, we'll set up a chat. No
                  fancy suits required-just be yourself. We're friendly, we
                  promise! If needed, there might be a follow-up chat to dive
                  deeper into your awesomeness.
                </p>
              </div>
            </div>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, scale: 0.4 }}
          whileInView={{ opacity: 1 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.4 }}
          className="reveal md:min-h-screen mt-20 font-Inter"
        >
          <div className="flex flex-col gap-10">
            <h2 className="md:text-7xl text-5xl text-center md:mt-24 md:ml-0 text-sustally-violet font-bold font-Poppins">
              The Reveal
            </h2>
            <div className="md:flex  text-center p-2 mr-4  gap-10 items-center md:justify-between">
              <div className="flex flex-col md:flex-row justify-evenly md:justify-start md:gap-10  items-center max-w-4xl">
                <img
                  src={mailcolor}
                  alt="Chat"
                  className="w-28 md:w-[14%] py-4"
                  width={""}
                  height={""}
                ></img>
                <p className="text-xl md:w-[36%]">
                  We'll let you know how it all went down. No waiting in
                  suspense here! We'll share the results with you promptly.
                </p>
              </div>
              <h2 className="text-3xl mt-10 md:mt-0 font-semibold">
                That's it! Simple, right?
              </h2>
            </div>
            <h2 className="text-xl md:text-3xl mx-auto justify-center font-semibold font-Inter mb-20">
              Your next adventure starts here
            </h2>
            <div className="flex justify-center items-center mx-auto hidden md:block">
              <Link to="job-posts" spy={true} smooth={true} duration={200}>
                <motion.img
                  animate={{ y: -25 }}
                  transition={{
                    duration: 0.9,
                    repeat: Infinity,
                    type: "spring",
                    repeatDelay: 1,
                    bounce: 0.1,
                    mass: 0.8,
                  }}
                  src={downarrow}
                  alt="down arrow"
                  className="w-16 cursor-pointer"
                  width={""}
                  height={""}
                ></motion.img>
              </Link>
            </div>
          </div>
        </motion.div>

        <div className="job-posts p-4">
          <h2 className="text-xl md:text-3xl font-Poppins font-semibold text-center mb-24 mt-24 ">
            {" "}
            Are you ready to be part of our incredible team and make a splash in
            your career, hit that "Apply" button!
          </h2>
          <div className="flex flex-col mx-auto justify-center md:flex-row md:justify-start gap-20 md:gap-10 items-center">
            {posts.map((post) => (
              <CareerPost key={post.id} post={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
