import React from 'react'
import SEO from "../components/SEO";
function PrivacyPolicy({isDarkMode}) {
  return (
    <div className='text-7xl min-h-screen mt-32 text-center'>
      <SEO 
        title='Privacy Policy - Sustally'
        description="Your data privacy matters to us. Review Sustally's comprehensive privacy policy to understand how we protect your information while you track and share your company's impact."
        name = 'privacy policy'
      />
        This the Privacy Policy
    </div>
  )
}

export default PrivacyPolicy