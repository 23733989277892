import React, { useState } from 'react'
import Teamwall from './Teamwall'
import teamimg from '../common/styles/Team/TeamPic1.png'
import ashwinworkplace from '../assets/ashwin_workplace.jpg'
import anithaworkplace1 from '../assets/anitha_workplace.jpg'
import anithaworkplace2 from '../assets/anitha_workplace2.jpg'
import rahulworkplace1 from '../assets/rahul-workplace.jpg'
import sudipcampus from '../assets/sudip_campus.jpg'
import sudipworkplace from '../assets/sudip_workplace.jpg'
import { motion } from 'framer-motion'
import nandanaworkplace from '../assets/Nandana_Workplace.jpg'
import rahulworkplace2 from '../assets/rahul_workplace2.jpg'
import SEO from "../components/SEO";
import Advisors from '../components/Advisors'
import { Link } from 'react-scroll';
import downarrow from '../assets/careers_aboutus/downarrow.png'
function Team({ isDarkMode }) {

  const [rotation, setRotation] = useState(0);
  const [isClicked, setIsClicked] = useState([false, false, false, false, false, false, false, false]);
  const handleTap = (index) => {
    const newClickedState = [...isClicked];
    newClickedState[index] = !newClickedState[index];
    setIsClicked(newClickedState);
  };
  const skyData = [
    {
      imageSrc: rahulworkplace1,
      location: 'Hyderabad',
      Rotate: rotation + 4,
    },
    {
      imageSrc: sudipworkplace,
      location: 'Dantan',
      Rotate: rotation - 3,
    },
    {
      imageSrc: anithaworkplace1,
      location: 'Coimbatore',
      Rotate: rotation - 6,
    },

    {
      imageSrc: nandanaworkplace,
      location: 'Maddalakunta',
      Rotate: rotation - 4
    }

  ]
  const cardData = [
    // {
    //   imageSrc: sudipworkplace,
    //   location: 'Midnapore',
    //   Rotate: rotation + 4,
    // },
    {
      imageSrc: anithaworkplace2,
      location: 'Gobichettipalayam',
      Rotate: rotation + 3,
    },
    {
      imageSrc: ashwinworkplace,
      location: 'Elamanchili',
      Rotate: rotation + 4,
    },
    // {
    //   imageSrc: anithaworkplace1,
    //   location: 'Coimbatore',
    //   Rotate: rotation - 6,
    // },
    // {
    //   imageSrc: sudipworkplace,
    //   location: 'Dantan',
    //   Rotate: rotation - 3,
    // },
    {
      imageSrc: rahulworkplace2,
      location: 'Hyderabad',
      Rotate: rotation - 4,
    },
    {
      imageSrc: sudipcampus,
      location: 'VIT Chennai',
      Rotate: rotation - 4,
    },

  ];
  return (
    <>
      <SEO
        title='Team - Sustally'
        description="Discover global standards and best practices of measuring your firm's sustainability efforts and track your ESG impact with Sustally, the comprehensive online platform for sharing your impact with your stakeholders"
        name='team'
      />
      <div>
        <img className='w-[25%] absolute top-0 left-0 hidden md:block' src={teamimg} alt='teaming pic' width={""} height={""} />

        <Advisors />
        <div className=' justify-center items-center mx-auto flex mt-16'>
          <Link to="t-wall" aria-label="Scroll to Team Wall" spy={true} smooth={true} duration={200}>
            <motion.img
              animate={{ y: -20 }}
              transition={{ duration: 0.8, repeat: Infinity, type: 'spring', repeatDelay: 1, bounce: 0.3, mass: 0.8 }}
              src={downarrow}
              alt='Scroll down'
              aria-hidden="true" 
            className='w-16 cursor-pointer'
            width={""}
            height={""}
  />
          </Link>
        </div>
        <Teamwall className="t-wall" />
      </div>

      <div className='h-screen mt-40 md:px-0 px-4'>
        <p className='md:text-5xl text-3xl font-semibold text-sustally-violet font-Poppins'>Behind the Screens</p>
        <p className='text-xl pt-4 font-semibold font-Inter'>A Peek into Our Remote Team's Work & Life</p>
        <div className='grid grid-cols-1 xl:grid-cols-2 relative'>
          <div className='workplace-card-container mt-4 flex mx-auto justify-center items-center px-4'>
            {skyData.map((card, index) => (
              <motion.div
                key={index}
                onTap={() => handleTap(index - 4)}
                animate={{ scale: isClicked[index] ? 1.6 : 1, zIndex: isClicked[index] ? 50 : 0 }}
                initial={{ rotate: card.Rotate }}
                whileHover={{ scale: 1.8, zIndex: 20, rotate: rotation }}
                className={`workplace-card ${isClicked[index] ? 'clicked' : ''
                  } ${index === 1 ? 'left-1/4' : index === 2 ? 'left-2' : index === 3 ? 'right-8 md:right-4' : 'right-32'
                  } rotate-${card.initialRotate} ${index === 0 ? 'top-20' : index === 1 ? 'top-10' : index === 3 ? 'bottom-20' : ''
                  } hover:z-50`}
              >
                <img
                  className='w-[100%] h-[80%] object-cover border-4 border-custom-grey opacity-40 hover:opacity-100'
                  src={card.imageSrc}
                  alt='{card.location}'
                  width={""} height={""}
                />
                <p className='font-Caveat text-lg text-black flex justify-center'>{card.location}</p>
              </motion.div>
            ))}
          </div>
          <div className='workplace-card-container mt-4 flex mx-auto justify-center items-center px-4'>
            {cardData.map((card, index) => (
              <motion.div
                key={index}
                onTap={() => handleTap(index + 4)}
                animate={{ scale: isClicked[index] ? 1.6 : 1, zIndex: isClicked[index] ? 50 : 0 }}
                initial={{ rotate: card.Rotate }}
                whileHover={{ scale: 1.8, zIndex: 20, rotate: rotation }}
                className={`workplace-card ${isClicked[index] ? 'clicked' : ''
                  } ${index === 1 ? 'left-1/4' : index === 2 ? 'left-2' : index === 3 ? 'right-8 md:right-4' : index === 4 ? 'right-20 md:right-10' : 'right-32'
                  } rotate-${card.initialRotate} ${index === 0 ? 'top-20' : index === 1 ? 'top-10' : index === 3 ? 'bottom-20' : index === 4 ? 'bottom-10' : ''
                  } hover:z-50`}
              >
                <img
                  className='w-[100%] h-[80%] object-cover border-4 border-custom-grey opacity-40 hover:opacity-100'
                  src={card.imageSrc}
                  alt='{card.location}'
                  width={""} height={""}
                  onContextMenu={(e) => { e.preventDefault() }}
                />
                <p className='font-Caveat text-lg text-black flex justify-center'>{card.location}</p>
              </motion.div>
            ))}
          </div>

        </div>
      </div>
    </>
  )
}

export default Team