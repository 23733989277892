import React, { useState, useEffect, useRef } from "react";
import "../common/styles/styles.css";
import darkLogo from "../assets/Logomark Black.svg";
import lightLogo from "../assets/Logomark white.svg";
import Divider from "./Divider";
import FooterAnimation from "../assets/waves.gif";
import FooterAnimationColor from "../assets/waves_color.gif";
import FooterAnimationLight from '../assets/waves-light.gif'
import FooterAnimationLightColor from '../assets/waves-light-color.gif'
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import BlogContactus from "./BlogContactus";

function Footer({isDarkMode}) {
  const [isVisible, setIsVisible] = useState(true);
  const lineHeight = {
    hidden: { height: 0 },
    show: { height: "70%" },
  };
  const [showColorImage, setShowColorImage] = useState(false);
  const sectionRef = useRef(null);
  //handle everytime animation
  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        const isInView = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (isInView) {
          if (!showColorImage) {
            // If not already transitioning, start the transition
            timeoutId = setTimeout(() => {
              setShowColorImage(true);
            }, 1950); // Adjust the timeout duration as needed
          }
        } else {
          if (timeoutId) {
            // Cancel the transition if the user scrolls away
            clearTimeout(timeoutId);
          }
          setShowColorImage(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setShowColorImage]);

  useEffect(() => {
    // Preload both images
    const initialImage = new Image();
    initialImage.src = FooterAnimation;

    const colorImage = new Image();
    colorImage.src = FooterAnimationColor;

    const initialImageLight = new Image();
    initialImageLight.src = FooterAnimationLight;

    const colorImageLight = new Image();
    colorImageLight.src = FooterAnimationColor;

    // When both images are loaded, set showColorImage to true
    Promise.all(
      [initialImage, colorImage, initialImageLight, colorImageLight].map(
        (image) =>
          new Promise((resolve) => {
            image.onload = resolve;
          })
      )
    ).then(() => {
      setShowColorImage(true);
    });

    // Clean up
    return () => {
      initialImage.onload = null;
      colorImage.onload = null;
      initialImageLight.onload = null;
      colorImageLight.onload = null;
    };
  }, []);

  const { ref, inView } = useInView({ triggerOnce: true });
  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  const handleAnimationComplete = () => {
    if (isVisible) {
      setIsVisible(false);
    }
  };

  //code to disable animation in other pages
  const location = useLocation();

  const [Path, setPath] = useState(
    location.pathname.toLowerCase().substring(1)
  );
  const [IsOnOtherPage, setIsOnOtherPage] = useState(
    Path === "" ? true : false
  );

  useEffect(() => {
    console.log(location.pathname);
    setPath(location.pathname.toLowerCase().substring(1));
  }, [location.pathname]);

  useEffect(() => {
    if (Path == "") {
      setIsOnOtherPage(true);
    } else {
      setIsOnOtherPage(false);
    }
  }, [Path]);

  console.log("isDarkMode:", isDarkMode); // Check if the mode is correctly passed
  console.log("showColorImage:", showColorImage);
  return (
    <>
      {IsOnOtherPage && (
        <motion.div
          ref={ref}
          variants={lineHeight}
          initial="hidden"
          animate={isVisible ? "show" : "hidden"}
          onAnimationComplete={handleAnimationComplete}
          transition={{ duration: 0.9, delay: 1.2 }}
          style={{ transformOrigin: "top-right" }}
          className=" snakeline absolute w-[10px] h-full increase1 origin-top left-4 rounded-full mt-20"
        ></motion.div>
      )}

      <div className="w-screen overflow-hidden mt-36">
        {IsOnOtherPage && (
          <div ref={sectionRef}>
            <motion.img
              key={showColorImage ? isDarkMode==="dark" ? "color-image" : "color-image-light" :  isDarkMode==="dark"? "initial-image":"initial-image-light"}
              src={showColorImage ? isDarkMode==="dark"? FooterAnimationColor : FooterAnimationLightColor:isDarkMode==="dark"? FooterAnimation:FooterAnimationLight}
              className="w-[100%]"
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
              width={""} height={""}
              alt="Footer Image"
            />
          </div>
        )}
        {!IsOnOtherPage && <BlogContactus />}
        <div className="p-4 pt-10 rounded-b-lg footerBack ft-left">
          <div className="flex flex-col items-center justify-between gap-10 ft-title md:flex-row lg:gap-0">
            <div className="flex flex-col items-start gap-2 lg:flex-row md:gap-10 flex-wrap">
              <div className="flex flex-row items-center flex-wrap">
                <img
                  className="hidden ft-logo dark:block"
                  width={""}
                  height={""}
                  alt="lightLogo"
                  src={lightLogo}
                />
                <img
                  className="block ft-logo dark:hidden"
                  width={""}
                  height={""}
                  alt="darkLogo"
                  src={darkLogo}
                />
                <h2 className="text-3xl font-Rymaneco md:text-5xl ">
                  sustally
                </h2>
              </div>
              <p className="items-center max-w-lg mt-4 text-xl italic md:max-w-lg font-Inter">
                Choose Sustally as your sustainability ally, and together, we
                will pave the way for a better and brighter future
              </p>
            </div>

            <div className="flex  gap-4 links md:flex-row font-Inter flex-wrap md:flex-nowrap items-start justify-center mx-auto">
              <ul className="flex flex-col gap-3  w-[130px]">
                <li>
                  <h2 className="text-xl font-semibold text-sustally-violet font-Poppins">
                    Company
                  </h2>
                </li>
                <li>
                  <a aria-label="Visit about us page" href="/aboutus">
                    <h2 className="hover:text-sustally-violet">About Us</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit careers page" href="/career">
                    <h2 className="hover:text-sustally-violet">Careers</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit Team page" href="/teamwall">
                    <h2 className="hover:text-sustally-violet">Team</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit media kit page" href="/Mediakit">
                    <h2 className="hover:text-sustally-violet">Media Kit</h2>
                  </a>
                </li>

                {/*
                <li><a href='/'><h2 className='hover:text-sustally-violet'>Privacy Policy</h2></a></li> */}
              </ul>

              <ul className="flex flex-col gap-3 w-[130px] ">
                <li>
                  <h2 className="text-xl font-semibold text-sustally-violet font-Poppins">
                    Product
                  </h2>
                </li>
                <li>
                  <a aria-label="Visit Features page" href="/product">
                    <h2 className="hover:text-sustally-violet">Overview</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit FAQ Page" href="/product-supply-chain">
                    <h2 className="hover:text-sustally-violet">Supply Chains</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit FAQ Page" href="/product-investors">
                    <h2 className="hover:text-sustally-violet">Startup Ecosystems</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit FAQ Page" href="/product-ngo">
                    <h2 className="hover:text-sustally-violet">NGO or Govt</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit FAQ Page" href="/product-agencies">
                    <h2 className="hover:text-sustally-violet">Consultants</h2>
                  </a>
                </li>
                {/* <li>
                  <a aria-label="Visit Calendly to Schedule Demo" href="/benefits">
                    <h2 className="hover:text-sustally-violet">Benefits</h2>
                  </a>
                </li> */}
              </ul>
              

              <ul className="flex flex-col gap-3  w-[130px]">
                <li>
                  <h2 className="text-xl font-semibold text-sustally-violet font-Poppins">
                    Industry
                  </h2>
                </li>
                <li>
                  <a aria-label="Visit Resources page" href="/blog?Beyond-Spreadsheets">
                    <h2 className="hover:text-sustally-violet font-Inter">Resources</h2>
                  </a>
                </li>
                {/* <li>
                  <a aria-label="Visit Resources page" href="/employeesignin">
                    <h2 className="hover:text-sustally-violet font-Inter">Employee</h2>
                  </a>
                </li> */}
                
                {/* <li><a href='/aboutus'><h2 className='hover:text-sustally-violet'>Text</h2></a></li> */}
              </ul>
              <ul className="flex flex-col gap-3  w-[130px]">
                <li>
                  <h2 className="text-xl font-semibold text-sustally-violet font-Poppins">
                    Get In Touch
                  </h2>
                </li>
                <li>
                  <a aria-label="Visit Resources page" href="https://calendly.com/sustally_team/product_demo?month=2024-02">
                    <h2 className="hover:text-sustally-violet font-Inter">Schedule Demo</h2>
                  </a>
                </li>
                <li>
                  <a aria-label="Visit Resources page" href="/faq">
                    <h2 className="hover:text-sustally-violet font-Inter">FAQ</h2>
                  </a>
                </li>
                
                {/* <li><a href='/aboutus'><h2 className='hover:text-sustally-violet'>Text</h2></a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
