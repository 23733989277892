import React, { useContext } from 'react'
import { motion, useMotionValue, useSpring, useMotionValueEvent, useScroll, AnimatePresence } from 'framer-motion';
import { Link } from 'react-scroll';
import sparkle from '../assets/careers_aboutus/sparkle_colour.svg'
import downarrow from '../assets/careers_aboutus/downarrow.png'
import handArrowLight from '../assets/careers_aboutus/handArrowLight.svg'
import handArrowDark from '../assets/careers_aboutus/handArrowDark.svg'
import Product from '../assets/careers_aboutus/product.svg'
import People from '../assets/careers_aboutus/people_colour.svg'
import Pride from '../assets/careers_aboutus/pride_color.svg'
import network from '../assets/careers_aboutus/network_colour.svg'
import { useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../assets/cross.png'
import { Scale } from '@mui/icons-material';
import { Helmet } from "react-helmet-async";
import { ThemeContext } from '../App';
import SEO from "../components/SEO";
import ProductPeoplePride from '../components/ProductPeoplePride';
function AboutUs(){
    const [productOpen, setProductOpen] = useState(false);
    const [prideOpen, setPrideOpen] = useState(false);
    const [peopleOpen, setPeopleOpen] = useState(false);
    const {isDarkMode} = useContext(ThemeContext)
    const handleProduct = () =>{
        setProductOpen((prev)=>{
            return !prev;
        })
    }
    const handlePride = () =>{
        setPrideOpen((prev)=>{
            return !prev;
        })
    }
    const handlePeople = () =>{
        setPeopleOpen((prev)=>{
            return !prev;
        })
    }
    const popRight = {
        hidden : {
            x : "-100vw",
            opacity:0, 
        },
        visible:{
            opacity:1,
            x:"0",
            transition: {
                duration:0.2,
                type:"spring",
                damping:100,
                stiffness:500,
            }
        },
        exit:{
            x:"-100vw",
            opacity:0,
        }
    }
    const popLeft = {
        hidden : {
            x:"100vw",
            opacity:0, 
        },
        visible:{
            opacity:1,
            x:"0",
            transition: {
                duration:0.2,
                type:"spring",
                damping:100,
                stiffness:500,
            }
        },
        exit:{
            x:"100vw",
            opacity:0,
        }
    }
    const popUp = {
        hidden : {
            y:"100vh",
            opacity:0, 
        },
        visible:{
            opacity:1,
            y:"0",
            transition: {
                duration:0.2,
                type:"spring",
                damping:100,
                stiffness:500,
            }
        },
        exit:{
            y:"100vh",
            opacity:0,
        }
    }

    const [mobile, setMobile] = useState(false);
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      if(width<990){
        setMobile(true);
      }
      else{
        setMobile(false);
      }
    });

    return(
        <div id={isDarkMode}>
            <SEO 
                title='About Us - Sustally'
                description="Explore the vision and mission of Sustally. Sustally enables growing companies to systematically measure their impact and share it with their stakeholders."
                name = 'about'
            />
            <div className='about-us-home '>
            <div className=' relative flex flex-col md:flex-row items-center justify-center mx-auto mt-44'>
                <h1 className='font-bold font-Poppins md:text-7xl text-5xl text-center max-w-4xl px-4'  
                    style={{ lineHeight: "1.6"}}>
                        <span className='text-sustally-violet'>Sustally</span>, Where We Make Magic <br></br> Happen! 
                </h1>
                <motion.img 
                initial={{opacity:1}}
                animate={{ opacity: [0.8,1,0.8] }}
                transition={{ duration:1 , repeat:Infinity ,type: 'tween' }}
                className='md:w-40 w-28 md:absolute md:bottom-0 md:right-4 lg:bottom-0 lg:right-10 xl:right-60' src={sparkle}
                alt='sparkle image' width={""} height={""}> 
                  
                </motion.img>
            </div>
            <div className=' justify-center items-center mx-auto mt-20 hidden md:flex'>
                    <Link to="about-middle-page" spy={true} smooth={true}  duration={200}>
                        <motion.img   
                        animate={{ y: -20 }}
                        transition={{ duration:0.8 , repeat:Infinity ,type: 'spring', repeatDelay: 1, bounce:0.3, mass: 0.8 }}
                        src={downarrow} alt='down arrow' className='w-16 cursor-pointer' width={""} height={""}></motion.img>
                    </Link>
            </div>
            </div>

            {/* <div className='team-wall md:min-h-screen mt-44 md:mt-0'>
                <div className='flex mx-auto items-center text-center justify-center'>
                    <h2 className='md:text-7xl text-5xl font-semibold font-Inter mt-20'>Small Team Big Impact</h2>
                </div>
                <div className='mt-10 flex mx-auto items-center justify-center'>
                        <img src={TeamWallDark} alt loading='lazy' className='dark:block hidden md:w-[70%] w-[90%] '></img>
                        <img src={TeamWallLight} alt loading='lazy' className='block dark:hidden md:w-[70%] w-[90%] '></img>
                    
                </div>
            </div> */}

            <div className='about-middle-page  mt-44 max-w-7xl flex  mx-auto'>
                <div className='flex md:flex-row flex-col md:pt-20 md:gap-20 gap-10' >
                    <h2 className='md:text-5xl text-3xl font-semibold max-w-2xl px-4 font-Poppins bg-secondary-light dark:bg-secondary-dark h-auto xl:h-[380px] py-10 rounded-2xl'>We're not just a company, we're a group of <span className='text-sustally-violet'>passionate</span> individuals on a mission to create something extraordinary</h2>
                    <div className='flex flex-col gap-10 font-Inter items-center px-4'>
                        <h2 className='md:text-3xl text-xl'>Our sustally engine is fueled by a set of guiding principles that define who we are and how we operate </h2>
                        <h2 className='md:text-3xl text-xl'>These principles are like the North Star guiding us towards creating products that exceed industry standards while fostering a culture of respect, collaboration, and sustainability</h2>
                        <img src={handArrowLight} alt='hand drown arrow' className='lg:w-[26%] w-[25%] rotate-90 dark:hidden block' width={""} height={""}></img>
                        <img src={handArrowDark} alt='hand drown arrow' className='lg:w-[26%] w-[25%] rotate-90 dark:block hidden' width={""} height={""}></img>
                    </div>
                </div>
            </div>

           <div className='mt-44'>
            <ProductPeoplePride/>
           </div>
            

            <div className='join-us mt-44'>
                <div className=' flex '>
                    <div className='flex flex-col gap-20 z-10 mt-20'>
                    <h2 className='md:text-5xl text-3xl md:max-w-4xl pt-20 font-semibold px-4 md:text-left backdrop-blur-sm text-center font-Poppins'>Join us in making magic happen together</h2>
                    <p className='text-xl md:text-3xl px-4 max-w-2xl md:text-left pb-4 text-center backdrop-blur-sm'>
                        We welcome as many patrons aka well-wishers to be a part of our journey. We're not just about products and services 
                        we're about creating a better world, one step at a time.
                    </p>
                    </div>
                    <motion.img
                    initial={{opacity:1}}
                    animate={{ opacity: [0.8,1,0.8] }}
                    transition={{ duration:1 , repeat:Infinity ,type: 'tween' }}
                    src={network} className='w-[40%] md:w-[30%] py-10 absolute right-20 z-0  md:static mt-24 ' alt='network image' width={""} height={""}></motion.img>
                    </div>

            </div>

        </div>
    )
}


export default AboutUs;