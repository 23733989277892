
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyChFhbl0bu489ghASLOpF14tcL30nQAlAk",
  authDomain: "susally-career.firebaseapp.com",
  projectId: "susally-career",
  storageBucket: "susally-career.appspot.com",
  messagingSenderId: "560629937592",
  appId: "1:560629937592:web:f6712d8e8ca72c770f4b4d",
  measurementId: "G-68ZWP1EZ83"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
