import Hamburger from 'hamburger-react';
import '../common/styles/styles.css';
import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import openInNewTab from '../assets/opennewtab.png'
import door from '../assets/door.png'
function App() {
  return (
      <NavItem>
        <DropdownMenu></DropdownMenu>
      </NavItem>
  );
}


function NavItem(props) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // Add an event listener to the document body to close the menu when a click occurs outside
    const handleClickOutside = (event) => {
      if (open && !event.target.closest('.nav-item')) {
        setOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  return (
    <ul>
    <li className="nav-item text-lg">
      <a className="icon-button" onClick={() => setOpen(!open)}>
      <Hamburger label='Open the menu' toggle={setOpen} toggled={open} />
      </a>

      {open && props.children}
    </li>
    </ul>
  );
}

function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <a aria-label="Nav Menu" className="menu-item hover:bg-tertiary-light dark:hover:bg-tertiary-dark" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>

      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
          {/* <DropdownItem><Link to="productandfeature" spy={true} smooth={true} offset={50} duration={500}>Our Products</Link></DropdownItem>
          <DropdownItem><Link to="pricingplan" spy={true} smooth={true} offset={50} duration={500}>Pricing plan</Link></DropdownItem>
          */}
          <DropdownItem><a aria-label="Visit About us page" href='/aboutus' >About Us</a><img className='w-4 ml-4' src={door} width={""} height={""} alt='Enter'></img></DropdownItem>
          <DropdownItem><a aria-label="Visit Product beta page" href='/product' >Product</a><img className='w-4 ml-4' src={door} width={""} height={""} alt='Enter'></img></DropdownItem>
          <DropdownItem><a aria-label="Visit FAQ Page" href='https://calendly.com/sustally_team/product_demo?month=2024-02' target='_blank'>Schedule Demo</a><img className='w-3 ml-4' src={openInNewTab} width={""} height={""} alt='open in newtab'></img></DropdownItem> 
          {/* <DropdownItem><Link to="contactt" spy={true} smooth={true} offset={50} duration={500}>Let's Discuss</Link></DropdownItem> */}
          <DropdownItem><a aria-label="Visit Team page" href='/teamwall' >Meet the Team</a><img className='w-4 ml-4' src={door} width={""} height={""} alt='Enter'></img></DropdownItem>  
          <DropdownItem><a aria-label="Visit Careers page" href='/career'>Join Us</a><img className='w-4 ml-4' src={door} width={""} height={""} alt='Enter'></img></DropdownItem> 
          <DropdownItem> </DropdownItem>

        </div>
      </CSSTransition>
    </div>
  );
}

export default App;
