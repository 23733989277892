import React from "react";
import blogimg1 from "./blogAssets/blog1.1.png";
import blogimg2 from "./blogAssets/blog1.2.png";
import blogimg3 from "./blogAssets/blog1.3.png";
import blogmg4 from "./blogAssets/blog1.4.png";
import blogimg5 from "./blogAssets/blog1.5.png";
import blogimg6 from "./blogAssets/blog1.6.png";
import BlogD from "../components/BlogD";
import downarrow from '../assets/careers_aboutus/downarrow.png'
import { motion, } from 'framer-motion';
import { Link } from 'react-scroll'; import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import contact from '../assets/contact1.2.png'
import BlogContactUs from '../components/BlogContactus';

function Blog1({ selectedblog }) {
  /* <h2 className='text-5xl py-2 font-semibold text-custom-purple'></h2>
    <h2 className='text-3xl my-2'></h2>
    <br></br>
    <br></br>
    <h3 className='text-xl text-custom-purple'></h3> */


  const [scrollTop, setScrollTop] = useState(0);


  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 23;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);


    return () => window.removeEventListener("scroll", onScroll);
  }, []);


  return (
    <div className="">
      <div
        className="progressMainStyle"
        style={{ width: `${scrollTop * 3.80}%` }}
      ></div>
      <div
        className="progressMainStyle1 hidden lg:block"
        style={{ height: `${scrollTop}%` }}
      ></div>
      <div className="fixed right-0 top-[40%] bg-transparent hidden lg:block ">
        <HashLink smooth to={"#intro"}>
          <h2 style={{}} className={`py-1 rounded-l-lg  dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linear text-black border ${scrollTop >= 0 && scrollTop <= 5 ? 'bg-sustally-violet' : 'bg-transparent'}`}>
            Introduction
          </h2>
        </HashLink>
        <HashLink smooth to={"#challenges"}>
          <h2 className={`py-1 rounded-l-lg  dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 5 && scrollTop <= 10 ? 'bg-sustally-violet' : 'bg-transparent'}`}>
            Existing Challenges
          </h2>
        </HashLink>
        <HashLink smooth to={"#pitfalls"}>
          <h2 className={`py-1 rounded-l-lg  dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 10 && scrollTop <= 15 ? 'bg-sustally-violet' : 'bg-transparent'}`}>
            MIS pitfalls
          </h2>
        </HashLink>
        <HashLink smooth to={"#benefits"} offset={-10}>
          <h2 className={`py-1 rounded-l-lg  dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 15 && scrollTop <= 20 ? 'bg-sustally-violet' : 'bg-transparent'}`}>
            Benefits
          </h2>
        </HashLink>
        <HashLink smooth to={"#key"}>
          <h2 className={`py-1 rounded-l-lg  dark:text-white font-semibold px-3 dark:border hover:right-2 hover:scale-110 transition-all ease-linea text-black border ${scrollTop >= 20 && scrollTop <= 25 ? 'bg-sustally-violet' : 'bg-transparent'}`}>
            Key Takeaways
          </h2></HashLink>
      </div>

      <div className="mx-7 md:mx-36 py-24 my-8 font-Inter">
        <h1 id="intro" className="text-3xl md:text-5xl  md:py-2 font-Poppins">
          Be future ready!
        </h1>

        <br></br>
        <div className="leading-relaxed text-lg">
          <h2 className="md:text-7xl text-5xl font-semibold text-sustally-violet w-[100%] md:w-[80%]">
            Beyond spreadsheets

          </h2>
          <br></br>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-justify ">
            <p className="leading-relaxed">
              Meet XYZ Corp, a dynamic small business making waves in the
              India’s silicon valley.<br></br><i>Picture this:  </i>As a strategic investment
              opportunity knocks on the company's door, teams are buzzing
              everywhere - from the number crunchers in accounts to the
              innovative minds in marketing, and the ever-dedicated customer
              support crew. It's like a beehive, with everyone playing their
              part to make the magic happen.
              <br></br>
              But wait, there's a twist. <br></br> The modern business ecosystem is <span className="bg-custom-grey dark:text-black px-2 py-1 rounded-lg">not
              just about ticking off tasks</span>. Nope. It's about shouting from the
              rooftops about what you're up to, especially if stakeholders are
              peeking in.
              <br></br>
            </p>
            <p className="leading-relaxed">
              <b>Introducing MIS - The unsung hero of corporate transactions.</b> It
              begins as a simple conversation starter, evolves into a detailed
              analysis during due diligence, and culminates as a recurring
              report post-transaction. Whether a deal progresses or stalls, MIS
              remains a constant, offering investors a clear window into the
              company's operations. <br></br>
              While some may find it tedious, others see its value. But one
              thing's for sure: no strategic deal is complete without it. In the
              corporate world, <b>MIS</b> isn't just a tool; it's a necessity.<br></br>
              Sounds simple, right? Think again. <br></br>Let's dive into the maze that
              is XYZ Corp's journey through the wild world of sharing info.
              <br></br>
            </p>
          </div>
          <br></br>
          {/* <BlogD /> */}
          <br></br>
          {/* <br></br> */}
          <div className="flex flex-col md:flex-row items-center gap-6" id="challenges">
            <img className="py-4 w-[80%]" src={blogimg1} alt="blog1" width={""} height={""}/>
            <br></br>
            <p className="leading-relaxed text-justify">
              It's a crisp Monday morning at XYZ Corp. The CEO, ABC has a
              critical stakeholder meeting later in the week. <br></br><br></br>
              She must present a comprehensive report encapsulating the
              company's recent activities, achievements, and plans.<br></br> To do this,
              she requires input from every functional team.<br></br>
              <br></br>
            </p>
          </div>
          <p className="grid grid-cols-1 md:grid-cols-3 gap-6 py-4">
            <span>
              <span className="text-xl text-sustally-violet  font-semibold">
                The Gathering Storm of Coordination
              </span>
              <br></br>
              <p className="text-justify">
                Ms. Anderson reaches out to the heads of each department.<br></br> <b>But
                  here's where the plot thickens</b>.<br></br> Each team has its own set of
                priorities. The marketing team is prepping for an upcoming
                campaign launch, the sales team is chasing quarterly targets, and
                the operations team is knee-deep in logistics planning.</p>
            </span>
            <span className="text-justify">
              <span className="text-xl text-sustally-violet  font-semibold text-justify">
                Conflicting Priorities & Bandwidth Limitations
              </span>
              <br></br>
              While everyone understands the importance of the stakeholder
              meeting, they're swamped with their immediate tasks. The marketing
              head is juggling ad campaigns, the HR team is in the middle of
              recruitment drives, and the customer support team is handling a
              higher-than-average ticket volume. Everyone's bandwidth is
              stretched thin.
            </span>
            <span className="text-justify">
              <span className="text-xl text-sustally-violet  font-semibold text-justify">
                The Bottleneck Blues
              </span>
              <br></br>
              Even when teams do send in their reports, there's another
              challenge: the review process. With multiple inputs coming in,
              there's a need to ensure consistency, accuracy, and relevance. The
              review process can become a bottleneck, especially if
              discrepancies or data need to be revalidated.
            </span>
          </p>
          <p className=" md:w-[60%] text-justify">
            <span className="text-xl text-sustally-violet font-semibold">
              The Domino Effect of Delays
            </span>
            <br></br>
            Here's a real kicker: When one team gets delayed, it has a domino
            effect. If the operations team is late in submitting their data
            because they're waiting on figures from the accounts team, it pushes
            everything back. And when team members are under pressure to meet
            their immediate manager's deliverables, these reports for the CEO
            can unintentionally take a backseat. While every team at XYZ Corp is
            a cog in a well-oiled machine, coordinating them for a unified
            purpose can be akin to herding cats. But as we'll see, there are
            ways to streamline this process and make it smoother for everyone
            involved.
          </p>
          <br></br>
          {/* <BlogD /> */}

          <h3
            className="md:text-5xl text-3xl text-sustally-violet font-semibold"
            id="pitfalls"
          >
            The Downfalls of Manual Information Collation
          </h3>
          <br></br>
          <p className="text-justify">
            <i>
              Many companies, including XYZ Corp, often rely on old-school
              methods for gathering and collating information in the digital age
              <br></br>
              Let's dive into the pitfalls of this approach..
            </i>
            <br></br>
            <br></br>
            <span className="grid gap-6 grid-cols-1 md:grid-cols-2">
              <span>
                <span className="text-xl text-sustally-violet font-semibold">
                  The Spreadsheet Saga
                </span>
                <br></br>
                <img className="py-2" src={blogimg2} alt="Spreadsheet saga image" width={""} height={""} />
                <br></br>
                Ah, the ubiquitous spreadsheet! It's the go-to tool for many
                when it comes to collecting data. At XYZ Corp, each team has its
                spreadsheet format, tailored to its specific needs. But when
                it's time to merge these sheets for a comprehensive report?
                Chaos ensues. Different formats, varying data points, and the
                occasional missing entry make for a messy compilation.
              </span>
              <span>
                <span className="text-xl text-sustally-violet font-semibold">
                  Version Control Vortex
                </span>
                <br></br>
                <img className="py-2" src={blogimg3} alt="Version control vortex image" width={""} height={""}/>
                <br></br>
                Ever received a file named "Final_v2_updated_latest"? With
                manual methods, version control becomes a nightmare. Team A
                might be working on an older data version while Team B has made
                updates. It leads to inconsistencies, and before you know it,
                there's a frantic search for the "actual" latest version.
                <br></br>
              </span>

              <span className="">
                <span className="text-xl text-sustally-violet font-semibold">
                  "Pass the Ball" Phenomenon
                </span>
                <br></br>
                <img className="py-2" src={blogmg4} alt="Pass the ball phenomenom image" width={""} height={""}/>
                <br></br>
                Remember the childhood game where you pass the ball (or mail)
                and hope it doesn't stop with you? That's what happens with
                emails. One team sends data to another for validation, makes
                changes, sends it to another team, and so on. Information gets
                lost, altered, or delayed in this endless loop.<br></br>
              </span>

              <span className="">
                <span>
                  <span className="text-xl text-sustally-violet font-semibold">
                    The Cumulative Cost of Delays
                  </span>
                  <br></br>
                  <img className="py-2" src={blogimg5} alt="Cumulative cost of delays image" width={""} height={""}/>
                </span>
                <br></br>
                While a day's delay might seem trivial, these add up.<br></br>A
                day here, two days there, and suddenly, XYZ Corp is a week
                behind schedule.<br></br>
                It isn't just an inconvenience for stakeholders eagerly awaiting
                critical information; it's a potential business setback.
                <br></br>
              </span>
            </span>
            <br></br>
            <br></br>

            <i>
              While manual methods have their place, when it comes to efficient
              information collation, they're akin to using a typewriter in a
              world of laptops. The need for a more streamlined, tech-savvy
              approach is evident, and as we'll explore next, some solutions can
              make this process a breeze.<br></br>
              <br></br>
              <br></br>
            </i>
          </p>
          {/* <BlogD /> */}
          {/* <br></br> */}
          <h3 className='text-3xl text-sustally-violet font-semibold' id="benefits">The Benefits of Software Solutions for Information Collation</h3><br></br>
          <img className="w-[100%] py-5" src={blogimg6} alt="Benefits of software solutions"  width={""} height={""}/>
          {/* <br></br> */}
          <p className="text-justify" id="">
            <i>
              The digital revolution has brought forth many tools designed to
              make our lives easier, and the realm of information collation is
              no exception.
              Let's explore how software solutions change the game for companies
              like XYZ Corp.<br></br>
              <br></br>
            </i>
            <span className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <span>
                <span className="text-xl text-sustally-violet  font-semibold">
                  Embracing the Digital Shift
                </span>
                <br></br>
                Gone are the days of juggling multiple spreadsheets and email
                threads. Enter the era of software solutions designed specifically for
                information collation. These tools are tailored to seamlessly gather, organize, and
                present data from various teams. With a single click, disparate
                data sources can be unified, eliminating the tedious task of
                manual data merging.
                <br></br>
              </span>
              <span>
                <span className="text-xl text-sustally-violet  font-semibold">
                  Streamlining & Automation at Its Best
                </span>
                <br></br>
                Software doesn't just collect data; it does so smartly.<br></br>
                The process becomes easy with features like automated data
                fetching, preset templates, and scheduled updates.<br></br>
                No more manual entries mean no more manual errors. It's
                efficiency on steroids! The automation not only saves time but
                also ensures that the data is always up-to-date and reliable.
                <br></br>
                <br></br>
              </span>
              <span>
                <span className="text-xl text-sustally-violet font-semibold">
                  A Central Hub for All Communication
                </span>
                <br></br>
                One of the standout features of these software solutions is the
                centralized platform they offer.<br></br>
                No more sifting through emails or chasing team members.<br></br>
                Everyone knows where to go to input their data, view updates, or
                check on the progress of the collation.<br></br>
                It's like having a digital meeting room where everyone's always
                in sync. The central hub acts as a single source of truth,
                eliminating confusion and ensuring everyone is aligned.<br></br>
                <br></br>
              </span>
              <span>
                <span className="text-xl text-sustally-violet  font-semibold">
                  Real-Time Data at Your Fingertips
                </span>
                <br></br>
                Remember the version control chaos?<br></br>
                With software, that's a thing of the past.<br></br>
                Everyone works with real-time data, ensuring consistency and
                accuracy.
                <br></br>
                Everyone sees the same updated information whether the sales
                team updates its latest figures or the marketing team sharing
                campaign metrics. Real-time access ensures that decisions are
                made based on the most recent and accurate data.<br></br>
                <br></br>
              </span>
            </span>
            <span className="grid grid-cols-1 md:grid-cols-2">
              <span>
                <span className="text-xl text-sustally-violet  font-semibold">
                  Fostering Team Collaboration
                </span>
                <br></br>
                Beyond just data collection, these tools promote inter-team
                collaboration. With features like comment sections, task assignments, and
                notification alerts, teams can communicate, provide feedback,
                and work together more effectively.<br></br>
                It's not just about collecting data but building stronger team
                dynamics. By fostering a culture of collaboration, these
                software solutions ensure that teams are not just collecting
                data but also leveraging it for better decision-making.
                Software solutions for information collation are like fresh air
                for companies drowning in data chaos. They offer a structured, efficient, and collaborative way to
                manage and share information, ensuring that XYZ Corp and others
                like it are always ahead in their communication.<br></br>
              </span>
            </span>
            <br></br>
            <br></br>
          </p>
          {/* <BlogD /> */}
          {/* <br></br> */}
          <span id="key">
            <h2 className="md:text-5xl text-3xl font-semibold text-sustally-violet text-justify">Takeaway</h2><br></br>
            <p className=" gap-6 text-justify ">
              <span>
                Companies, exemplified by XYZ Corp, grapple with significant
                challenges when disseminating MIS or vital information to
                stakeholders.
                The intricate web of inter-departmental coordination, each with
                its distinct priorities, often culminates in conflicting
                schedules, bandwidth limitations, and inevitable delays.
                These challenges underscore the pressing need for efficient
                information collation methods. Without them, not only does
                external communication with stakeholders suffer, but internal
                team dynamics are also strained, leading to potential business
                setbacks.
              </span>
              <br></br><br></br>The solution lies in embracing the digital age fully. Software
              solutions for information collation have emerged as game-changers
              in this scenario. They don't merely gather data; they transform
              the very fabric of inter-team communication, making it more
              streamlined, timely, and efficient.
              These tools are indispensable with features that promote real-time
              data access, foster collaboration, and provide a centralized
              communication hub. 
              Adopting such software solutions is paramount for businesses to
              thrive in today's fast-paced environment. 
              They serve the dual purpose of meeting external stakeholder
              requirements while enhancing internal team coordination and
              efficiency, ensuring that firms remain agile and ahead of the
              curve.<br></br>
            </p>
          </span>
        </div>
      </div><br></br>
      <div className="flex mx-auto justify-center items-center max-w-5xl mb-10">
        <h2 className="text-xl italic text-center">*This article is independently written and not sponsored by any of the companies mentioned; it is provided for informational purposes only and does not constitute legal or financial advice.*</h2>
      </div>
      <div className="md:mx-36 border-2 border-custom-grey dark:border-2 dark:border-custom-dark p-7 rounded-xl items-center mx-4">
        <p className="text-xl text-center"><i>If this article has captured your interest and you'd like to delve deeper, don't hesitate to connect with us. We'd love to hear your thoughts! </i></p>
      </div><br></br>
      <div className='flex justify-center mt-20 items-center mx-auto'>
        <Link to="process" spy={true} smooth={true} offset={50} duration={200}>
          <motion.img
            animate={{ y: -20 }}
            transition={{ duration: 0.8, repeat: Infinity, type: 'spring', repeatDelay: 1, bounce: 0.3, mass: 0.8 }}
            src={downarrow} alt='down arrow' className='w-10 cursor-pointer' width={""} height={""}></motion.img>
        </Link>
      </div>
      
      {/* <div className="md:static relative">
        <BlogContactUs/>
      </div> */}
    </div>
  );
}

export default Blog1;
