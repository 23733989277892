import React, { useState } from "react";
import "../common/styles/styles.css";
import "../common/styles/teamwall.css";
import rightwhitearrow from "../assets/rightwhitearrow.svg";
import sidearrow from "../assets/careers_aboutus/downarrow.png";
import { motion } from "framer-motion";
import downarrow from "../assets/careers_aboutus/downarrow.png";

function OurTeam(dat, text) {
  var w = window.innerWidth;

  let dum = dat.data.dummy;
  let pathid = dat.data.animateId;
  function ran() {
    return Math.floor(Math.random() * (150 - 10)) + 10;
  }
  console.log(w);

  return (
    <div>
      {dat.data.id !== "somu" ? (
        <div
          id={dat.data.id}
          className="card md:mb-28 ml-auto mr-auto md:ml-0 md:mr-0"
          style={{ top: w >= 600 ? ran`%` : "0%" }}
        >
          <div className="foooter">
            <svg id="curve">
              <path
                id={pathid}
                d="M0,200 Q80,100 400,200 V150 H0 V50"
                transform="translate(0 300)"
              />
              <rect
                id={dum}
                x="0"
                y="0"
                height="450"
                width="400"
                fill="transparent"
              />
              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,100 400,50 V150 H0 V50"
                fill="freeze"
                begin={dum.concat(".mouseover")}
                end={dum.concat(".mouseout")}
                dur="0.1s"
                id={dat.data.bounce[0]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,0 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[0] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.15s"
                id={dat.data.bounce[1]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,80 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[1] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.15s"
                id={dat.data.bounce[2]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,45 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[2] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.1s"
                id={dat.data.bounce[3]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,50 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[3] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.05s"
                id={dat.data.bounce[4]}
              />
              {/* <animate
              xlinkHref={"#" + pathid}
              attributeName="d"
              to="M0,50 Q80,50 400,50 V150 H0 V50"
              fill="freeze"
              begin={dat.data.bounce[4] + ".end"}
              end={dum.concat(".mouseout")}
              dur="0.05s"
              id={dat.data.bounce[5]}
            />
            <animate
              xlinkHref={"#" + pathid}
              attributeName="d"
              to="M0,50 Q80,50 400,50 V150 H0 V50"
              fill="freeze"
              begin={dat.data.bounce[5] + ".end"}
              end={dum.concat(".mouseout")}
              dur="0.05s"
              id={dat.data.bounce[6]}
            />

            <animate
              xlinkHref={"#" + pathid}
              attributeName="d"
              to="M0,50 Q80,50 400,50 V150 H0 V50"
              fill="freeze"
              begin={dat.data.bounce[6] + ".end"}
              end={dum.concat(".mouseout")}
              dur="0.05s"
              id={dat.data.bounce[7]}
            /> */}

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,200 Q80,100 400,200 V150 H0 V50"
                fill="freeze"
                begin={dum.concat(".mouseout")}
                dur="0.15s"
                id="bounceOut"
              />
            </svg>

            <div className="info">
              <div className="name text-xl font-Poppins">{dat.data.name}</div>
              <div className="job text-sm font-Inter">{dat.data.role}</div>
            </div>

            <a
              aria-label="Visit Anitha's Linkedin Page"
              href={dat.data.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <div className="connections z-50">
                <div className="connection twitter">
                  <div className="icon"></div>
                </div>
              </div>
            </a>
          </div>
          <div
            id="textt"
            className="absolute -top-4 px-2 transition-all duration-300 ease-in-out group-hover:-top-2"
          >
            <p className="text-black text-[16px] leading-tight font-Inter ">
              {dat.data.decription}
            </p>
          </div>
          <div className="card-blur"></div>
        </div>
      ) : (
        <div
          id={dat.data.id}
          className="card md:mb-28 ml-auto mr-auto md:ml-0 md:mr-0"
          style={{ top: w >= 600 ? ran`%` : "0%" }}
        >
          <div className="foooter">
            <svg id="curve">
              <path
                id={pathid}
                d="M0,200 Q80,100 400,200 V150 H0 V50"
                transform="translate(0 300)"
              />
              <rect
                id={dum}
                x="0"
                y="0"
                height="450"
                width="400"
                fill="transparent"
              />
              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,100 400,50 V150 H0 V50"
                fill="freeze"
                begin={dum.concat(".mouseover")}
                end={dum.concat(".mouseout")}
                dur="0.1s"
                id={dat.data.bounce[0]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,0 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[0] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.15s"
                id={dat.data.bounce[1]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,80 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[1] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.15s"
                id={dat.data.bounce[2]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,45 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[2] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.1s"
                id={dat.data.bounce[3]}
              />

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,50 Q80,50 400,50 V150 H0 V50"
                fill="freeze"
                begin={dat.data.bounce[3] + ".end"}
                end={dum.concat(".mouseout")}
                dur="0.05s"
                id={dat.data.bounce[4]}
              />
              {/* <animate
              xlinkHref={"#" + pathid}
              attributeName="d"
              to="M0,50 Q80,50 400,50 V150 H0 V50"
              fill="freeze"
              begin={dat.data.bounce[4] + ".end"}
              end={dum.concat(".mouseout")}
              dur="0.05s"
              id={dat.data.bounce[5]}
            />
            <animate
              xlinkHref={"#" + pathid}
              attributeName="d"
              to="M0,50 Q80,50 400,50 V150 H0 V50"
              fill="freeze"
              begin={dat.data.bounce[5] + ".end"}
              end={dum.concat(".mouseout")}
              dur="0.05s"
              id={dat.data.bounce[6]}
            />

            <animate
              xlinkHref={"#" + pathid}
              attributeName="d"
              to="M0,50 Q80,50 400,50 V150 H0 V50"
              fill="freeze"
              begin={dat.data.bounce[6] + ".end"}
              end={dum.concat(".mouseout")}
              dur="0.05s"
              id={dat.data.bounce[7]}
            /> */}

              <animate
                xlinkHref={"#" + pathid}
                attributeName="d"
                to="M0,200 Q80,100 400,200 V150 H0 V50"
                fill="freeze"
                begin={dum.concat(".mouseout")}
                dur="0.15s"
                id="bounceOut"
              />
            </svg>

            <div className="info">
              <div className="profile">
                <a href="/career">
                  <h1 className="name text-xl text-black">Join us</h1>
                  <img
                    src={sidearrow}
                    alt="side arrow"
                    className="w-[20%] md:w-[20%] absolute right-[15%] md:right-[8%] top-[19.8%] md:top-[21%] -rotate-90 hover:scale-105"
                  ></img>
                </a>
              </div>
            </div>

            {/* <a
            aria-label="Visit Anitha's Linkedin Page"
            href={dat.data.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <div className="connections z-50">
              <div className="connection twitter">
                <div className="icon"></div>
              </div>
            </div>
          </a> */}
          </div>
          <div id="textt" className="absolute -top-3 px-2">
            <p className="text-black text-[16px] leading-tight font-Inter ">
              {dat.data.decription}
            </p>
          </div>
          <div className="card-blur"></div>
        </div>
      )}
    </div>
  );
}

export default OurTeam;
