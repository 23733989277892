import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import '../common/styles/styles.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
/*
const marks = [
    {
        value: 5,
        label: '1',
    },
    {
        value: 10,
        label: '5',
    },
    {
        value: 15,
        label: '10 +',
    },
];*/
const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '',
    },
    {
        value: 3,
        label: '',
    },
    {
        value: 4,
        label: '',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '',
    },
    {
        value: 7,
        label: '',
    },
    {
        value: 8,
        label: '',
    },
    {
        value: 9,
        label: '',
    },
    {
        value: 10,
        label: '10+',
    },
];
const durations = [
    {
        value: 3,
        label: 'Quarterly',
    },
    {
        value: 6,
        label: 'Half-yearly',
    },
    {
        value: 12,
        label: 'Annual',
    },

];





const PricingPlan = ({ PricingPlanRef, isDarkMode }) => {

    const [alignment, setAlignment] = React.useState('stakeholders');

    const handleChange = (
        event,
        newAlignment,
    ) => {
        if (newAlignment === null) { return }
        console.log("-------->", newAlignment)
        setAlignment(newAlignment);
    };

    const [userValue, setuserValue] = useState(10)
    function handleuserChange(value) {

        if (value.target.value === 1 || value.target.value === 2 || value.target.value === 3 || value.target.value === 4 || value.target.value === 5 || value.target.value === 6 || value.target.value === 7 || value.target.value === 8 || value.target.value === 9 || value.target.value === 10) {
            setuserValue(value.target.value)
        }
    }


    let [months, setmonths] = useState(['0M'])
    const [duratinoValue, setduratinoValue] = useState(12)
    function handledurationChange(value) {
        if (value.target.value === 1 || value.target.value === 3 || value.target.value === 6 || value.target.value === 12) {
            setduratinoValue(value.target.value)
        }

    }

    useEffect(() => {

        if (duratinoValue === 3) {
            setmonths(['0M', '3M', '6M', '12M'])
        } else if (duratinoValue === 6) {
            setmonths(['0M', '3M', '6M', '12M'])
        } else if (duratinoValue === 12) {
            setmonths(['0M', '3M', '6M', '12M'])

        }
    }, [duratinoValue])
    let customerObject =
    {
        usd: {
            '1-5': [15, 12, 10],
            '6-10': [12, 10, 8],
            '>10': [10, 8, 7]
        }
    }

    let stakeholderobject = {
        usd: {
            '1-5': [12, 10, 8],
            '6-10': [10, 8, 6],
            '>10': [8, 7, 5]
        }
    }
    const [resultingobject, setresultingObject] = useState(alignment === 'stakeholders' ? stakeholderobject : customerObject)
    useEffect(() => {
        if (alignment === 'stakeholders') {
            setresultingObject(stakeholderobject)
        } else {
            setresultingObject(customerObject)

        }
    }, [alignment])

    const [currency, setcurrency] = useState('usd')
    const [graphState, setGraphState] = useState(customerObject[currency]['>10'])

    useEffect(() => {
        if (userValue <= 4) {
            setGraphState(customerObject[currency]['1-5'])
        } else if (userValue >= 5 && userValue < 10) {
            setGraphState(customerObject[currency]['6-10'])
        } else {
            setGraphState(customerObject[currency]['>10'])
        }
    }, [userValue])



    const saving = resultingobject[currency]['1-5'][0] * duratinoValue - resultingobject[currency][userValue <= 4 ? '1-5' : userValue >= 5 && userValue <= 9 ? '6-10' : '>10'][duratinoValue <= 3 ? '0' : duratinoValue > 3 && duratinoValue <= 6 ? '1' : '2'] * duratinoValue;

    const basePrice = resultingobject[currency]['1-5'][0] * duratinoValue;



    const pricePerUser = resultingobject[currency][userValue <= 4 ? '1-5' : userValue >= 5 && userValue <= 9 ? '6-10' : '>10'][duratinoValue <= 3 ? '0' : duratinoValue > 3 && duratinoValue <= 6 ? '1' : '2']


    const savingPercentage = 100 - Math.floor((basePrice - saving) * 100 / basePrice)

    //const savingPercentage = Math.floor((15 - pricePerUser) * 100 / 15)
    //Calculate % - (base price - new price)*100/base price

    return (
        <div
            ref={PricingPlanRef}
            style={{
                zIndex: 0,
                position: 'relative',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '5% 331px',
            }}
            className='w-full h-screen  pricing  flex flex-col' id="pricingplan">
            <h1

                className='font-semibold font-Inter text-center  md:text-7xl  mt-10 text-5xl'>
                Pricing Plan
            </h1>
            <div style={{ justifyContent: "space-between" }} className='md:w-[80%] md:h-[75%] w-[100%] h-[100%] md:mt-10 mt-7  flex items-center pricing-div content-between'>
            <div style={{ justifyContent: 'space-around' }} className='toggle-button md:w-[35%] md:h-[70%] w-[70%] flex flex-col item-center content-between md:pt-2 pt-0'>
                    <ToggleButtonGroup

                        style={{
                            border: "1px solid #4e4646",
                            boxShadow: "rgb(0 0 0) 0px 16px 17px",
                            position: 'relative',
                        }}
                        // color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton className={`${alignment === 'stakeholders' && 'active-butttonn'}`} value="stakeholders">Stakeholders</ToggleButton>
                        <ToggleButton className={`${alignment === 'customers' && 'active-butttonn'}`} value="customers">Customers</ToggleButton>
                        <img style={{ width: '25px', height: '25px' }} className='absolute -right-3 -top-3' src={require('../assets/question_icon.png')} alt="question icon" width={""} height={""}/>

                    </ToggleButtonGroup>

                    <div className='w-full slider1 md:mt-10 mt-5'>
                        <span style={{ fontWeight: '600' }}>No. of users</span>

                        <Box className="md:mt-3 mt-1" sx={{}}>
                            <Slider
                                value={userValue}
                                onChange={handleuserChange}

                                style={{}}

                                aria-label="Restricted values"
                                defaultValue={1}
                                // step={userValue < 5 ? 4 : userValue < 10 && userValue >= 5 ? 5 : 10}
                                // valueLabelDisplay={userValue > 10 ? false : false}
                                max={10}
                                min={1}
                                marks={marks}
                            />
                        </Box>
                    </div>

                    <div className='w-full slider1 mt-1'>

                        <span style={{ fontWeight: '600' }}>Duration</span>

                        <Box className="md:mt-3 mt-1" sx={{}}>
                            <Slider
                                value={duratinoValue}
                                onChange={handledurationChange}
                                style={{}}
                                aria-label="Restricted values"
                                defaultValue={3}
                                // step={duratinoValue}
                                valueLabelDisplay="auto"
                                max={12}
                                min={3}
                                marks={durations}
                            />
                        </Box>
                    </div>
                </div>
                <div style={{

                    background: isDarkMode === "dark" ? "black" : "white",

                }} className='md:w-[60%] w-[85%] relative items-center content-around flex-col flex h-[100%]'>
                    <div style={{ backgroundColor: isDarkMode === "dark" ? "white" : "black" }} className='rounded-full absolute flex items-center content-center pricingBubble'>
                        <span style={{ color: isDarkMode === "dark" ? "black" : "white", margin: 'auto', fontWeight: '700', fontSize: '10px' }}><b style={{ color: '#8E4DFF', fontWeight: '700', fontSize: '20px' }}><sup>$</sup>{pricePerUser}</b>/user</span>
                    </div>
                    <div style={{ width: '80%', height: '65%', marginTop: '7%' }} className='relative'>
                        <span style={{
                            zIndex: -1,
                            bottom: "-20px",
                            left: "-15px",
                            color: isDarkMode === "dark" ? "white" : "black",
                        }} className=' absolute'>1</span>
                        <span style={{
                            zIndex: -1,
                            bottom: "20px",
                            left: "-25px",
                            transform: "rotate(-90deg)", color: '#8E4DFF', fontSize: '12px'
                        }} className='text-white absolute'>users</span>
                        <span style={{
                            zIndex: -1,
                            bottom: "-20px",
                            left: "15px", color: '#8E4DFF', fontSize: '12px'
                        }} className='text-white absolute'>months</span>
                        <div style={{
                            width: '85%',
                            height: '92%',
                            left: '5%',
                            background: 'red',
                            position: 'absolute',
                            bottom: 0,
                            clipPath: 'polygon(0% 0%, 100% 64%, 112% 23%, 110% 3%, 102% 108%, -1% 100%)',
                            backgroundImage: "linear-gradient(176deg, rgb(43 12 96), #49274d)",
                        }}>

                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                left: userValue >= 5 && userValue <= 9 && duratinoValue === 3 ? '7%' : userValue <= 4 && duratinoValue === 12 ? '7%' : userValue <= 4 && duratinoValue === 6 ? '7%' : userValue <= 4 && duratinoValue === 3 ? '7%' : userValue >= 4 && userValue <= 9 && duratinoValue === 6 ? '48%' : duratinoValue === 3 && userValue === 10 ? '48%' : userValue >= 4 && userValue <= 9 && duratinoValue === 12 ? '48%' : userValue === 10 && duratinoValue === 6 ? '48%' : userValue >= 5 && userValue <= 9 ? '48%' : "87.5%",
                                bottom: userValue >= 5 && userValue <= 9 && duratinoValue === 3 ? '59%' : userValue <= 4 && duratinoValue === 12 ? '31%' : userValue <= 4 && duratinoValue === 6 ? '59%' : userValue <= 4 && duratinoValue === 3 ? '82.5%' : userValue >= 4 && userValue <= 9 && duratinoValue === 6 ? '56%' : duratinoValue === 3 && userValue === 10 ? '56%' : duratinoValue === 12 && userValue === 10 ? '30%' : userValue >= 4 && userValue <= 9 && duratinoValue === 12 ? '30%' : userValue === 10 && duratinoValue === 6 ? '30%' : '0%',
                                transition: 'all 0.3s ease'
                            }}>{/*<img src={cross} />*/}</div>
                        <div
                             className=' thirdAxis'>
                            <div style={{ backgroundColor: isDarkMode === "dark" ? "white" : "black",
                            transition:'all 0.5s ease',
                            top: userValue >= 5 && userValue <= 9 && duratinoValue === 3 ? '-3%' : userValue <= 4 && duratinoValue === 12 ? '-3%' : userValue <= 4 && duratinoValue === 6 ? '-3%' : userValue <= 4 && duratinoValue === 3 ? '-3%' : userValue >= 4 && userValue <= 9 && duratinoValue === 6 ? '45%' : duratinoValue === 3 && userValue === 10 ? '45%' : userValue >= 4 && userValue <= 9 && duratinoValue === 12 ? '45%' : userValue === 10 && duratinoValue === 6 ? '45%' : userValue >= 5 && userValue <= 9 ? '45%' : "92.5%"
                             }} className='rounded-full absolute flex items-center content-center pricingBubbleLine'>
                                <span style={{ color: isDarkMode === "dark" ? "black" : "white", margin: 'auto', fontWeight: '700', fontSize: '8px' }}><b style={{ color: '#8E4DFF', fontWeight: '700', fontSize: '16px' }}><sup>$</sup>{pricePerUser}</b>/user</span>
                            </div>
                        </div>

                        <div
                            style={{
                                width: "2px",
                                height: "100%",
                                backgroundColor: '#8E4DFF'
                            }}
                            className='absolute flex '>
                            {['Annual ', 'Half-yearly ', 'Quarterly '].map((item, index) => {
                                console.log(userValue)

                                return (
                                    <pre className='absolute'
                                        style={{
                                            display: 'flex',
                                            bottom: index == 0 ? '32%' : index == 1 ? '60%' : '90%',
                                            left: index == 0 ? '-42px' : index == 1 ? '-70px' : '-60px',
                                            fontSize: '10px',
                                            alignItems: 'center',
                                            color: isDarkMode === "dark" ? "white" : "black",
                                        }}>{item}
                                        <div style={{ width: '5px', height: '2.5px', background: 'white' }}></div>
                                    </pre>
                                )


                            })}


                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "2px",
                                backgroundColor: '#8E4DFF',
                                bottom: '0'
                            }}
                            className='absolute'>
                            {['1 ', '5 ', '10+ '].map((item, index) => {
                                console.log(userValue)
                                {
                                    return (
                                        <pre className='absolute'
                                            style={{
                                                display: 'flex',
                                                left: index == 0 ? '10%' : index == 1 ? '50%' : '90%',
                                                bottom: '-20px',
                                                fontSize: '10px',
                                                alignItems: 'center',
                                                color: isDarkMode === "dark" ? "white" : "black",
                                            }}>{item}
                                            <div style={{ width: '2.5px', height: '5px', background: 'white', position: 'absolute', top: '-10px' }}></div>
                                        </pre>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className='md:w-[75%] w-[90%] mt-5'>

                        <span style={{ color: isDarkMode === "dark" ? "white" : "black", fontSize: '20px', fontWeight: '600' }}>Savings - <b style={{ color: '#8E4DFF', fontWeight: '800' }}>
                            {userValue == 10 ? ">" : ""}

                            <sup>
                                $
                            </sup>

                            {saving * userValue}</b></span>
                        <p style={{ fontSize: '16px', color: isDarkMode === "dark" ? "white" : "black", }}>{(userValue <= 4 && duratinoValue === 3) ? <p>Consider extending your subscription to unlock potential savings.</p> : (userValue >= 5 && userValue <= 15 && duratinoValue === 12) ? <p>Kudos to your fiscal wisdom! Your choice is yielding a remarkable savings of <span>{savingPercentage}</span>% !</p> : <p>Nice Choice! Your optimal choice leads to solid savings of <span>{savingPercentage}</span>% and you still have the opportunity to enhance this further by selecting the yearly plan</p>}</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PricingPlan