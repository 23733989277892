import React from "react";
import "../common/styles/styles.css";
import "../common/styles/teamwall.css";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Alumni = (dat) => {
  return (
    <div>
      <div className="alumni-card w-11/12 sm:w-full ml-auto mr-auto p-5 rounded-lg sm:h-full">
        <div className="section2">
          <div className="flex h-7">
            <p className="text-7xl">“</p>
            <div
              style={{
                borderTop: "3px solid",
                width: "97%",
                marginTop: "15px",
                fontFamily: "Verdana, sans-serif",
              }}
            ></div>
          </div>
          <p
            style={{ textAlign: "justify", textJustify: "inter-word" }}
            class="text-xl md:text-2xl pl-5 pr-5 pt-5 mb-5 kalam-light"
          >
            {dat.data.decription}
          </p>
          <div className="flex h-7">
            <div
              style={{
                borderTop: "3px solid",
                width: "97%",
                marginTop: "15px",
              }}
            ></div>
            <p className=" text-7xl">”</p>
          </div>
        </div>
        <div className="section1 ">
          <div className="flex xl:gap-4 xl:ml-auto xl:mr-auto xl:w-3/4">
            <div className="pohoto">
              <img
                className="rounded-full h-24 w-24 md:h-32 md:w-44 border-4 border-purple-600 "
                alt=""
                src={dat.data.image}
              />
            </div>
            <div class="details text-center flex md:mt-5 w-full">
              <div>
                <h3 class="text-center text-2xl sm:text-3xl text-bold ">
                  {dat.data.name}
                </h3>
                <p className="text-md sm:text">{dat.data.duration}</p>
              </div>
              <a href={dat.data.linkedin}>
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{
                    marginTop: "5px",
                    marginLeft: "10px",
                    height: "30px",
                    width: "30px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alumni;
