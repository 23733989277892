import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Input from '@mui/material/Input';

import CloseIcon from '@mui/icons-material/Close';
import phoneNumberCodes from '../common/countrycodelist';

const ariaLabel = { 'aria-label': 'description' };




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    paddingLeft: '10px',
    paddingRight: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '20px',
    boxShadow: '2px 0px 32px 2px #353333',
    outline: 'none'
};

export default function FaqNotFoundPopup({ faqpopupformRef, sendEmailForFaqPopup, open, setOpen, handleOpen, handleClose, search, setSearch }) {


    const [isopen, setisopen] = useState(false)
    const [isPhoneopen, setisPhoneopen] = useState(false)

    const toggleopen = () => {
        setisopen(!isopen)
    }
    const togglephoneopen = () => {
        setisPhoneopen(!isPhoneopen)
    }
    const [selectedText, setselectedText] = useState('Select')
    const [selectedphone, setselectedphone] = useState('+91')

    const [inputValue, setInputValue] = useState(search)

    const handleInputChange = (event) => {
      setSearch(event.target.value);
    };

    //post data to google sheets
    // useEffect(() => {
    //     const scriptURL = 'https://script.google.com/a/macros/sustally.com/s/AKfycbxA1b7s0c9XOl6ebCAcV-sGLKgf3HAph3Z-1lFp--5bKNz9X_gm55wN1CUfSDrwl1vRcQ/exec';

    //     // Check if the form exists before adding the event listener
    //     const form = document.forms['faq-to-google-sheet'];
    //     if (form) {
    //         form.addEventListener('submit', (e) => {
    //             e.preventDefault();
    //             fetch(scriptURL, { method: 'POST', body: new FormData(form) })
    //                 .then((response) => {
    //                     form.reset();
    //                 })
    //                 .catch((error) => console.error('Error!', error.message));
    //         });
    //     }
    // }, []);

    // useEffect(() => {
    //     const scriptURL = 'https://script.google.com/a/macros/sustally.com/s/AKfycbxA1b7s0c9XOl6ebCAcV-sGLKgf3HAph3Z-1lFp--5bKNz9X_gm55wN1CUfSDrwl1vRcQ/exec';
    //     const form = document.forms['faq-to-google-sheet'];
    //     // Wrap the event listener registration in a useEffect hook
    //     const registerFormSubmitListener = () => {
    //         if (form) {
    //             form.addEventListener('submit', (e) => {
    //                 e.preventDefault();
    //                 fetch(scriptURL, { method: 'POST', body: new FormData(form) })
    //                     .then((response) => {
    //                         form.reset();
    //                     })
    //                     .catch((error) => console.error('Error!', error.message));
    //             });
    //         }
    //     };

    //     registerFormSubmitListener(); // Call the function to register the listener

    //     // Cleanup: Remove the event listener when the component unmounts
    //     return () => {
    //         const form = document.forms['faq-to-google-sheet'];
    //         if (form) {
    //             form.removeEventListener('submit', () => {});
    //         }
    //     };
    // }, []);

    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >






                <Box sx={style} className='faqnotfoundbox'>
                    <form ref={faqpopupformRef} 
                    onSubmit={async (e) => {
                    e.preventDefault(); // Prevent the default form submission
                    const scriptURL = 'https://script.google.com/macros/s/AKfycbxA1b7s0c9XOl6ebCAcV-sGLKgf3HAph3Z-1lFp--5bKNz9X_gm55wN1CUfSDrwl1vRcQ/exec';
                    const form = document.forms['faq-to-google-sheet'];

                    try {
                        const response = await fetch(scriptURL, { method: 'POST', body: new FormData(form) });

                        if (response.ok) {
                            form.reset();
                            handleClose(); // Close the modal if needed
                        } else {
                            console.error('Error in form submission:', response.statusText);
                        }
                    } catch (error) {
                        console.error('Error in form submission:', error.message);
                    }
                    }}
                        name="faq-to-google-sheet" id="my-form" className='mt-[2%] text-black relative' style={{ fontWeight: '400', width: '90%', fontSize: '18px', letterSpacing: '2.5%', }}>

                        <CloseIcon onClick={() => {
                            // setOpen(false)
                            handleClose()
                        }} className='absolute cursor-pointer right-[-5%] top-[-5%] text-black z-100' style={{ fontSize: '40px' }} />
                        <div className='flex flex-row font-[400] text-black'>
                            <span style={{ marginRight: '20px', }}>





                                We couldn't find this particular question in our FAQ section. Rest assured, our team will be in touch with you shortly to provide further clarification on this.


                            </span>



                        </div>




                        <Input name='Question' className='nameclass mb-2' placeholder="Enter Your Question here" inputProps={ariaLabel} value={search} onChange={handleInputChange} autoFocus="autoFocus"  style={{ color: 'black', width: "100%" }} />
                        <b>
                            Please share your</b>
                        <div className='flex flex-col'>

                            <Input name='Email' className='nameclass mb-2' placeholder="YOUR EMAIL" inputProps={ariaLabel} style={{ color: 'black', width: "100%" }} />
                            <span className='self-center mx-2'>
                                <b>
                                    or</b>
                            </span>
                            <div className='flex flex-row'>
                                <div onClick={togglephoneopen} className='ml-3 px-2 cursor-pointer flex items-center rounded relative' style={{
                                    width: "100px", zIndex: 100,
                                    //  backgroundImage: 'linear-gradient(#373737, #373737)',
                                    backgroundColor: 'black',
                                    height: '50px', border: '1px solid #7d7272',
                                }}>
                                    <span style={{ fontWeight: '400', fontSize: '20px', marginLeft: '0px', color: "#9a9191" }}>{selectedphone}</span>
                                    {!isPhoneopen && <KeyboardArrowDownIcon style={{ position: 'absolute', right: '5%', color: 'white' }} />}
                                    {isPhoneopen && <KeyboardArrowUpIcon style={{ position: 'absolute', right: '10%', color: 'white' }} />}
                                    {isPhoneopen && <div className='scrollable-content rounded' style={{ border: '1px solid #373737', boxShadow: "rgb(4 2 2) 7px 8px 7px 2px", width: '100%', position: 'absolute', top: '100%', color: '#9a9191', height: '200px', left: '-0%', backgroundImage: 'linear-gradient(#373737, #0a0a0a)' }}>
                                        {

                                            phoneNumberCodes.map((item, index) => {
                                                return (
                                                    <p onClick={() => { setselectedphone(item.code) }} className='selections' style={{ fontWeight: '400', fontSize: '20px', paddingLeft: '20px' }}>{item.code}</p>
                                                )
                                            })
                                        }

                                    </div>}
                                </div>


                                <input type="text" name='code' value={selectedphone} style={{ display: 'none' }} />

                                <Input name='Phone' className='nameclass ml-5 w-full' placeholder="PHONE NUMBER" inputProps={ariaLabel} style={{ color: 'black', }} />
                            </div>
                        </div>

                        <button aria-label="Submit Form" type="submit" name="submit" onClick={(e) => {
                            // setOpen(false)
                            console.log(e)
                        }} className='absolute text-base	 font-[700] px-10 py-2 flex items-center justify-center bottom-[-25%] right-[1%] cursor-pointer'
                            style={{
                                backgroundColor: "rgb(142, 77, 255)",
                                color: "black",
                                borderRadius: '30px',
                                boxShadow: 'rgb(19 18 18) 3px 2px 10px -1px'
                            }}>
                            <span>SEND</span>
                        </button>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}