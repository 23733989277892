import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Airtable from "airtable";
import Modal from "react-modal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, provider } from "../config/firebase";
import "../common/styles/Form.css";

Modal.setAppElement("#root");

function AssessmentForm() {
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [employee, setEmployee] = useState([]);
  const [assess, setAssess] = useState([]);
  const [userType, setUserType] = useState(null);
  const [userAssess, setUserAssess] = useState(null);
  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [q4, setQ4] = useState(null);

  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeRecords = await new Promise((resolve, reject) => {
          base("employeetable")
            .select({ view: "Grid view" })
            .eachPage((records, fetchNextPage) => {
              resolve(records);
              fetchNextPage();
            });
        });
        const assessRecords = await new Promise((resolve, reject) => {
          base("internAssessmentForm")
            .select({ view: "Grid view" })
            .eachPage((records, fetchNextPage) => {
              resolve(records);
              fetchNextPage();
            });
        });
        setEmployee(employeeRecords);
        setAssess(assessRecords);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const targetMailid = user?.email;
  const specificRecord = employee.find(
    (record) => record.fields.MailId === targetMailid
  );
  const specificAssess = assess.find(
    (record) => record.fields.Intern_Email === targetMailid
  );
  useEffect(() => {
    if (specificAssess) {
      setUserAssess(specificAssess.fields.Intern_Email);
      setQ1(specificAssess.fields.q1);
      setQ2(specificAssess.fields.q2);
      setQ3(specificAssess.fields.q3);
      setQ4(specificAssess.fields.q4);
    }
  }, [specificAssess]);
  useEffect(() => {
    if (specificRecord) {
      setUserType(specificRecord.fields.EmployeeType);
    }
  }, [specificRecord]);

  const screenWidth = window.innerWidth;
  const height = screenWidth > 616 ? 1120 : 1400;
  return (
    <div className="h-max mt-24 rounded-md assessment-card p-0 md:p-12  w-5/6 ml-auto mr-auto">
      {userType === "Entrant" ? (
        <div>
          {" "}
          {targetMailid === userAssess ? (
            <div>
              <h1 className="text-3xl text-sustally-violet">
                Your Assessment Response:
              </h1>
              <h1 className="text-lg font-medium mt-5 mb-1">
                Q. The intern has been proactively communicating and showing
                interest consistently - Yes / No / Comments ?
              </h1>
              <h1 className="text-md font-semibold inline">Ans:-</h1>
              <p className="inline text-sm">{q1}</p>
              <h1 className="text-lg mt-3 mb-1">
                Q. The intern has completed assigned work deliverables and is
                skilled to fulfil the role requirements- Yes / No / Comments ?
              </h1>
              <h1 className="text-md font-semibold inline">Ans:-</h1>
              <p className="inline text-sm">{q2}</p>
              <h1 className="text-lg mt-3 mb-1">
                Q. The intern has displayed highest level of integrity and
                ethics during the internship period - Yes / No?
              </h1>
              <h1 className="text-md font-semibold inline">Ans:-</h1>
              <p className="inline text-sm">{q3}</p>
              <h1 className="text-lg mt-3 mb-1">Q. Suggested FT Role</h1>
              <h1 className="text-md font-semibold inline">Ans:-</h1>
              <p className="inline text-sm">{q4}</p>
            </div>
          ) : (
            <div>No record found</div>
          )}
        </div>
      ) : (
        <div>
          <div className="px-0 md:px-8">
            <iframe
              title="Airtable Embed"
              className="airtable-embed"
              src="https://airtable.com/embed/app2Jiq9L6kKd4a9z/pagrAVT8Ylqzyzbtr/form"
              frameBorder="0"
              width="100%"
              height={height}
              style={{ background: "transparent", border: "1px solid #ccc" }}
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}
export default AssessmentForm;
