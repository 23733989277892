import React from "react";
import ApplicationDetails from "../components/ApplicationDetails";
import ApplicationForm from "../components/ApplicationForm";
import ApplicationStatus from "../components/ApplicationStatus";
import Airtable from "airtable";
import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, provider } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { signOut } from "firebase/auth";

const Tabs = ({ isDarkMode }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate(0);
  const routee = window.location.pathname;

  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");

  const [persons, setPersons] = useState([]);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [openTab, setOpenTab] = React.useState(1);
  const [disable, setDisble] = useState();
  const [disablee, setDisblee] = useState();

  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);
    navigate(routee);
  };

  useEffect(() => {
    base("internApp")
      .select({ view: "Grid view" })
      .eachPage(
        (records, fetchNextPage) => {
          setPersons(records);
          const extractedEmails = records.map((person) => person.fields.mail);
          setEmailAddresses((prevEmailAddresses) => [
            ...prevEmailAddresses,
            ...extractedEmails,
          ]);
          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
          }
        }
      );
  }, []);

  useEffect(() => {
    if (user?.email && emailAddresses.includes(user.email)) {
      setOpenTab(3);
      setDisble("none");
      setDisblee("auto");
    } else {
      setDisble("auto");
      setDisblee("none");
    }
  }, [user, emailAddresses]);
  const signUserOut = async () => {
    await signOut(auth);
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          {openTab === 2 && !user ? (
            <div className="text-black shadow-2xl dark:text-white z-10 fixed md:w-[40%] w-[80%] h-[50%] flex md:mx-80 mx-10 my-44  rounded-xl opacity-90 bg-white dark:bg-black flex-col justify-center items-center">
              <h2 className="py-8 text-xl md:px-0 text-center px-4">
                To proceed with the application process, Sign In
              </h2>
              <button
                aria-label="Sign In"
                name="sign in"
                className="dark:bg-white dark:text-black bg-gray-900 text-white px-3 py-2 rounded-lg"
                onClick={signInWithGoogle}
              >
                Sign in
              </button>
              <p className="text-md md:px-0 px-4 text-center pt-4">
                Please enable pop-ups to sign in
              </p>
            </div>
          ) : openTab === 1 && !user ? (
            <></>
          ) : openTab === 3 && user ? (
            <div className="flex justify-end items-center gap-4">
              <p className="">{user?.displayName}</p>
              <img
                className="w-12 rounded-full"
                src={user?.photoURL}
                alt="Profile pic"
                width={""}
                height={""}
              />
              <button
                aria-label="Sign Out"
                name="sign out"
                className="px-4 py-2 bg-sustally-violet text-white rounded-full hover:scale-105 duration-200"
                onClick={signUserOut}
              >
                Sign Out
              </button>
            </div>
          ) : openTab === 2 && user ? (
            <div className="flex justify-end items-center gap-4">
              <p className="">{user?.displayName}</p>
              <img
                className="w-12 rounded-full"
                src={user?.photoURL}
                alt="Profile pic"
                width={""}
                height={""}
              />
              <button
                aria-label="Sign Out"
                name="sign out"
                className="px-4 py-2 bg-sustally-violet text-white rounded-full hover:scale-105 duration-200"
                onClick={signUserOut}
              >
                Sign Out
              </button>
            </div>
          ) : (
            <></>
          )}
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 flex-row border-b-4 border-custom-purple"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center font-Inter">
              <a
                aria-label="Visit Job Description tab"
                className={
                  "text-sm  font-semibold px-5 py-3  shadow-lg block leading-normal " +
                  (openTab === 1
                    ? "text-white  bg-custom-purple"
                    : "text-white-600 bg-tertiary-light dark:bg-tertiary-dark ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Job description
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center font-Inter">
              <a
                aria-label="Visit Job Application tab"
                style={{ pointerEvents: "" + disable + "" }}
                className={
                  "text-sm  font-semibold px-5 py-3  shadow-lg block leading-normal " +
                  (openTab === 2
                    ? "text-white  bg-custom-purple"
                    : "text-white-600 bg-tertiary-light dark:bg-tertiary-dark ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Job Application
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center font-Inter">
              <a
                aria-label="Visit Job Status tab"
                style={{ pointerEvents: "" + disablee + "" }}
                className={
                  "JobStatus text-sm  font-semibold px-5 py-3 mt-0  shadow-lg block leading-normal " +
                  (openTab === 3
                    ? "text-white  bg-custom-purple"
                    : "text-white-600 bg-tertiary-light dark:bg-tertiary-dark ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                  navigate(0);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Job Status
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-transperent w-full mb-6 bg-secondary-light dark:bg-secondary-dark rounded-b-2xl">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <ApplicationDetails />
                  <button
                    aria-label="Apply for Job"
                    name="apply"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    href={signInWithGoogle}
                    class="btn bg-sustally-violet px-4 py-2 rounded-full hover:scale-105 duration-200 mt-10 text-white"
                  >
                    <span>Apply Here</span>
                  </button>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <ApplicationForm />
                  <button
                    aria-label="Check Status of you application"
                    name="check status"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(0);
                      setOpenTab(3);
                    }}
                    class="btn rounded-full flex justify-center bg-sustally-violet px-4 py-2 text-white"
                  >
                    <span>Check Status</span>
                  </button>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  {persons.map((person) => (
                    <ApplicationStatus key={person.id} person={person} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
      <Tabs color="pink" />
    </>
  );
}
