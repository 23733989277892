import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Airtable from "airtable";
import Modal from "react-modal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, provider } from "../config/firebase";
import "../common/styles/Form.css";

Modal.setAppElement("#root");

function LearningForm() {
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [employee, setEmployee] = useState([]);
  const [assess, setAssess] = useState([]);
  const [userType, setUserType] = useState(null);
  const [userAssess, setUserAssess] = useState(null);
  const [t1q1, setT1Q1] = useState(null);
  const [t1q2, setT1Q2] = useState(null);
  const [t1q3, setT1Q3] = useState(null);

  // const [t2q1,setT2Q1]=useState(null);
  // const [t2q2,setT2Q2]=useState(null);
  // const [t2q3,setT2Q3]=useState(null);
  // const [t2q4,setT2Q4]=useState(null);
  // const [t2q5,setT2Q5]=useState(null);

  const base = new Airtable({
    apiKey:
      "patrkaFyQODFlolLu.83b6a2e5302e6b6df870e1bfcdd68f5ce5f13970feb64d0200b7fdc538cc36d0",
  }).base("appHVOw0hKIRSCuND");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeRecords = await new Promise((resolve, reject) => {
          base("employeetable")
            .select({ view: "Grid view" })
            .eachPage((records, fetchNextPage) => {
              resolve(records);
              fetchNextPage();
            });
        });
        const assessRecords = await new Promise((resolve, reject) => {
          base("Learning")
            .select({ view: "Grid view" })
            .eachPage((records, fetchNextPage) => {
              resolve(records);
              fetchNextPage();
            });
        });

        // const assessRecordsTeamAssessment1 = await new Promise((resolve, reject) => {
        //   base("internAssessmentForm")
        //     .select({ view: "Grid view" })
        //     .eachPage((records, fetchNextPage) => {
        //       resolve(records);
        //       fetchNextPage();
        //     });
        // });
        setEmployee(employeeRecords);
        setAssess(assessRecords);
        console.log("Records: " + assessRecords.fields.MailId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const targetMailid = user?.email;
  const specificRecord = employee.find(
    (record) => record.fields.MailId === targetMailid
  );
  console.log("Specific Records: " + specificRecord);
  const specificAssess = assess.find(
    (record) => record.fields.Email === targetMailid
  );
  useEffect(() => {
    if (specificAssess) {
      setUserAssess(specificAssess.fields.Email);
      setT1Q1(specificAssess.fields.t1q1);
      setT1Q2(specificAssess.fields.t1q2);
      setT1Q3(specificAssess.fields.t1q3);
    }
  }, [specificAssess]);
  useEffect(() => {
    if (specificRecord) {
      setUserType(specificRecord.fields.EmployeeType);
    }
  }, [specificRecord]);
  console.log("UserType=" + userType);
  console.log("Target Mail Id=" + targetMailid);
  console.log("Access=" + userAssess);
  const screenWidth = window.innerWidth;
  const height = screenWidth > 616 ? 1120 : 1400;
  return (
    <div className="h-max mt-24 rounded-md assessment-card p-0 md:p-12  w-5/6 ml-auto mr-auto">
      {userType === "Buddy" ? (
        <div>
          {console.log("UserType=" + userType)}
          {targetMailid === userAssess ? (
            <div>
              <h1 className="text-3xl text-sustally-violet">
                Your Assessment Response:
              </h1>
              <h1 className="text-lg font-medium mt-5 mb-1">
                The team member tried new concepts with high difficulty level in
                the assigned task. - Yes / No / Comments ?
              </h1>
              <h1 className="text-md font-semibold inline">Ans:-</h1>
              <p className="inline text-sm">{t1q1}</p>
              <h1 className="text-lg mt-3 mb-1">
                The team member attended and actively interacted in the weekly
                tech learning?- Yes / No / Comments ?
              </h1>
              <h1 className="text-md font-semibold inline">Ans:-</h1>
              <p className="inline text-sm">{t1q2}</p>
              <h1 className="text-lg mt-3 mb-1">
                The team member shared new concepts which are useful for others
                - Yes / No /Comments?
              </h1>
              <h1 className="text-md font-semibold inline">Ans:-</h1>
              <p className="inline text-sm">{t1q3}</p>
            </div>
          ) : (
            <div>No record found</div>
          )}
        </div>
      ) : (
        console.log("Nothing")
      )}
      {userType === "Mentor" ? (
        <div>
          <div className="px-0 md:px-8">
            <iframe
              title="Airtable Embed"
              className="airtable-embed"
              src="https://airtable.com/embed/app2Jiq9L6kKd4a9z/pageXX78Z6mluO3jo/form"
              frameBorder="0"
              width="100%"
              height={height}
              style={{ background: "transparent", border: "1px solid #ccc" }}
            ></iframe>
          </div>
        </div>
      ) : (
        console.log("Nothing")
      )}
    </div>
  );
}

export default LearningForm;
